import { numericUtils } from './numeric.utils';

export const arrayUtils = {
    removeMany,
    removeAt,
    removeRight,
    arrayFromRange,
    repeat,
    groupBy,
    findLast,
    distinct,
    sum,
    min,
    max,
    take,
    findRange,
    findLastRange
};

function removeMany<T>(array: T[], valuesToRemove: T[]) {
    return array.filter(i => !valuesToRemove.some(v => v === i));
}

function removeAt<T>(array: T[], index: number) {
    if (index < 0 || index >= array.length) return array;

    const copy = [...array];
    copy.splice(index, 1);

    return copy;
}

function removeRight<T>(array: T[], n: number = 1) {
    if (n < 1) return array;
    if (array.length < n) return [];

    return take(array, array.length - n);
}

function arrayFromRange(min: number, max: number): number[] {
    const array = [];
    for (let i = min; i <= max; i++) {
        array.push(i);
    }

    return array;
}

function repeat<TValue>(value: TValue, times: number): TValue[] {
    const result = [];

    for (let i = 0; i < times; i++) {
        result.push(value);
    }

    return result;
}

function groupBy<T, TKey>(array: T[], keyGetter: (item: T) => TKey) {
    const map = new Map<TKey, T[]>();
    array.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function findLast<T>(array: T[], comparer: (item: T) => boolean): T | undefined  {
    if (Array.isArray(array) && typeof comparer === 'function') {
        for (let i = array.length - 1; i >= 0; i--) {
            const item = array[i];
            if (comparer(item)) {
                return item;
            }
        }
    }

    return undefined;
}

function distinct<T, TKey>(array: T[], keyGetter: (item: T) => TKey) {
    if (Array.isArray(array) && typeof keyGetter === 'function') {
        const keys = new Set<TKey>();
        const result: T[] = [];

        array.forEach(i => {
            const key = keyGetter(i);
            if (!keys.has(key)) {
                keys.add(key);
                result.push(i);
            }
        });

        return result;
    }

    return [];
}

function sum<T>(array: T[], valueGetter: (item: T) => number) {
    if (!Array.isArray(array) || typeof valueGetter !== 'function') {
        return 0;
    }

    let result = 0;
    for (let i = 0; i < array.length; i++) {
        const item = array[i];
        const valueRaw = valueGetter(item);
        const value = numericUtils.numberOrDefault(valueRaw);
        result += value;
    }
    return result;
}

function min<T, TValue>(array: T[], valueGetter: (item: T) => TValue) {
    if (!Array.isArray(array) || !array.length) {
        return undefined;
    }

    return array.reduce(function (previous, current) {
        const p = valueGetter(previous);
        const c = valueGetter(current);

        return (p <= c) ? previous : current;
    });
}

function max<T, TValue>(array: T[], valueGetter: (item: T) => TValue) {
    if (!Array.isArray(array) || !array.length) {
        return undefined;
    }

    return array.reduce(function (previous, current) {
        const p = valueGetter(previous);
        const c = valueGetter(current);

        return (p >= c) ? previous : current;
    });
}

function take<T>(array: T[], length: number) {
    if (!Array.isArray(array) || !array.length || length <= 0) return [];

    if (array.length <= length) return array;

    const result = [...array];
    result.length = length;

    return result;
}

function findRange<T>(list: T[], predicate: (item: T) => boolean) {
    if (!list.length) {
        return [-1, -1];
    }

    let foundStart = false;
    let foundEnd = false;

    return list.reduce((acc, item, index) => {
        const [start, end] = acc;

        if (!foundStart && predicate(item)) {
            foundStart = true;

            if (index === list.length - 1) {
                return [index, index];
            }

            return [index, end];
        }

        if (foundStart && !foundEnd) {
            if (predicate(item)) {
                return [start, index];
            }

            foundEnd = true;
            return [start, index - 1];
        }

        return acc;
    }, [-1, -1]);
}

function findLastRange<T>(list: T[], predicate: (item: T) => boolean) {
    if (!list.length) {
        return [-1, -1];
    }

    let foundStart = false;
    let foundEnd = false;

    return list.reduceRight((acc, item, index) => {
        const [start, end] = acc;

        if (!foundEnd && predicate(item)) {
            foundEnd = true;

            if (index === 0) {
                return [index, index];
            }

            return [start, index];
        }

        if (foundEnd && !foundStart) {
            if (predicate(item)) {
                return [index, end];
            }

            foundStart = true;
            return [index + 1, end];
        }

        return acc;
    }, [-1, -1]);
}
