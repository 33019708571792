export type Primitive = string | number | boolean | Date;

export enum DifferenceType {
    Added = "added",
    Updated = "updated",
    Removed = "removed",
    Unchanged = "unchanged",
}

export enum Dynamics {
    Increased = "increased",
    Decreased = "decreased",
}

export enum Traverse {
    /** Perform deep traverse of objects and arrays */
    Deep,

    /** Perform shallow traverse of objects and arrays */
    Shallow,

    /** Do not traverse field */
    Skip,
}

export type Iteratee<T> = (value: T) => Primitive;

interface BaseDifference<T> {
    /**
     * Value in initial object
     */
    previousValue?: T;

    /**
     * Value in current object
     */
    currentValue?: T;

    /**
     * Derived value.
     * For added, updated unchanged difference types — equals to `currentValue`.
     * For deleted differenceType — equals to `previousValue`
     */
    derivedValue?: T;
    type: DifferenceType;
}

export interface PrimitiveDifference<T> extends BaseDifference<T> {
    dynamics?: Dynamics;
}

export interface ObjectArrayItemDifference<T> extends BaseDifference<T> {
    difference?: Difference<T>;
    id: Primitive;
}

export type ObjectArrayDifference<T extends unknown[]> = ObjectArrayItemDifference<T extends (infer K)[] ? K : never>[];

type ObjectFieldDifference<T> = T extends unknown[]
    ? ObjectArrayDifference<T>
    : T extends Primitive
    ? PrimitiveDifference<T>
    : Difference<T>;

export type Difference<T> = Partial<{
    [K in keyof T]: ObjectFieldDifference<T[K]>;
}>;

export interface ObjectConfig<T> {
    traverse?: Traverse;
    iteratee?: Iteratee<T>;
    nestedConfig?: DifferenceConfig<T>;
}

export type ObjectArrayConfig<T extends unknown[]> = ObjectConfig<T extends (infer K)[] ? K : never>;

export type DifferenceConfig<T> = {
    [K in keyof T]?: T[K] extends Primitive
        ? never
        : T[K] extends unknown[]
        ? ObjectArrayConfig<T[K]>
        : T[K] extends unknown
        ? ObjectConfig<T[K]>
        : never;
}
