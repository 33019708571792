function readFile(file: Blob): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(String(reader.result)), false);
        reader.readAsDataURL(file)
    })
}

function dataURLtoBlob(base64Image: string) {
    const arr = base64Image.split(',');
    const mimeArr = arr[0]?.match(/:(.*?);/);
    if (mimeArr) {
        const mime = mimeArr[1];
        const base64string = atob(arr[1]);
        let base64stringLength = base64string.length;
        let u8arr = new Uint8Array(base64stringLength);
        while(base64stringLength--){
            u8arr[base64stringLength] = base64string.charCodeAt(base64stringLength);
        }
        return new Blob([u8arr], {type:mime});
    }
    return null;
}

export const imageCropperUtils = {
    readFile,
    dataURLtoBlob
};
