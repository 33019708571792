import { PushNotificationServiceStatus } from '../../constants';
import { Notification } from '../notifications/Notification';
import { ModalNotification } from '../notifications/ModalNotification';
import { NotificationAttributes } from '../notifications/NotificationAttributes';
import { RequestState } from '../../constants/request-state';

export enum StatusMessageSectionType {
    Info,
    Error,
    Alert,
    Success
}

export interface NotificationState {
    connectionStatus: PushNotificationServiceStatus;
    notificationList: Notification[];
    notificationAttributes: NotificationAttributes;
    modalNotificationList: ModalNotification[];
    requestStateGetNotificationList: RequestState;
    countUnreadNotifications: number;
    hasMoreNotifications: boolean;
    requestStateGetCountUnread: RequestState;
    requestStateReadAll: RequestState;
}
