export enum IssuanceMonitorSubscriptionType {
  Trial = 1,
  Basic = 2,
  Pro = 3,
}

export const  imSubscriptionTitle = {
    [IssuanceMonitorSubscriptionType.Trial]: 'Trial',
    [IssuanceMonitorSubscriptionType.Basic]: 'Basic',
    [IssuanceMonitorSubscriptionType.Pro]: 'Pro',
}
