import { entries } from 'lodash';

export enum InstitutionType {
    Bank = 'Bank',
    BrokerDealer = 'BrokerDealer',
    FoundationEndowmentOCIO = 'FoundationEndowmentOcio',
    InsuranceCompany = 'InsuranceCompany',
    NonUSBank = 'NonUsBank',
    PensionCorporate = 'PensionCorporate',
    PensionPublic = 'PensionPublic',
    RIAAssetManager = 'RiaAssetManager',
    RIACLOManager = 'RiaCloManager',
    RIAFamilyOfficeVCFund = 'RiaFamilyOfficeVcFund',
}

export const InstitutionTypeTitles = {
    [InstitutionType.Bank]: 'Bank',
    [InstitutionType.BrokerDealer]: 'Broker-Dealer (BD)',
    [InstitutionType.FoundationEndowmentOCIO]: 'Foundation / Endowment / OCIO',
    [InstitutionType.InsuranceCompany]: 'Insurance Company',
    [InstitutionType.NonUSBank]: 'Non-US Bank',
    [InstitutionType.PensionCorporate]: 'Pension / Corporate',
    [InstitutionType.PensionPublic]: 'Pension / Public',
    [InstitutionType.RIAAssetManager]: 'RIA / Asset Manager',
    [InstitutionType.RIACLOManager]: 'RIA / CLO Manager',
    [InstitutionType.RIAFamilyOfficeVCFund]: 'RIA / Family Office / VC Fund',
};

export const InstitutionTypes = entries(InstitutionTypeTitles).map(([value, text]) => ({ value, text }));
