import { AccessTypeMeta } from '../../analytics/AccessTypeMeta';

export enum BankAccessType {
    ContactsEventsTab = 'ContactsEventsTab',
    ContactsEventsIn = 'ContactsEventsIn',
    ContactsEventsAllNews = 'ContactsEventsAllNews',
    PrimaryTab = 'PrimaryTab',
    PrimaryStatVolDoor = 'PrimaryStatVolDoor',
    PrimaryStatActiveTransactionsDoor = 'PrimaryStatActiveTransactionsDoor',
    PrimaryStatTotalTransactionsDoor = 'PrimaryStatTotalTransactionsDoor',
    PrimaryStatUpcomingDealsDoor = 'PrimaryStatUpcomingDealsDoor',
    PrimaryStatAmrDealDoor = 'PrimaryStatAmrDealDoor',
    PrimaryPieGeographicMixDoor = 'PrimaryPieGeographicMixDoor',
    PrimaryPieEsgDealsDoor = 'PrimaryPieEsgDealsDoor',
    PrimaryPieEuCompliantDoor = 'PrimaryPieEuCompliantDoor',
    PrimaryPieStaticDealsDoor = 'PrimaryPieStaticDealsDoor',
    PrimaryPieOutOfNCDoor = 'PrimaryPieOutOfNCDoor',
    PrimaryPieOutOfRIDoor = 'PrimaryPieOutOfRIDoor',
    SecondaryTab = 'SecondaryTab',
    PrimaryTotalTransactionsDoor = 'PrimaryTotalTransactionsDoor',
    PrimaryArrangerPipelineManagersName = 'PrimaryArrangerPipelineManagersName',
    PrimaryArrangerPipelineIssuanceMonitor = 'PrimaryArrangerPipelineIssuanceMonitor',
    PrimaryDealsLastUpdated = 'PrimaryDealsLastUpdated',
    PrimaryDealsLastUpdatedTransactionVersion = 'PrimaryDealsLastUpdatedTransactionVersion',
    PrimaryDealsManagersName = 'PrimaryDealsManagersName',
    PrimaryIssuanceMonitor = 'PrimaryIssuanceMonitor',
}

export const BankAccessTypes: AccessTypeMeta<BankAccessType>[] = [
    { value: BankAccessType.ContactsEventsTab, text: 'Contacts & Events', color: '#235692' },
    { value: BankAccessType.PrimaryTab, text: 'Primary', color: '#669FC3' },
    { value: BankAccessType.SecondaryTab, text: 'Secondary', color: '#AFE0EB' },
];
