import moment from 'moment';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { BwicProcessType, Process } from '../types/models/Process';

export const liveBiddingUtils = {
    canBid,
    calculateStage2SubmissionDeadline,
    checkLiveBiddingStage2Expired
}

function canBid(bwicBidsDueUtc: Date, process: Process, canBidOnStage2: boolean, stage2LatestBidSubmissionUtc?: Date) {
    if (process.type !== BwicProcessType.Live || process.liveBidding == null) return false;

    if (process.stagedBiddingStatus == null) return true;
    if (process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded) return false;
    if (process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
        if (!canBidOnStage2) return false;

        const deadline = calculateStage2SubmissionDeadline(bwicBidsDueUtc, process, stage2LatestBidSubmissionUtc);
        const expired = moment.utc().diff(deadline) > 0;
        if (expired) return false;
    }

    return true;
}

function calculateStage2SubmissionDeadline(bwicBidsDueUtc: Date, process: Process, latestBidSubmissionUtc?: Date) {
    if (process.type !== BwicProcessType.Live || process.liveBidding == null) throw Error('Invalid process');

    const stage1End = moment
        .utc(bwicBidsDueUtc)
        .add(process.liveBidding.stage1Deadline, 'minute');

    if (latestBidSubmissionUtc == null || moment.utc(latestBidSubmissionUtc).isBefore(stage1End)) { // no stage 2 bids
        return stage1End.add(process.liveBidding.improvementRound, 'minute');
    }

    return moment
        .utc(latestBidSubmissionUtc)
        .add(process.liveBidding.improvementRound, 'minute');
}

function checkLiveBiddingStage2Expired(process: Process, bidsDueDateUtc: Date, latestBidSubmission?: Date) {
    const isLiveBiddingStage2 =
        process.type === BwicProcessType.Live &&
        process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
    const liveBiddingStage2SubmissionDeadline = isLiveBiddingStage2
        ? liveBiddingUtils.calculateStage2SubmissionDeadline(bidsDueDateUtc, process, latestBidSubmission)
        : undefined;
    return isLiveBiddingStage2 && moment.utc().diff(liveBiddingStage2SubmissionDeadline) > 0;
}