import moment from 'moment';
import { bwicDateFilterOptions } from '../constants';

export function getSearchDateRange(filter) {
    switch (filter.selectedDateOption.key) {
        case bwicDateFilterOptions.thisWeek.key:
            return {
                dateFrom: moment().day(1).startOf('day').format(),
                dateTo: moment().day(5).endOf('day').format()
            };

        case bwicDateFilterOptions.lastWeek.key:
            return {
                dateFrom: moment().subtract(1, 'week').startOf('day').format(),
                dateTo: moment().endOf('day').format()
            };

        case bwicDateFilterOptions.lastMonth.key:
            return {
                dateFrom: moment().subtract(1, 'month').startOf('day').format(),
                dateTo: moment().endOf('day').format()
            };

        case bwicDateFilterOptions.lastYear.key:
            return {
                dateFrom: moment().subtract(1, 'year').startOf('day').format(),
                dateTo: moment().endOf('day').format()
            };

        case bwicDateFilterOptions.custom.key:
            const { customDateRange } = filter;
            if (customDateRange?.from || customDateRange?.to) {
                const { from, to } = customDateRange;
                return {
                    dateFrom: from ? moment(from).startOf('day').format() : undefined,
                    dateTo: to ? moment(to).endOf('day').format() : undefined,
                }
            }
            return {};
        case bwicDateFilterOptions.unspecified.key:
            return { dateFrom: null, dateTo: null };

        default:
            return {
                dateFrom: moment().startOf('day').format()
            };
    }
}

export function getDateRangeOption(dateFrom, dateTo) {
    const result = {
        option: bwicDateFilterOptions.todayAndUpcoming,
        customRange: {}
    };

    const today = moment();
    const monday = moment().day(1);
    const friday = moment().day(5);
    const weekAgo = moment().subtract(1, 'week');
    const monthAgo = moment().subtract(1, 'month');
    const yearAgo = moment().subtract(1, 'year');

    const dateFromOnly = dateFrom && !dateTo;
    const dateToOnly = !dateFrom && dateTo;
    const rangeValid = dateFrom && dateTo && moment(dateTo).diff(dateFrom, 'days') >= 0;
    const dateToToday = moment(dateTo).isSame(today, 'day');
    const todayAndUpcoming = dateFromOnly && moment(dateFrom).isSame(today, 'day');
    const todayOutdated = dateFromOnly && !todayAndUpcoming;
    const thisWeek = rangeValid && moment(dateFrom).isSame(monday, 'day') && moment(dateTo).isSame(friday, 'day');
    const lastWeek = rangeValid && moment(dateFrom).isSame(weekAgo, 'day') && dateToToday;
    const lastMonth = rangeValid && moment(dateFrom).isSame(monthAgo, 'day') && dateToToday;
    const lastYear = rangeValid && moment(dateFrom).isSame(yearAgo, 'day') && dateToToday;

    if (dateFrom === null && dateTo === null) {
        result.option = bwicDateFilterOptions.unspecified;
    } else if (todayOutdated) {
        result.option = bwicDateFilterOptions.custom;
        result.customRange.from = moment(dateFrom).startOf('day').toDate();
        result.customRange.to = moment(dateFrom).add(1, 'year').startOf('day').toDate();
    } else if (dateToOnly) {
        result.option = bwicDateFilterOptions.custom;
        result.customRange.to = moment(dateTo).startOf('day').toDate();
        result.customRange.from = moment(dateTo).add(-1, 'year').startOf('day').toDate();
    } else if (thisWeek) {
        result.option = bwicDateFilterOptions.thisWeek;
    } else if (lastWeek) {
        result.option = bwicDateFilterOptions.lastWeek;
    } else if (lastMonth) {
        result.option = bwicDateFilterOptions.lastMonth;
    } else if (lastYear) {
        result.option = bwicDateFilterOptions.lastYear;
    } else if (rangeValid) {
        result.option = bwicDateFilterOptions.custom;
        result.customRange.from = dateFrom;
        result.customRange.to = dateTo;
    } else {
        result.option = bwicDateFilterOptions.todayAndUpcoming;
    }

    return result;
}
