import { AccessTypeMeta } from '../../analytics/AccessTypeMeta';

export enum CloManagerAccessType {
    VolUsdBslDoor = 'VolUsdBslDoor',
    VolUsdMmDoor = 'VolUsdMmDoor',
    VolEurBslDoor = 'VolEurBslDoor',
    VolEurMmDoor = 'VolEurMmDoor',
    DealIntex = 'DealIntex',
    CloTeamWebsite = 'CloTeamWebsite',
    CloTeamIn = 'CloTeamIn',
    DealDoor = 'DealDoor',
    OverviewTab = 'OverviewTab',
    OverviewTotalTransactions = 'OverviewTotalTransactions',
    OverviewUpcomingDeals = 'OverviewUpcomingDeals',
    OverviewAmrDeal = 'OverviewAmrDeal',
    OverviewGeographicMix = 'OverviewGeographicMix',
    OverviewEsgDeals = 'OverviewEsgDeals',
    OverviewEuCompliant = 'OverviewEuCompliant',
    OverviewStaticDeals = 'OverviewStaticDeals',
    OverviewOutOfNCDoor = 'OverviewOutOfNCDoor',
    OverviewOutOfRIDoor = 'OverviewOutOfRIDoor',
    OverviewArrangers = 'OverviewArrangers',
    OverviewTrustees = 'OverviewTrustees',
    OverviewIssuanceSpreads = 'OverviewIssuanceSpreads',
    OverviewShortLongDatedDeals = 'OverviewShortLongDatedDeals',
    DealTab = 'DealTab',
    CloTeamTab = 'CloTeamTab',
    AdditionalInformationTab = 'AdditionalInformationTab',
    AdditionalInformationUnpri = 'AdditionalInformationUnpri',
    DownloadManagerPresentationFileFromBank = 'DownloadManagerPresentationFileFromBank',
    DownloadLogoFile = 'DownloadLogoFile',
}

export const CloManagerAccessTypes: AccessTypeMeta<CloManagerAccessType>[] = [
    { value: CloManagerAccessType.OverviewTab, text: 'Overview', color: '#235692' },
    { value: CloManagerAccessType.DealTab, text: 'Deals', color: '#669FC3' },
    { value: CloManagerAccessType.CloTeamTab, text: 'CLO Team', color: '#AFE0EB' },
    { value: CloManagerAccessType.AdditionalInformationTab, text: 'Additional Info', color: '#CECECE' },
];
