import { BidOnBehalfConflict } from "../bid-on-behalf/BidOnBehalfConflict";
import { CompanySlim } from "../company/CompanySlim";

export interface BidOnBehalfState {
    isRequesting: boolean
    isSubmitting: boolean
    companies: CompanySlim[]
    editState: {
        // key: positionId-companyId
        [key: string]: EditBidOnBehalfState
    }
    conflicts: BidOnBehalfConflict[]
    isConflictsPpopupVisible: boolean
    focusedBid?: { companyId: number, positionId: number }
}

export const getEditKey = (positionId: number, companyId: number) => `${positionId}-${companyId}`;
export const parseKey = (key: string) => {
    const [positionId, companyId] = key.split('-');
    return {
        positionId: +positionId,
        companyId: +companyId
    };
}

export interface BulkSaveBidOnBehalfDataItem {
    ticker?: string;
    selectBd: number;
    [companyCode: string]: number | string | undefined;
}

export interface EditBidOnBehalfState {
    value?: string
    axed?: boolean
    pass?: boolean
    final?: boolean
    error?: string
    editing?: boolean
}

export interface BidOnBehalfParseResultItem {
    ticker?: string;
    [companyCode: string]: string | undefined;
}

