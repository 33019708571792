import { RequestState } from '../../constants/request-state';
import { ApiError } from '../ApiError';
import { Bid } from '../bidding/Bid';
import { BidConflict } from '../bidding/BidConflict';
import { BwicPosition } from '../bwic/BwicPosition';
import { OpenBiddingStatus } from '../enums/OpenBiddingStatus';

export interface BidConfirmationState {
    bid: EditBidState;
    currentBid?: Bid;
}

export interface BidsConfirmationState {
    [positionId: number]: BidConfirmationState;
}

export interface SubmitResultState {
    requestState: RequestState;
    isBidOverThemself?: boolean;
    stageTransitionConflict?: {
        stagedBiddingStatus?: OpenBiddingStatus;
        canBidOnStage2?: boolean;
    };
    conflict?: BidConflict;
    validationError?: ApiError;
    isLoggedOut?: boolean;
}

export interface SubmitBidConfurmationState {
    securities?: number[]; // Array of positionId
    submitBidsConfirmationVisible: boolean;
    submitInProgress: boolean;
    submitInProgressPositionId?: number;
    submitResult: { [positionId: number]: SubmitResultState };
}

export interface BidError {
    bid?: string;
}

export interface EditBidState {
    value?: string;
    axed?: boolean;
    final?: boolean;
    pass?: boolean;
    canSubmit?: boolean;
    errors?: BidError;
    commission?: string;
    commissionError?: string;
}

export enum BrokerDealerAccessState {
    NotInvited = 1,
    AccessRequested = 2,
    Approved = 3,
    Rejected = 4
}

export interface BrokerDealerBiddingState {
    accessState: BrokerDealerAccessState;
    securities: BwicPosition[];
    editBid: {
        [positionId: number]: EditBidState;
    }
    submitBidsConfirmation: SubmitBidConfurmationState;
}
