import { IColumnDefinition } from "../components/bidding/common/table/types/ColumnDefinition";
import { ColumnConfigKey } from "../types/page-config/column-customizer/ColumnConfigKey";
import { UserConfigColumn } from "../types/user-config/UserConfigColumn";
import { ListBuilder } from "./ListBuilder";

class ColumnListBuilder<TEntity> extends ListBuilder<IColumnDefinition<TEntity>> {
    _config: UserConfigColumn[];

    constructor(config: UserConfigColumn[]) {
        super();
        this._config = config;
    }

    add(...items: IColumnDefinition<TEntity>[]): ColumnListBuilder<TEntity> {
        const displayedItems = items.filter(i =>
            !i.configKey || this._config.some(c => c.displayed && i.configKey === c.name)
        );

        super.add(...displayedItems);
        return this;
    }

    result(): IColumnDefinition<TEntity>[] {
        let orderingMap: { [key: string]: number } = {};
        this._config.forEach(configItem => {
            orderingMap[configItem.name as ColumnConfigKey] = configItem.order;
        })

        return [...this._result].sort((ac, bc) => {
            const a = ac.configKey ? orderingMap[ac.configKey] : 0;
            const b = bc.configKey ? orderingMap[bc.configKey] : 0;
            return a - b;
        });
    }
}

export const createColumnListBuilder = <TEntity>(config?: UserConfigColumn[]) => {
    return config
        ? new ColumnListBuilder<TEntity>(config)
        : new ListBuilder<IColumnDefinition<TEntity>>()
}
