import { PaymentCard } from "../billing/PaymentCard";
import { BillingContact } from '../billing/BillingContact';
import { Country } from "../location/Country";
import { State } from "../location/State";
import { City } from "../location/City";
import { SubscriptionType } from "../billing/SubscriptionType";
import { RequestState } from '../../constants/request-state';
import { BillingHistory } from '../billing/BillingHistory';
import { FilterOption } from '../filters/FilterOption';

export interface SubscriptionBillingHistoryFilterState {
    period: FilterOption[];
    status: FilterOption[];
}

export enum UpgradeSubscriptionStep {
    BillingContact = 1,
    PaymentMethod = 2,
    Summary = 3
}

export interface SubscriptionBillingHistoryState {
    fetchHistoryRequestState: RequestState;
    initialFilter: SubscriptionBillingHistoryFilterState;
    filter: SubscriptionBillingHistoryFilterState;
    appliedFilter: SubscriptionBillingHistoryFilterState;
    history: BillingHistory[];
}

export interface SubscriptionsState {
    isLoading: boolean
    isPaymentCardSaving: boolean
    paymentCards: PaymentCard[]
    addPaymentCardPopupVisible: boolean
    billingContact?: BillingContact
    isBillingContactEdit: boolean
    isBillingContactSaving: boolean
    countries: Country[]
    states: State[]
    cities: City[]
    isCitiesLoading: boolean
    upgradeSubscriptionWizard: {
        isUpgradeSubscriptionPopupVisible: boolean
        activeStep: UpgradeSubscriptionStep
        targetSubscriptionType?: SubscriptionType
    }
    billingHistory: SubscriptionBillingHistoryState
}
