import { TokenPayload } from '../types/account/TokenPayload';

export const jwtParser = {
    parsePayload
}

function parsePayload(token: string): TokenPayload {
    var base64Url = token.split('.')[1]; // take payload part
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );

    return JSON.parse(jsonPayload);
};
