export enum PipelineWidgetColumn {
  company = 'company',
  dealName = 'dealName',
  collateralManager = 'collateralManager',
  collateralType = 'collateralType',
  currency = 'currency',
  pricingDate = 'pricingDate',
  dealBalanceTotal = 'dealBalanceTotal',
  numberOfTransactions = 'numberOfTransactions',
  redirectToIm = 'redirectToIm',
  relatedTransactions = 'relatedTransactions',
  emptySize = 'emptySize',
};
