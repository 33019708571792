export enum UserStatus {
    Active = 1,
    Blocked = 2,
    Invited = 3,
    NotInvited = 4
}

export const userStatusTitle = {
    [UserStatus.Active]: "Active",
    [UserStatus.Blocked]: "Blocked",
    [UserStatus.Invited]: "Invited",
    [UserStatus.NotInvited]: "NotInvited",
}
