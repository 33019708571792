import { constants, pxTalkLevelTypes } from '../constants';
import { numericUtils } from './numeric.utils';

export const pxTalkUtils = {
    normalizeDecimalPlaces,
    getEmptyPxTalk
}

function normalizeDecimalPlaces(value) {
    const regExp = /^\w+(\.\w{0,2})?$/;
    if (numericUtils.isNumber(value) && !regExp.test(value)) {
        const [integer] = value.split('.');
        return  value.substring(0, integer.length + 1 + constants.pxTalkCharactersAfterDot);
    }

    return value;
};

function getEmptyPxTalk() {
    return { levelType: pxTalkLevelTypes.price.key };
};

