import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const bwicMonitorFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.exportAllBwics,
    SubscriptionFeature.BwicMonitorSecuritySearch,
    SubscriptionFeature.BwicMonitorSavedFilters,
    SubscriptionFeature.BwicMonitorAvancedFilters,
    SubscriptionFeature.BwicMonitorColumnCustomizer,
    SubscriptionFeature.BwicMonitorHistoricalData,
    SubscriptionFeature.BwicMonitorBwicsData,
    SubscriptionFeature.BwicMonitorSorting,
    SubscriptionFeature.BwicMonitorNavigation,
    SubscriptionFeature.BwicMonitorAlerts,
    SubscriptionFeature.ExportIndividualBwic,
    SubscriptionFeature.BwicMonitorCanViewSummaryRow
]