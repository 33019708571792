export enum TransactionStatus {
    Draft = 'Draft',
    Active = 'Active',
    Priced = 'Priced',
    Closed = 'Closed',
    Canceled = 'Canceled',
    Deleted = 'Deleted',
    OnHold = 'OnHold',
}

export const TransactionStatuses = [
    { value: TransactionStatus.Draft, text: TransactionStatus.Draft },
    { value: TransactionStatus.Active, text: TransactionStatus.Active },
    { value: TransactionStatus.OnHold, text: "On Hold" },
    { value: TransactionStatus.Priced, text: TransactionStatus.Priced },
    { value: TransactionStatus.Closed, text: TransactionStatus.Closed },
];

export const transactionStatusTitles = {
    [TransactionStatus.Draft]: TransactionStatus.Draft,
    [TransactionStatus.Active]: TransactionStatus.Active,
    [TransactionStatus.Priced]: TransactionStatus.Priced,
    [TransactionStatus.Closed]: TransactionStatus.Closed,
    [TransactionStatus.Canceled]: TransactionStatus.Canceled,
    [TransactionStatus.Deleted]: TransactionStatus.Deleted,
    [TransactionStatus.OnHold]: "On Hold",
};

export const transactionStatusesSortOrder = {
    [TransactionStatus.Active]: 0,
    [TransactionStatus.Priced]: 1,
    [TransactionStatus.Closed]: 2,
    [TransactionStatus.Draft]: 3,
    [TransactionStatus.OnHold]: 4,
    [TransactionStatus.Canceled]: 5,
    [TransactionStatus.Deleted]: 6,
}

