export enum AmrTrancheStatus {
    Draft = 'Draft',
    Initiated = 'Initiated',
    Scheduled = 'Scheduled',
    AcceptingBids = 'AcceptingBids',
    PendingResultCalculation = 'PendingResultCalculation',
    ResultCalculation = 'ResultCalculation',
    AllocationCorrection = 'AllocationCorrection',
    Settlement = 'Settlement',
    NewMarginApplied = 'NewMarginApplied',
    Canceled = 'Canceled',
    Failed = 'Failed'
}

export const amrTrancheStatusTitles = {
    [AmrTrancheStatus.AcceptingBids]: 'Bidding',
    [AmrTrancheStatus.PendingResultCalculation]: 'Pending Result',
    [AmrTrancheStatus.ResultCalculation]: 'Result',
    [AmrTrancheStatus.NewMarginApplied]: 'Success',
    [AmrTrancheStatus.AllocationCorrection]: 'Allocation Correction',
    [AmrTrancheStatus.Failed]: 'Not Refinanced',
    [AmrTrancheStatus.Draft]: AmrTrancheStatus.Draft,
    [AmrTrancheStatus.Initiated]: AmrTrancheStatus.Initiated,
    [AmrTrancheStatus.Scheduled]: AmrTrancheStatus.Scheduled,
    [AmrTrancheStatus.Settlement]: AmrTrancheStatus.Settlement,
    [AmrTrancheStatus.Canceled]: AmrTrancheStatus.Canceled,
}

export const amrTrancheStatusesSelectOptions = Object.values(AmrTrancheStatus).map(status => ({
    key: status,
    title: amrTrancheStatusTitles[status],
}));
