export default class contactsComparer {
    static compareMultiple(original, modified) {
        if ((original === modified) || (!original && !modified)) {
            return true;
        }

        if (!original || !modified || original.length !== modified.length) {
            return false;
        }

        const originalJson = this.generateContactsJson(original);
        const modifiedJson = this.generateContactsJson(modified);

        return originalJson.localeCompare(modifiedJson, undefined, { sensitivity: 'accent' }) === 0;
    }

    static generateContactsJson(contacts) {
        const sorted = contacts
            .sort((a, b) => a.email.localeCompare(b.email, undefined, { sensitivity: 'accent' }))
            .map(c => ({
                id: c.id,
                firstName: c.firstName,
                lastName: c.lastName,
                companyId: c.companyId,
                email: c.email,
                role: c.role,
                phone: c.phone
            }));

        return JSON.stringify(sorted);
    }
}
