import { cloneDeep } from 'lodash';

export enum PanelType {
    Templates = 'Templates'
}
type PanelLocationState<T = undefined> = { type: PanelType; payload?: T };

export enum PopupType {
    TradeAllocationPopup = 'TradeAllocationPopup',
    TradeRejectReason = 'TradeRejectReason',
    SaveBidOnBehalf = 'SaveBidOnBehalf',
    SecurityUsage = 'SecurityUsage',
    WhatsNewPopup = 'WhatsNewPopup',
}
type PopupLocationState<T = undefined> = { type: PopupType; payload?: T };

export type LocationState<TPanelPayload = undefined, TPopupPayload = undefined> = {
    panel?: PanelLocationState<TPanelPayload>;
    popup?: PopupLocationState<TPopupPayload>;
};


export class LocationStateBuilder<TPanelPayload = unknown, TPopupPayload = unknown> {
    _state: LocationState<TPanelPayload, TPopupPayload> = {
        popup: undefined,
        panel: undefined
    }

    constructor(initial?: LocationState<TPanelPayload, TPopupPayload>) {
        if (initial != null) {
            this._state = { ...initial };
        }
    }

    resetPopup() {
        this._state.popup = undefined;
        return this;
    }

    resetPanel() {
        this._state.panel = undefined;
        return this;
    }

    panel(type: PanelType, payload?: TPanelPayload) {
        this._state.panel = { type, payload };
        return this;
    }

    popup(type: PopupType, payload?: TPopupPayload) {
        this._state.popup = { type, payload };
        return this;
    };

    result() {
        return cloneDeep(this._state);
    }
}
