export enum OnBoardingTooltipType {
    addressBook = 'AddressBook',
    allBwics = 'AllBwics',
    blotter = 'Blotter',
    bwicBidding = 'BwicBidding',
    bwicCancelled = 'BwicCancelled',
    bwicColorDistribution = 'BwicColorDistribution',
    bwicFinished = 'BwicFinished',
    bwicNotInvited = 'BwicNotInvited',
    bwicOpenBidding = 'BwicOpenBidding',
    bwicParsed = 'BwicParsed',
    bwicScheduled = 'BwicScheduled',
    directBiddingPopup = 'DirectBiddingPopup',
    editAddressBook = 'EditAddressBook',
    myBwics = 'MyBwics',
    newBwicStep1 = 'NewBwicStep1',
    newBwicStep2 = 'NewBwicStep2',
    newBwicStep3 = 'NewBwicStep3',
    newBwicStep4 = 'NewBwicStep4',
    newBwicStep5 = 'NewBwicStep5',
    newPortfolio = 'NewPortfolio',
    portfolio = 'Portfolio',
    portfolioUploadDocument = 'PortfolioUploadDocument',
    whatsNewPopup = 'WhatsNewPopup',
    webinarAnnouncementPopup = 'WebinarAnnouncementPopup',
    allToAllPopup = 'AllToAllPopup',
    eval = 'Eval',
}
