export enum TransactionType {
    newIssue = 'NewIssue',
    refi = 'Refinancing',
    reset = 'Reset',
    amr = 'AMR'
}

export const TransactionTypes = [
    { value: TransactionType.newIssue, text: 'New Issue' },
    { value: TransactionType.refi, text: 'Refinancing' },
    { value: TransactionType.reset, text: 'Reset' },
    { value: TransactionType.amr, text: 'AMR' },
];

export const AllTopIssuersTransactionTypes = [
    TransactionType.newIssue,
    TransactionType.refi,
    TransactionType.reset,
];

