import { TimeSpan } from "../primitives/TimeSpan";

export type BlastMessageRecipientsState = {
    [companyIndentifier: string]: boolean;
}

export enum BlastMessageRecipientLevelState {
    Top3 = "Top3",
    Top5 = "Top5",
    All = "All"
}

export interface BlastMessageState {
    panelVisible: boolean;
    messageType: number;
    recipientLevel?: BlastMessageRecipientLevelState;
    recipients: BlastMessageRecipientsState;
    isSending: boolean;
    isSelectAllRecipients: boolean;
    stage1AboutToEndTimeout?: TimeSpan;
    error?: string
}

export enum BlastMessageTypes {
    submitFinal = 1,
    bidOrPass = 2,
    stage1AboutToEndTimeout = 3
};
