import moment from 'moment';
import { isNil } from 'lodash';
import arraystat from 'arraystat';
import { numericUtils } from './numeric.utils';
import { RatingGroup, ratingGroupValues } from '../types/dashboard/RatingGroup';
import { moneyUtils } from './money.utils';

const getArrayStats = (array) => arraystat(array);

const getChartData = (historyList, dateFormat, pxTalkLimit) => {
    const result = {
        x: [],
        y: [],
        lineX: [],
        lineY: [],
        markersX: [],
        markersY: [],
        kTalkY: [],
        kTalkX: [],
        sortedByDate: {},
        datesList: {}
    };
    for (let i = 0; i < historyList.length; i++) {
        const { pxTalks = [], bidsDueUtc = '', color, normalizedLevelEvalPriceTalk } = historyList[i];
        const date = moment(bidsDueUtc).format(dateFormat);
        result.datesList[date] = bidsDueUtc;
        if ((color !== null && color.level) || pxTalks.length || !isNil(normalizedLevelEvalPriceTalk)) {
            if (!result.sortedByDate[date]) {
                result.sortedByDate[date] = { pxTalk: [], kTalk: normalizedLevelEvalPriceTalk, color: 0 };
            }

            result.sortedByDate[date].pxTalk.push(...pxTalks.map(p => ({
                normalizedLevel: p.normalizedLevel > pxTalkLimit ? pxTalkLimit : p.normalizedLevel
            })));

            if (color !== null && color.normalizedLevel) {
                result.sortedByDate[date].color += 1
            }
        }
    }

    for (let i = 0; i < historyList.length; i++) {
        const { pxTalks = [], bidsDueUtc = '', color, normalizedLevelEvalPriceTalk } = historyList[i];
        const date = moment(bidsDueUtc).format(dateFormat);
        if (result.sortedByDate[date]) {
            if (pxTalks.length) {
                for (let k = 0; k < pxTalks.length; k++) {
                    const pxTalk = pxTalks[k];
                    result.y.push({
                        value: pxTalk.normalizedLevel > pxTalkLimit ? pxTalkLimit : pxTalk.normalizedLevel,
                        companyName: (pxTalk.company ? pxTalk.company.name : '')
                    });
                    result.x.push(date);
                }
            }

            if (!isNil(normalizedLevelEvalPriceTalk)) {
                const kTalkDateIndex = result.kTalkY.indexOf(date);

                if (kTalkDateIndex < 0) {
                    result.kTalkX.push(date);
                    result.kTalkY.push(normalizedLevelEvalPriceTalk);
                }
            }

            if (color && color.normalizedLevel) {
                const dateIndex = result.lineX.indexOf(date);

                if (dateIndex > -1) {
                    result.lineY[dateIndex] = Math.round(
                        (result.lineY[dateIndex] + color.normalizedLevel) / result.sortedByDate[date].color
                    );
                } else {
                    result.lineY.push(color.normalizedLevel);
                    result.lineX.push(date);
                }

                result.sortedByDate[date].colorValue = result.lineY[dateIndex];

                if (dateIndex > -1 && result.markersX.indexOf(date) > -1) {
                    result.markersX.splice(result.markersX.indexOf(date), 1);
                    result.markersY.splice(result.markersX.indexOf(date), 1);
                }
            } else {
                if (result.markersX.indexOf(date) === -1 && result.lineX.indexOf(date) === -1) {
                    result.markersX.push(date);
                    result.markersY.push(arraystat(result.sortedByDate[date].pxTalk.map((px) => px.normalizedLevel)).median)
                } else {
                    result.markersY[result.markersX.indexOf(date)] = arraystat(result.sortedByDate[date].pxTalk.map((px) => px.normalizedLevel)).median
                }
            }
        } else if (result.x.length) {
            result.y.push({ value: -50, companyName: '' });
            result.x.push(date);
        }
    }

    return result;
};

const getXRange = (allDates, minXRange, range) => {
    const dates = allDates;
    const minRange = dates.length / 20;
    if (range && dates.length > range) {
        return [dates.length - range - minRange, dates.length - minRange];
    }

    const xRange = [-minRange, dates.length - minRange];
    const l = xRange[1] - xRange[0];
    if (l < minXRange) {
        xRange[0] = xRange[0] - l / 2;
        xRange[1] = xRange[1] + l / 2;
    }
    return xRange
};

const getYRange = (charts) => {
    const maxMargin = 3.5;
    let roundTo = 1;
    const yValues = [];
    for (let i = 0; i < charts.length; i++) {
        if (charts[i].y !== undefined) {
            yValues.push(...charts[i].y);
        }
    }

    const range = yValues.every(v => v < 0)
        ? [0, 0]
        : yValues.reduce((val, current) => {
            if (!val[0] || val[0] < 0) {
                val[0] = current;
            }
            if (current && current > 0 && current < val[0]) {
                val[0] = current;
            }

            if (current > val[1]) {
                val[1] = current;
            }
            return val;
        }, [0, 0]);

    const difference = range[1] - range[0];
    if (difference > 5 && difference < 10) {
        roundTo = 5
    } else if (difference > 10 && difference < 50) {
        roundTo = 10
    } else if (difference > 50) {
        roundTo = 20
    }

    let min = range[0];
    let max = chartUtils.roundToNumber(range[1], roundTo);
    if ((range[1] - range[0]) > 0) {
        min = chartUtils.roundToNumber(range[0], roundTo, true);
    }
    if ((range[1] - range[0]) === 0) {
        max += 1
    }
    if (min > 0) {
        min -= roundTo
    }
    return [
        min <= 0 ? 0 : min,
        max + numericUtils.getPercentage(difference || 1, maxMargin)
    ]
};

const setCursorByDiv = (chartDivId, cursorType) => {
    return () => {
        const divElement = document.getElementById(chartDivId);
        if (divElement) {
            const element = divElement.querySelector('.nsewdrag');
            element.style.cursor = cursorType
        }
    }
};

const setCursor = (cursor) => {
    return (e) => {
        if (e.event && e.event.target) {
            e.event.target.style.cursor = cursor
        }
    }
};

const getMoneyString = (amount, sign, showMM = true, round = false) => {
    let amountFormatted = '0MM'
    if(amount){
        amountFormatted = round
            ? moneyUtils.amountToRoundedMM(amount, showMM)
            : moneyUtils.amountToMM(amount, showMM)
    }

    return `${sign}${amountFormatted}`;
}

const getRatingsForSearchUrl = (selectedRatings) => selectedRatings.reduce((result, rating) => {
    if (rating === RatingGroup.OTHER) {
        result = [...result, ...ratingGroupValues[RatingGroup.OTHER]]
    } else {
        result.push(rating);
    }
    return result;
}, []);

function roundToNumber(value, number, toMinus) {
    if (value && number) {
        const balance = value % number;
        if (balance) {
            if (toMinus) {
                return value - balance;
            } else {
                return value - balance + number;
            }
        }
    }
    return value
}

export const chartUtils = {
    getChartData,
    getXRange,
    getYRange,
    getArrayStats,
    setCursorByDiv,
    setCursor,
    getMoneyString,
    getRatingsForSearchUrl,
    roundToNumber
};

export const getRatingForSearchString = (rating) => {
    if (rating === RatingGroup.OTHER) {
        return [...ratingGroupValues[RatingGroup.OTHER]]
    }
    return [rating];
};

export const getVolume = (money) => {
    if ((money / 1000000) < 1) {
        return moneyUtils.amountToMM(money, false)
    }
    return (money / 1000000).toFixed(0)
};
