import { PipelineType } from "../../../../types/amr-pipeline/enums/PipelineType";
import { DealsPipelineFilters, PipelineFilters } from "../../../../types/state/PipelineFilterState";
import { UserFilterValues } from "../../../../types/user-config/UserConfigFilter";
import {
    serializeBooleanFilter,
    serializeDateFilter,
    serializeDateTimeFilter,
    serializeDaysFilter,
    serializeRangeFilter,
    serializeSelectFilter,
    serializeRadioFilter,
} from "./filterSerializers";

export const serializeDealsPipelineFilters = (filterState: DealsPipelineFilters) => {
    return {
        ratings: serializeSelectFilter(filterState.ratings.filter),
        statuses: serializeSelectFilter(filterState.statuses.filter),
        types: serializeSelectFilter(filterState.transactionTypes.filter),
        collateralManagers: serializeSelectFilter(filterState.managers.filter),
        arrangers: serializeSelectFilter(filterState.arrangers.filter),
        pricingDate: serializeDateFilter(filterState.pricingDate.filter),
        closingDate: serializeDateFilter(filterState.closingDate.filter),
        nonCallPeriodEnd: serializeDateFilter(filterState.nonCallEnd.filter),
        nonCallPeriodEndDays: serializeDaysFilter(filterState.nonCallEnd.filter),
        reinvestmentPeriodEnd: serializeDateFilter(filterState.reinvestmentEnd.filter),
        reinvestmentPeriodEndDays: serializeDaysFilter(filterState.reinvestmentEnd.filter),
        rollerDeadline: serializeDateTimeFilter(filterState.rollerDeadline.filter),
        was: serializeRangeFilter(filterState.was.filter),
        warf: serializeRangeFilter(filterState.warf.filter),
        ds: serializeRangeFilter(filterState.ds.filter),
        collateralTypes: serializeSelectFilter(filterState.collateralType.filter),
        amrDeal: serializeBooleanFilter(filterState.amrDeal.filter),
        currencyCodes: serializeSelectFilter(filterState.currency.filter),
        euCompliance: serializeBooleanFilter(filterState.euCompliance.filter),
        classNames: serializeSelectFilter(filterState.classNames.filter),
        trustees: serializeSelectFilter(filterState.trustees.filter),
        parSubordination: serializeRangeFilter(filterState.parSubordination.filter),
        mvoc: serializeRangeFilter(filterState.mvoc.filter),
        esg: serializeBooleanFilter(filterState.esg.filter),
        sofr: serializeBooleanFilter(filterState.sofr.filter),
        isDebut: serializeBooleanFilter(filterState.isDebut.filter),
        // enhancedCLO: serializeBooleanFilter(filterState.enhancedCLO.filter), Temporary Enhanced CLO hiding
        staticDeal: serializeBooleanFilter(filterState.staticDeal.filter),
        vintage: serializeDateFilter(filterState.vintage.filter),
        coupons: serializeRadioFilter(filterState.coupons.filter),
        outOfNC: serializeBooleanFilter(filterState.outOfNC.filter),
        outOfRI: serializeBooleanFilter(filterState.outOfRI.filter),
    } as UserFilterValues;
};

export const serializeArrangerPipelineFilters = (filterState: PipelineFilters<PipelineType.ArrangerPipeline>) => {
    return {
        collateralManagers: serializeSelectFilter(filterState.managers.filter),
        arrangers: serializeSelectFilter(filterState.arrangers.filter),
        collateralTypes: serializeSelectFilter(filterState.collateralType.filter),
        euCompliance: serializeBooleanFilter(filterState.euCompliance.filter),
        ncEnd: serializeRangeFilter(filterState.ncEnd.filter),
        riEnd: serializeRangeFilter(filterState.riEnd.filter),
        equity: serializeRadioFilter(filterState.equity.filter),
        anchor: serializeBooleanFilter(filterState.anchor.filter),
    } as UserFilterValues;
};
