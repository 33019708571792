export enum DocumentType {
    otherDocument = 'OtherDocument',
    otherDealDocument = 'OtherDealDocument',
    indenture = 'Indenture',
    collateralManagementAgreement = 'CollateralManagementAgreement',
    collateralAdministrationAgreement = 'CollateralAdministrationAgreement',
    offeringMemorandum = 'OfferingMemorandum',
    distributionReport = 'DistributionReport',
    monthlyReport = 'MonthlyReport',
    reportsExcelFiles = 'ReportsExcelFiles',
}

export function getDocumentTypeText(documentType: DocumentType): string {
    return documentType.toString().split(/(?=[A-Z])/).join(' ') + 's';
}

export function getDealRegularDocumentTypes(): DocumentType[] {
    return [
        DocumentType.indenture,
        DocumentType.collateralManagementAgreement,
        DocumentType.collateralAdministrationAgreement,
        DocumentType.offeringMemorandum,
        DocumentType.otherDealDocument
    ];
}

export const dealRegularDocTypeLabels = {
    [DocumentType.indenture]: "Indenture",
    [DocumentType.collateralAdministrationAgreement]:
        "Administration Agreement",
    [DocumentType.collateralManagementAgreement]: "Management Agreement",
    [DocumentType.offeringMemorandum]: "Memorandum",
    [DocumentType.otherDealDocument]: "Other",
};

export const dealRegularDocumentList = Object.values(getDealRegularDocumentTypes()).map((t) => ({
    value: t,
    text: dealRegularDocTypeLabels[t as keyof typeof dealRegularDocTypeLabels],
}));
