import { trim } from 'lodash';
import sanitizeHTML from 'sanitize-html';
import { numericUtils } from "./numeric.utils";

export const stringUtils = {
    isNullOrEmpty,
    isNullOrWhiteSpace,
    isValidUrl,
    shorten,
    isHTMLEmpty,
};

function isNullOrEmpty(s?: string | null) {
    return s == null || s === '';
}

function isNullOrWhiteSpace(s?: string | null) {
    return isNullOrEmpty(s) || isNullOrEmpty(String(s).trim());
}

function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

function shorten(s: string, to: number) {
    if (!s || to <= 0) {
        return '';
    }
    if (numericUtils.isNumber(to) && s.length > to) {
        return `${s.substr(0, to)}...`;
    }

    return s;
}

function isHTMLEmpty(html: string) {
    return !trim(sanitizeHTML(html, {
        allowedTags: ['img'],
        allowedAttributes: {}
    }));
}
