export enum CollateralQualityTestValue {
    AssetPar = "AssetPar",
    WalYears = "WalYears",
    BondsPercentage = "BondsPercentage",
    WasPercentage = "WasPercentage",
    MoodysWARF = "MoodysWARF",
    MoodysWeightedAverageRecoveryRatePercentage = "MoodysWeightedAverageRecoveryRatePercentage",
    FitchWeightedAverageRatingFactor = "FitchWeightedAverageRatingFactor",
    FitchWeightedAverageRecoveryRatePercentage = "FitchWeightedAverageRecoveryRatePercentage",
    MoodysCorrelationTest = "MoodysCorrelationTest",
    MoodysDiversityScore = "MoodysDiversityScore",
    SnPWeightedAverageRecoveryRatePercentage = "SnPWeightedAverageRecoveryRatePercentage",
    WeightedAverageCoupon = "WeightedAverageCoupon",
    WeightedAverageCouponOfFixedRateAssets = "WeightedAverageCouponOfFixedRateAssets",
    WeightedAverageCouponOfFloatingRateAssets = "WeightedAverageCouponOfFloatingRateAssets",
    PurchasePrice = 'PurchasePrice',
    LiborFloor = 'LiborFloor',
    FirstLienLoans = 'FirstLienLoans',
    SecondLienLoans = 'SecondLienLoans',
    CovLite = 'CovLite',
    MoodysCaaAssets = 'MoodysCaaAssets',
    SPCCCAssets = 'SPCCCAssets',
}
