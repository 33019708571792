import moment, { Moment, MomentInput } from 'moment';
import { DateInterval, DateAfter } from 'react-day-picker';
import { constants } from '../../constants';
import { errorMessages } from '../../constants/error.messages';
import { TFilter, TFilterType } from '../../types/filters/FilterState';
import isEqual from 'lodash/isEqual';
import { keys, mapValues, pick } from 'lodash';
import { dateRangeFilterOptions } from '../../constants/date-range.filter';
import { getRangeFromDateOption } from '../amr-pipeline.utils';
import { DateFilterOption } from '../../types/filters/DateFilterOption';
import { DateFilterOptions, FilterDateGroup, FilterGroup, FilterType } from '../../types/filters/FilterGroup';
import { numericUtils } from '../numeric.utils';
import { selectedDateOptions } from '../amr-pipeline-filter.utils';

export const filterUtils = {
    validateDate,
    validateFromDate,
    validateToDate,
    isFilterChanged,
    filterByYearIncrement,
    filerByDateRange,
};

function pickDataToCompare(filter: FilterGroup) {
    const pickDates = (filter: FilterDateGroup) => {
        const key = filter.filter.selectedOption?.key;
        const customDates = selectedDateOptions(filter)

        return { key, customDates };
    }

    const pickYears = (filter: FilterDateGroup) => {
        const yearsRange = filter.filter.options.customYearsRange;
        if (!yearsRange) {
            return {};
        }
        return {
            from: numericUtils.isNumber(yearsRange?.from) ? Number(yearsRange?.from) : undefined,
            to: numericUtils.isNumber(yearsRange?.to) ? Number(yearsRange?.to) : undefined
        };
    }

    if (filter.type === FilterType.Date || filter.type === FilterType.YearsDate) {
        return pickDates(filter as FilterDateGroup);
    }

    if (filter.type === FilterType.DateWithYearsRange) {
        return { ...pickYears(filter as FilterDateGroup), ...pickDates(filter as FilterDateGroup) }
    }

    return filter.filter;
}

function isFilterChanged(
    filterType: TFilterType,
    filter: TFilter<typeof filterType>,
    lastAppliedFilter?: TFilter<typeof filterType>,
): boolean {
    if (!lastAppliedFilter) {
        return true;
    }

    const filterValues = mapValues(filter, pickDataToCompare);
    const lastAppliedFilterValues = mapValues(pick(lastAppliedFilter, keys(filter)), pickDataToCompare);

    return !isEqual(filterValues, lastAppliedFilterValues);
}

function validateDate(minSelectYear: number, date?: Date | null) {
    if (date && moment(date).isValid() && date.getFullYear() < minSelectYear) {
        return errorMessages.datePickerInvalidDate;
    }

    if (date && !moment(date).isValid()) {
        return errorMessages.datePickerInvalidDate;
    }

    return '';
}

function validateFromDate(minSelectYear: number, date?: Date | null, to?: Date | null, disabledDays?: DateInterval) {
    if (date && moment(date).isValid() && date.getFullYear() < minSelectYear) {
        return errorMessages.datePickerInvalidFromDate;
    }
    if (date && to && date > to) {
        return errorMessages.fromDateLaterThenToDate;
    }

    if (date && !String(date).includes('_') && !moment(date).isValid()) {
        return errorMessages.datePickerInvalidFromDate;
    }

    const resetTime = (date: Date) => new Date(date).setHours(0, 0, 0, 0);

    if (date) {
        if (disabledDays?.after && resetTime(date) > resetTime(disabledDays.after))
            return errorMessages.datePickerInvalidFromDate;
        if (disabledDays?.before && resetTime(date) < resetTime(disabledDays.before))
            return errorMessages.datePickerInvalidFromDate;
    }

    return '';
}

function validateToDate(minSelectYear: number, date?: Date | null, disabledDays?: DateAfter) {
    if (date && moment(date).isValid() && date.getFullYear() < minSelectYear) {
        return errorMessages.datePickerInvalidToDate;
    }

    if (date && !moment(date).isValid()) {
        return errorMessages.datePickerInvalidToDate;
    }

    if (date && !String(date).includes('_') && !moment(date).isValid()) {
        return errorMessages.datePickerInvalidFromDate;
    }

    if (
        disabledDays &&
        disabledDays.after &&
        date &&
        new Date(date).setHours(0, 0, 0, 0) > new Date(disabledDays.after).setHours(0, 0, 0, 0)
    ) {
        return errorMessages.datePickerInvalidToDate;
    }

    return '';
}

function getDateOptionsWithRange() {
    return dateRangeFilterOptions
        .toArray()
        .map(option => {
            const range = getRangeFromDateOption(option);
            if (range) {
                return {
                    ...option,
                    from: range.from,
                    to: range.to
                }
            }
            return option
        })
}

export const getDateOptionByRange = (defaultOption: DateFilterOption, from?: Date | number | null, to?: Date | number | null) => {

    const parsedOption = getDateOptionsWithRange()
        .find(o => {
            return ((from && o.from) || (to && o.to))
                && ((from instanceof Date && moment(o.from).isSame(from, 'day')) || o.from === from)
                && ((to instanceof Date && moment(o.to).isSame(to, 'day')) || o.to === to)
        });

    switch (true) {
        case from === null && to === null:
            return dateRangeFilterOptions.All;
        case Boolean(parsedOption):
            return parsedOption as DateFilterOption;
        case Boolean(from || to) && (numericUtils.isNumber(from) || numericUtils.isNumber(to)):
            return dateRangeFilterOptions.YearsRange;
        case Boolean(from || to):
            return dateRangeFilterOptions.Custom;
        default:
            return defaultOption;
    }
}

export const convertToApiCriteria = (selectedOption: DateFilterOption, options?: DateFilterOptions) => {
    const normalizeType = (value?: string | number | Date | null | Moment, time?: { hours: number, minutes: number, seconds: number }) => {
        if (value == null) return value;
        if (numericUtils.isNumber(value)) return null;

        const date = moment(value, 'MM/DD/YYYY', true).startOf('day');
        const momentDate = time ? date.hour(time.hours).minute(time.minutes).second(time.seconds) : date;

        // Do not use moment.toDate() to avoid time zone auto-adjustment
        return momentDate.toISOString(true);
    }

    const endOfDay = { hours: 23, minutes: 59, seconds: 59 };

    switch (selectedOption.key) {
        case dateRangeFilterOptions.CurrentInventory.key:
            return { isCurrentInventory: true }
        case dateRangeFilterOptions.All.key:
            return { dateFrom: null, dateTo: null }
        case dateRangeFilterOptions.Custom.key:

            return {
                dateFrom: normalizeType(options?.customDateRange?.from),
                dateTo: normalizeType(options?.customDateRange?.to, endOfDay)
            }
        default:
            return {
                dateFrom: normalizeType(getRangeFromDateOption(selectedOption)?.from),
                dateTo: normalizeType(getRangeFromDateOption(selectedOption)?.to, endOfDay)
            }
    }
}

export function getYearsRange(filter?: FilterDateGroup) {
    const { from, to } = filter?.filter.options.customYearsRange || {};

    return {
        from: numericUtils.isNumber(from) ? Number(from) : undefined,
        to: numericUtils.isNumber(to) ? Number(to) : undefined,
    };
}

function calculateYrsLeft(utcString: Date, fromDateString: Date): number {
    if (!utcString || !fromDateString) {
        return 0;
    }

    const date = moment.utc(utcString);
    const fromDate = moment.utc(fromDateString);
    const days = date.diff(fromDate, 'days');
    return days > 0 ? Number((days / 365).toFixed(2)) : 0;
}

function filterByYearIncrement(from: number, to: number, periodEnd?: Date, closingDate?: Date) {
    if (!periodEnd || !closingDate) {
        return false
    }
    const increment = calculateYrsLeft(periodEnd, closingDate);
    return !(increment < from || increment > to);
}

function filerByDateRange(periodEnd?: Date, from?: string, to?: string) {
    if (!periodEnd || (!from && !to)) return false;
    const momentPeriodEnd = moment(periodEnd);
    const momentFrom = moment(from, constants.dateFormatISO8601);
    const momentTo = moment(to, constants.dateFormatISO8601);
    if (!from) {
        return momentPeriodEnd.isBefore(momentTo);
    }
    if (!to) {
        return momentPeriodEnd.isAfter(momentFrom);
    }
    return momentPeriodEnd.isBetween(momentFrom, momentTo, 'day', '[]')
}

export function validateDateFormat(date: MomentInput, format: string, errorMessage: string, allowEmpty: boolean = true) {
    if(!date && allowEmpty) {
        return false;
    }
    if (!moment(date, format, true).isValid()) {
        return errorMessage;
    }
    return false;
}
