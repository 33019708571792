import { RequestState } from '../constants/request-state';

export function shouldRequest(requestState) {
    const { request, success } = RequestState;
    return requestState !== request && requestState !== success;
}

export function isRequesting(...requestState) {
    return requestState && requestState.some(r => r === RequestState.request);
}

export function isRequestSuccess(...requestState) {
    return requestState && !requestState.some(r => r !== RequestState.success);
}

export function isRequestNone(...requestState) {
    return requestState && requestState.some(r => r === RequestState.none);
}

export function isRequestFailed(...requestState) {
    return requestState && requestState.some(r => r === RequestState.failure);
}
