import { Process, BwicProcessType } from '../types/models/Process';
import { Bid } from '../types/bidding/Bid';
import { biddingUtils } from './bidding.utils';
import { apiUtils } from './api.utils';
import { getLatestStage1Bids } from '../selectors/latest-bids.selector';

// returns object map  { [BidCompanyIdentifier]: boolean }
export function calculateStage2Participants(bids: Bid[], process: Process): { [companyIdentifier: string]: boolean } {
    let stage2Bids: Bid[];

    switch (process.type) {
        case BwicProcessType.Live: stage2Bids = calculate(process.liveBidding?.openBiddingImproverCount ?? 0); break;
        case BwicProcessType.JumpBall: stage2Bids = calculate(process.jumpBall?.improverCount ?? 0); break;
        case BwicProcessType.TopX: stage2Bids = calculate(process?.topX?.improverCount ?? 0); break;
        default: stage2Bids = [];
    }

    const companies = stage2Bids.map(b => biddingUtils.getBidCompanyIdentifier(b));
    return apiUtils.normalize(companies, c => c, () => true);

    function calculate(improverCount: number) {
        return biddingUtils
            .groupBidsByLevel(getLatestStage1Bids(bids))
            .slice(0, Number(improverCount))
            .flat();
    }
}

