import { Bwic } from "../bwic/Bwic";
import { BrokerDealerCompanySlim } from '../company/BrokerDealerCompanySlim';
import { UserSlim } from '../account/UserSlim';
import { Contact } from '../company/Contact';
import { RequestState } from "../../constants/request-state";

export enum BwicVisitorType {
    Seller = 'Seller',
    Buyer = 'Buyer',
    OtherSeller = 'OtherSeller',
    BrokerDealer = 'BrokerDealer',
    Admin = 'Admin',
}

export interface BiddingState {
    bwic: Bwic;
    bwicDealers: BrokerDealerCompanySlim[];
    bwicDealerUsers: UserSlim[];
    bwicDealerContacts: Contact[];
    participantsLoadingState: RequestState;
    isLoading: boolean;
}
