import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";
import { numericUtils } from '../../numeric.utils';

export class NumericArgumentSerializer
    extends QueryStringArgumentSerializer<string> {
    serialize = (value?: string) => value === '' ? undefined: value && encodeURIComponent(value);
    deserialize = (rawValue: string): void => {
        const value = rawValue ? decodeURIComponent(rawValue.trim()) : undefined;
        if (value && numericUtils.isNumber(value) && this.isAccepted(value) && this.onDeserializeCallback) {
            this.onDeserializeCallback(value);
        }
    }
}
