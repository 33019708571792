import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const dealerProfileFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.DealerProfileBankContacts,
    SubscriptionFeature.DealerProfileBankEvents,
    SubscriptionFeature.DealerProfileEmailAlerts,
    SubscriptionFeature.DealerProfileBanksBWICsPxTalk,
    SubscriptionFeature.DealerProfileAnalytics,
    SubscriptionFeature.DealerProfileArrangerPipeline,
    SubscriptionFeature.DealerProfileDeals,
    SubscriptionFeature.DealerProfilePieCharts,
    SubscriptionFeature.DealerProfileStatistics,
    SubscriptionFeature.DealerProfileTransactionsCount,
];
