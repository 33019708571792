import { LevelSpecificationType } from '../enums/LevelSpecificationType';
import { OpenBiddingImproverCount } from '../enums/OpenBiddingImproverCount';
import { OpenBiddingStatus } from '../enums/OpenBiddingStatus';
import { ProcessTemplate } from './ProcessTemplate';
import { JumpBallSettings } from '../bwic-process/JumpBallSettings';
import { LiveBiddingSettings } from '../bwic-process/LiveBiddingSettings';
import { TopXSettings } from '../bwic-process/TopXSettings';
import { BestFootForwardSettings } from '../bwic-process/BestFootForwardSettings';
import { TimeSpan } from '../primitives/TimeSpan';

interface ProcessBase {
    id: number;
    reserveLevelsApply: boolean;
    earlyTrades: boolean;
    rulesText: string;
    type: BwicProcessType;

    // **** Obsolete, use .liveBidding
    stage1Deadline?: number;
    improvementRound?: number;
    levelSpecificationType?: LevelSpecificationType;
    openBiddingImproverCount?: OpenBiddingImproverCount; 
    // ****

    liveBidding?: LiveBiddingSettings;
    jumpBall?: JumpBallSettings;
    topX?: TopXSettings;
    bff?: BestFootForwardSettings;

    autoFeedbackDelay?: TimeSpan;
    minBidIncrement?: number;
    cutOffReminder?: TimeSpan;

    stagedBiddingStatus?: OpenBiddingStatus | null; 
}

export interface Process extends ProcessBase{
    processTemplate: ProcessTemplate;
}

export type NewBwicProcess = ProcessBase & ProcessTemplate;


export enum BwicProcessType {
    Standard = 1,
    Live = 2,
    JumpBall = 3, 
    TopX = 4,
    BestFootForward = 5,
    Unknown = 6
}

