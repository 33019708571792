import moment from "moment";
import { isNil, isString, toString } from 'lodash';
import { FilterDateOption } from "../../../../types/filters/FilterDateOption";
import {
    DateRangeOption,
    DateTimeRangeOption,
    UserConfigDateFilter,
    UserConfigDateTimeFilter,
    UserConfigRangeFilter,
} from "../../../../types/user-config/UserConfigFilter";
import { FilterRangeOption } from "../../../../types/filters/FilterRangeOption";
import { FilterRadioOption } from "../../../../types/filters/FilterRadioOption";
import { FilterBooleanOption } from "../../../../types/filters/FilterBooleanOption";
import { FilterOption } from "../../../../types/filters/FilterOption";
import { numericUtils } from "../../../numeric.utils";
import { constants } from "../../../../constants/constants";
import dateTimeUtils from "../../../dateTime.utils";
import { FilterDateTimeOption } from '../../../../types/filters/FilterDateTimeOption';
import { dateRangeFilterOptions } from "../../../../constants/date-range.filter";

function isNilOrEmpty<T>(value: T | undefined | null ) {
    return isNil(value) || (isString(value) && toString(value) === '');
}

export const serializeDateFilter = (filterDateOption: FilterDateOption) => {
    if (!filterDateOption.selectedOption || filterDateOption.selectedOption.key === dateRangeFilterOptions.All.key) {
        return undefined;
    }

    const { from, to } = filterDateOption.options.customDateRange;

    if (isNilOrEmpty(from) && isNilOrEmpty(to) && !filterDateOption.selectedOption.pureOption) {
        return undefined;
    }

    const filterOptionName = Object.values(DateRangeOption)[filterDateOption.selectedOption.key];

    switch (filterOptionName) {
        case DateRangeOption.Custom:
            return {
                name: filterOptionName,
                value: {
                    from: moment(from).startOf('day').format(constants.formatTimeStringWithoutTimeZone),
                    to: moment(to).endOf('day').format(constants.formatTimeStringWithoutTimeZone),
                },
            } as UserConfigDateFilter;
        default:
            return {
                name: filterOptionName || DateRangeOption.Custom,
            } as UserConfigDateFilter;
    }
};

export const serializeDateTimeFilter = (filterDateTimeOption: FilterDateTimeOption) => {
    if (!filterDateTimeOption.selectedOption) {
        return undefined;
    }

    const { key, from, to } = filterDateTimeOption.selectedOption;

    if (!from || !to) {
        return undefined;
    }

    const filterOptionName = Object.values(DateTimeRangeOption)[key];

    return {
        name: filterOptionName || DateTimeRangeOption.TodayWithTime,
        value: {
            from: {
                value: dateTimeUtils.changeDateTimeZone(from.date, constants.estTimezone).format(),
                option: from.timeOption
            },
            to: {
                value: dateTimeUtils.changeDateTimeZone(to.date, constants.estTimezone).format(),
                option: to.timeOption,
            },
        },
    } as UserConfigDateTimeFilter;
};

export const serializeDaysFilter = (filterDateOption: FilterDateOption) => {
    const { from, to } = filterDateOption.options.customYearsRange || {};

    if (isNilOrEmpty(from) && isNilOrEmpty(to)) {
        return undefined;
    }

    return {
        from: numericUtils.isNumber(from)
            ? numericUtils.floor(Number(from) * 365, 0)
            : undefined,
        to: numericUtils.isNumber(to)
            ? numericUtils.round(Number(to) * 365, 0)
            : undefined,
    } as UserConfigRangeFilter<number>;
};

export const serializeSelectFilter = <T = string | number>(filterSelectOptions: FilterOption<T>[]) => {
    const values = filterSelectOptions
        .filter(o => o.selected)
        .map(o => o.value);

    return values.length ? values : undefined;
};

export const serializeRadioFilter = <T>(filterRadioOption: FilterRadioOption<T>) => {
    return isNilOrEmpty(filterRadioOption.selectedOption)
        ? undefined
        : [filterRadioOption.selectedOption];
};

export const serializeBooleanFilter = (filterBooleanOption: FilterBooleanOption) => {
    const { selectedOption } = filterBooleanOption;

    return isNilOrEmpty(selectedOption) ? undefined : Boolean(selectedOption);
};

export const serializeRangeFilter = (filterRangeOption: FilterRangeOption) => {
    const { from, to } = filterRangeOption;

    if (isNilOrEmpty(from) && isNilOrEmpty(to)) {
        return undefined;
    }

    const parsedFrom = parseFloat(from as string);
    const parsedTo = parseFloat(to as string);

    return {
        from: isNaN(parsedFrom) ? null : parsedFrom,
        to: isNaN(parsedTo) ? null : parsedTo,
    } as UserConfigRangeFilter<number>;
}
