import { AnyAction } from 'redux';
import { LookupDataItem } from '../../components/grid/Cells/Lookup2';
import { OrderByDirection } from '../OrderByDirection';
import { AppState } from './AppState';

export interface GridState {
    searchTerm: string;
    lastAppliedSearchTerm: string;
    orderBy: GridOrderBy;
    headers: GridColumn[];
    dataItems: GridDataItem<any>[];
    selection: number[];
    upload: { status: string; progress: number, filename: string, global: boolean };
    isDataProcessing: boolean;
    inputBlocked: boolean;
    mountedKey: number;
    isValid: boolean;
    rowsLimit: number;
    position?: GridCurrentPosition;
}

export interface GridOrderBy {
    columnName?: string;
    direction: OrderByDirection;
}

export interface GridCurrentPosition {
    index: number;
    columnName?: string;
    editing: boolean;
    editingValue: string;
    editingError?: string;
}

export type GridDataItem<T> = T & {
    isNew?: boolean;
    isUpdate?: boolean;
    isFlagged?: boolean;
    draft?: boolean;
    readonly?: boolean;
    errors?: { columnName: string; message: string; isDuplicated: boolean; }[];
};


export interface ILookup {
    fetchCallback?: (searchTerm: string, requestCancelationSignal: AbortSignal) => LookupDataItem[];
    compareCallback: (searchTerm: string, lookupDataItem: LookupDataItem) => boolean;
    selector?: (s: AppState) => LookupDataItem[];
    onLookupItemSelected: (item: LookupDataItem) => void;
}

export interface GridItemUploadedFile {
    size?: number;
    referenceName?: string;
    name: string
}

export interface GridColumn {
    name: string;
    title: string;
    titleForError?: string;
    headerError?: string;
    validateHeader?: (nonDraftDataItems: GridDataItem<any>) => string | undefined; // returns error message that would be shown in the column header or undefined if valid
    renderTitle?: () => React.ReactNode;
    type?: 'number' | 'integer' | 'money' | 'longText' | 'select' | 'lookup' | 'file';
    className?: string;
    min?: number;
    max?: number;
    length?: number;
    required: boolean;
    readonly?: boolean;
    initialValue?: string;
    unique?: boolean;
    disabledSort?: boolean;
    cancelOnLeave?: boolean;
    mask?: RegExp[];
    pattern?: RegExp;
    keepEmptyOption?: boolean; // Allows to reset 'Select' cell
    canRemove?: boolean;
    file?: {
        maxSize: number,
        acceptedTypes: string;
    };
    format?: (value: any) => string;
    validate?: (value: any, dataItem: GridDataItem<any>, dataItems: GridDataItem<any>[]) => string; // returns error message or empty string if valie
    readonlyCallback?: (dataItem: GridDataItem<any>) => boolean;
    selectSourceItemsCallback?: () => { key: string | number, title: string }[] | string[]; // used when type="select"
    updateDependencyColumnsCallback?: (rowIndex: number) => AnyAction;
    lookup?: ILookup;
}

export enum GridUploadStatus {
    None = 'none',
    Ready = 'ready',
    Uploading = 'uploading',
    UploadingSingle = 'uploadingSingle'
};
