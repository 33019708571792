import moment from "moment";
import { accountService } from "../services/account.service";
import { user } from "./user";
import { jwtParser } from "../utils/jwt.parser";
import { constants } from "../constants/constants";
import { User } from '../types/account/User';
import { logger } from "../logging/logger";

class RefreshTokenController {
    _isTokenRefreshInProgress: boolean = false;
    _tokenRefreshPromise: Promise<string> = Promise.resolve('');

    isExpired = (token: string, limit: number): boolean => {
        const tokenPayload = jwtParser.parsePayload(token);
        const tokenExpirationDate = moment.unix(tokenPayload.exp);

        return tokenExpirationDate.diff(moment(), 'second') < limit;
    }

    refreshToken = (): void => {
        this._tokenRefreshPromise = accountService
            .refreshToken()
            .then(this.storeToken)
            .catch(this.refreshTokenFailed)
            .finally(() => this._isTokenRefreshInProgress = false);
        this._isTokenRefreshInProgress = true;
        this.log('Start token refresh operation');
    }

    storeToken = (userResponse: User): string => {
        if (userResponse) {
            this.log('Token refresh operaiton is completed');
            user.store(userResponse);
        }

        return user.token() ?? '';
    }

    refreshTokenFailed = (e: Error) => {
        logger.exception(e, 'Refresh Token: Failed to refresh token. Logging out.');
        accountService.logout();
        return Promise.reject({ status: 401, text: 'Refresh token failed' });
    }

    ensureTokenUpToDate = (token?: string, refreshToken?: string): Promise<string | undefined> => {
        if (refreshToken && this.isExpired(refreshToken, constants.refreshTokenExpirationLimitSeconds)) {
            return Promise.reject({ status: 401, text: 'Refresh token is expired', silent: true });
        }

        if (token && this.isExpired(token, constants.tokenExpirationLimitSeconds)) {
            this.log('Token is expired');
            if (!this._isTokenRefreshInProgress) {
                this.refreshToken();
            }

            this.log('Wait until token refresh completed');
            return this._tokenRefreshPromise;
        }

        return Promise.resolve(token);
    }

    log = (message: string, payload?: any) => logger.trace('Refresh Token: ' + message, payload)
}

export const refreshTokenController = new RefreshTokenController();
