export enum AppProduct {
    KWatch = 'KWatch',
    Dashboard = 'Dashboard',
    ManagerProfile = 'ManagerProfile',
    DealerProfile = 'DealerProfile',
    IssuanceMonitor = 'IssuanceMonitor',
    Portfolio = 'Portfolio',
    BwicMonitor = 'BwicMonitor',
    Inventory = 'Inventory',
    Integrations = 'Integrations',
    Trading = 'Trading',
    KTalk = 'KTalk',
    DealDocuments = "DealDocuments",
    Resources = 'Resources',
}

export function getAppProductTitle(product: AppProduct) {
    switch (product) {
        case AppProduct.KWatch: return "K-Watch News";
        case AppProduct.ManagerProfile: return "Manager Profile";
        case AppProduct.DealerProfile: return "Bank Profile";
        case AppProduct.IssuanceMonitor: return "Issuance Monitor";
        case AppProduct.BwicMonitor: return "BWIC Monitor";
        case AppProduct.Inventory: return "Dealer Inventory";
        case AppProduct.Trading: return "Trading Access";
        case AppProduct.KTalk: return "K-Talk";
        case AppProduct.DealDocuments: return "Deal Documents";
        default: return product.toString();
    }
}
