import { constants } from '../constants/constants'

const getFullName = (firstName, lastName) => {
    if (!firstName && !lastName) {
        return constants.emptyPlaceholder;
    }

    return [firstName, lastName].filter(name => name).join(' ');
};

const getInitials = (firstName, lastName) => `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;

export const nameUtils = {
    getFullName,
    getInitials
};
