export enum TradeAllocationType {
    PercentOfTrade = 1,
    AmountOfTrade = 2,
    CreditRatings = 3
}

export const tradeAllocationTypeTitle = {
    [TradeAllocationType.PercentOfTrade]: "% of Trade",
    [TradeAllocationType.AmountOfTrade]: "Amount of Trade",
    [TradeAllocationType.CreditRatings]: "Credit Ratings",
}
