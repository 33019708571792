import { mapValues, omit } from 'lodash';
import { GridDataItem } from '../types/state/GridState';

const sanitizeGridItems = <T>(items: GridDataItem<T>[]) => items
    .reduce((acc: T[], item: GridDataItem<T>) => {
        if (item.draft) {
            return acc;
        }

        const sanitizedItem = omit(item, [
            'isNew',
            'isUpdate',
            'isFlagged',
            'draft',
            'readonly',
            'errors',
        ]);

        const withPreserveNulls = mapValues(sanitizedItem, (value) => value === '' ? null : value) as T;

        return [
            ...acc,
            withPreserveNulls
        ];
    }, []);

export const gridUtils = {
    sanitizeGridItems,
};
