import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const inventoryFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.ExportInventory,
    SubscriptionFeature.InventorySecuritySearch,
    SubscriptionFeature.InventorySavedFilters,
    SubscriptionFeature.InventoryAvancedFilters,
    SubscriptionFeature.InventoryColumnCustomizer,
    SubscriptionFeature.InventoryHistoricalData,
    SubscriptionFeature.InventoryNavigation,
    SubscriptionFeature.InventoryBwicColumn,
    SubscriptionFeature.InventoryAlerts,
    SubscriptionFeature.InventoryCanViewSummaryRow
];