export interface BidRequest {
    bidRequestType: BidRequestType;
    created: Date;
}

export enum BidRequestType{
    SubmitFinal = 1,
    BidOrPass = 2
}

export const bidRequestText = {
    [BidRequestType.BidOrPass]: "Please submit your bid or indicate that you pass",
    [BidRequestType.SubmitFinal]: "Please submit your final level"
}