import { values } from 'lodash';
import { Ratings } from '../../../types/enums/Rating';
import { MultipleValuesArgumentSerializer } from './MultipleValuesArgumentSerializer';
import { QueryStringArgumentSerializer } from './QueryStringArgumentSerializer';
import { SingleValueArgumentSerializer } from './SingleValueArgumentSerializer';
import { DateArgumentSerializer } from './DateArgumentSerializer';
import { FlagArgumentSerializer } from './FlagArgumentSerializer';
import { Currencies } from '../../../types/enums/Currency';
import { tradeStatuses } from '../../../constants/bidding';
import { ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';
import { inventoryPositionStatusTitleList } from '../../../components/inventory/inventory-position-status-title';
import { BwicTypes } from '../../../types/enums/BwicType';
import { TradeSide } from '../../../types/trades/TradeSide';
import { tradeTypeTitle } from '../../../types/trades/TradeType';
import { NumericArgumentSerializer } from './NumericArgumentSerializer';
import { tradeSettlementStatusTitle } from '../../../types/settlement/TradeSettlementStatus';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { FloaterIndex } from '../../../types/amr-pipeline/enums/FloaterIndex';
import { IssuanceMonitorTab } from '../../../components/amrPipeline/types/PipelineTabTypes';
import { TransactionStatus } from '../../../types/amr-pipeline/enums/TransactionStatus';
import { CollateralType } from '../../../types/amr-pipeline/enums/CollateralType';
import { BwicMonitorSortByOptions } from '../../../types/state/AllBwicsState';
import { BwicStatusArgumentSerializer } from './BwicStatusArgumentSerializer';
import { PipelineView } from '../../../components/amrPipeline/types/PipelineView';

export const identifiers = (onDeserialized?: (identifiers: string[]) => void) =>
    new MultipleValuesArgumentSerializer('isinCusipsAndTickers', undefined, onDeserialized);

export const ratings = (onDeserialized?: (ratings: string[]) => void) =>
    new MultipleValuesArgumentSerializer('ratings', Ratings, onDeserialized);

export const ratingsGroup = (onDeserialized?: (ratings: string[]) => void) =>
    new MultipleValuesArgumentSerializer('ratings', ratingWithGroupValues, onDeserialized);

export const statuses = (onDeserialized?: (statuses: string[]) => void) =>
    new BwicStatusArgumentSerializer(onDeserialized);

export const tradesStatuses = (onDeserialized?: (statuses: string[]) => void) =>
    new MultipleValuesArgumentSerializer('statuses', tradeStatuses.toArray().map(s => s.title), onDeserialized);

export const settlementStatuses = (onDeserialized?: (statuses: string[]) => void) =>
    new MultipleValuesArgumentSerializer('settlementStatuses', values(tradeSettlementStatusTitle), onDeserialized, '-');

export const currency = (currencies: string[], onDeserialized?: (currency: string) => void) =>
    new SingleValueArgumentSerializer('currency', currencies, onDeserialized);

export const multipleCurrencies = (onDeserialized?: (currencies: string[]) => void) =>
    new MultipleValuesArgumentSerializer('currency', Currencies, onDeserialized);

export const multipleDealers = (dealerIds: string[], onDeserialized?: (dealers: string[]) => void) =>
    new MultipleValuesArgumentSerializer('dealers', dealerIds, onDeserialized);

export const tradeSide = (onDeserialized?: (sideList: string[]) => void) =>
    new MultipleValuesArgumentSerializer('side', [TradeSide.Sell, TradeSide.Buy], onDeserialized);

export const tradeType = (onDeserialized?: (sideList: string[]) => void) =>
    new MultipleValuesArgumentSerializer('type', values(tradeTypeTitle), onDeserialized);

export const dateFrom = (onDeserialized?: (from: Date | null) => void, key?: string) => new DateArgumentSerializer(key ?? 'dateFrom', onDeserialized);

export const dateTo = (onDeserialized?: (to: Date | null) => void, key?: string) => new DateArgumentSerializer(key ?? 'dateTo', onDeserialized);

export const published = (onDeserialized?: (published: boolean) => void) => new FlagArgumentSerializer('published', onDeserialized);

export const inventoryStatuses = (onDeserialized?: (statuses: string[]) => void) =>
    new MultipleValuesArgumentSerializer('statuses', inventoryPositionStatusTitleList, onDeserialized);

export const reportedStatuses = (onDeserialized?: (statuses: string[]) => void) =>
    new MultipleValuesArgumentSerializer('reported', ['Yes', 'No'], onDeserialized);

export const types = (onDeserialized?: (types: string[]) => void) =>
    new MultipleValuesArgumentSerializer('types', BwicTypes, onDeserialized);

export const collateralManagers = (onDeserialized?: (collateralManagers: string[]) => void, acceptableItems: string[] = []) =>
    new MultipleValuesArgumentSerializer('collateralManagers', acceptableItems, onDeserialized);

export const collateralTypes = (onDeserialized?: (collateralTypes: string[]) => void) =>
    new MultipleValuesArgumentSerializer('collateralTypes', values(CollateralType), onDeserialized);

export const transactionTypes = (onDeserialized?: (types: string[]) => void) =>
    new MultipleValuesArgumentSerializer('transactionTypes', values(TransactionType), onDeserialized);

export const reinvestmentEndFrom = (onDeserialized?: (from: string) => void) => new NumericArgumentSerializer('reinvestmentEndFrom', undefined, onDeserialized);
export const reinvestmentEndTo = (onDeserialized?: (to: string) => void) => new NumericArgumentSerializer('reinvestmentEndTo', undefined, onDeserialized);

export const nonCallEndFrom = (onDeserialized?: (from: string) => void) => new NumericArgumentSerializer('nonCallEndFrom', undefined, onDeserialized);
export const nonCallEndTo = (onDeserialized?: (to: string) => void) => new NumericArgumentSerializer('nonCallEndTo', undefined, onDeserialized);

export const pricingDateFrom = (onDeserialized?: (from: Date | null) => void, key?: string) => new DateArgumentSerializer(key ?? 'pricingDateFrom', onDeserialized);
export const pricingDateTo = (onDeserialized?: (to: Date | null) => void, key?: string) => new DateArgumentSerializer(key ?? 'pricingDateTo', onDeserialized);

export const coupon = (onDeserialized?: (coupon: string) => void) => new SingleValueArgumentSerializer('coupon', values(FloaterIndex), onDeserialized);

export const arrangers = (onDeserialized?: (deals: string[]) => void, acceptableItems?: string[]) =>
    new MultipleValuesArgumentSerializer('arrangers', acceptableItems, onDeserialized);

export const trustees = (onDeserialized?: (deals: string[]) => void) =>
    new MultipleValuesArgumentSerializer('trustees', undefined, onDeserialized);

export const currentInventory = (onDeserialized?: (isCurrentInventory: boolean) => void) => new FlagArgumentSerializer('isCurrentInventory', onDeserialized);

export const esg = (onDeserialized?: (esg: boolean) => void) => new FlagArgumentSerializer('esg', onDeserialized);

export const euCompliance = (onDeserialized?: (euCompliance: boolean) => void) => new FlagArgumentSerializer('euCompliance', onDeserialized);

export const staticDeals = (onDeserialized?: (staticDeals: boolean) => void) => new FlagArgumentSerializer('staticDeals', onDeserialized);

export const amr = (onDeserialized?: (anr: boolean) => void) => new FlagArgumentSerializer('amr', onDeserialized);

export const imPipelineTab = (onDeserialized?: (imPipelineType: string) => void) => new SingleValueArgumentSerializer('imPipelineTab', values(IssuanceMonitorTab), onDeserialized);

export const pipelineViewMode = (onDeserialized?: (pipelineViewMode: string) => void) => new SingleValueArgumentSerializer('pipelineViewMode', values(PipelineView), onDeserialized);

export const transactionStatuses = (onDeserialized?: (statuses: string[]) => void) =>
    new MultipleValuesArgumentSerializer('transactionStatuses', values(TransactionStatus), onDeserialized);

export const savedFilter = (onDeserialized?: (filter: string) => void) =>
    new SingleValueArgumentSerializer('filter', undefined, onDeserialized);

export const deals = (onDeserialized?: (deals: string[]) => void) =>
    new MultipleValuesArgumentSerializer(
        'dealsReferenceNames',
        undefined,
        onDeserialized
    );

export const dealsLegalNames = (onDeserialized?: (deals: string[]) => void) =>
    new MultipleValuesArgumentSerializer(
        'dealsLegalNames',
        undefined,
        onDeserialized
    );

export const sizeFrom = (onDeserialized?: (from: string) => void) =>
    new NumericArgumentSerializer('sizeFrom', undefined, onDeserialized);

export const sizeTo = (onDeserialized?: (to: string) => void) =>
    new NumericArgumentSerializer('sizeTo', undefined, onDeserialized);

export const submittedBids = (onDeserialized?: (submittedBidsValue: string) => void) =>
    new NumericArgumentSerializer('submittedBids', undefined, onDeserialized);

export type TDeserializeRange = { years?: { from?: number, to?: number }, dates?: { from: Date | null, to: Date | null } };

export const mixedDateRange = (keyFrom: string, keyTo: string): [QueryStringArgumentSerializer<any>[], () => TDeserializeRange] => {
    let fromDate: Date | null,
        toDate: Date | null,
        fromYear: number | undefined,
        toYear: number | undefined;

    const getDeserializeResult = (): TDeserializeRange => {
        if (fromYear != null || toYear) {
            return { years: { from: fromYear, to: toYear } }
        } else if (fromDate !== undefined || toDate !== undefined) {
            return { dates: { from: fromDate, to: toDate } }
        }
        return {};
    }
    const serializers = [
        new NumericArgumentSerializer(keyFrom, undefined, value => fromYear = +value),
        new DateArgumentSerializer(keyFrom, value => fromDate = value),
        new NumericArgumentSerializer(keyTo, undefined, value => toYear = +value),
        new DateArgumentSerializer(keyTo, value => toDate = value)
    ]
    return [serializers, getDeserializeResult];
};
export const outOfNC = (onDeserialized?: (outOfNC: boolean) => void) => new FlagArgumentSerializer('outOfNC', onDeserialized);
export const outOfRI = (onDeserialized?: (outOfRI: boolean) => void) => new FlagArgumentSerializer('outOfRI', onDeserialized);

export const sorting = (onDeserialized?: (sortBy: string) => void) =>
    new SingleValueArgumentSerializer('sortBy', Object.values(BwicMonitorSortByOptions), onDeserialized);
