export enum TradingOrderByColumn {
    TradeDate = 1,
    TradeStatus = 2,
    TradeType = 3,
    SettlementDate = 4,
    SettlementStatus = 5,
    Isin = 6,
    Cusip = 7,
    Ticker = 8,
    Size = 9,
    Price = 10,
    Commission = 11,
    BuyerShortCode = 12,
    DealerMPID = 13,
    SellerShortCode = 14,
}

export enum TradingOrderByColumnSortOrder {
    asc = 1,
    desc = 2
}
