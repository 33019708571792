export enum TransactionAccessType {
    Overview = 'Overview',
    Structure = 'Structure',
    TargetPortfolio = 'TargetPortfolio',
    ExportTargetPortfolio = 'ExportTargetPortfolio',
    Documents = 'Documents',
    Deal = 'Deal',
    Iois = 'Iois'
}

export const TransactionAccessTypes = [
    { value: TransactionAccessType.Overview, text: 'Overview', color: '#235692' },
    { value: TransactionAccessType.Structure, text: 'Structure', color: '#669FC3' },
    { value: TransactionAccessType.TargetPortfolio, text: 'Target Portfolio', color: '#AFE0EB' },
    { value: TransactionAccessType.Documents, text: 'Documents', color: '#CECECE' },
    { value: TransactionAccessType.Iois, text: 'IOIs', color: '#9B9B9B' },
];
