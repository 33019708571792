import moment from 'moment';
import { ratingSortOrder } from "../types/enums/Rating";
import { numericUtils } from './numeric.utils';
import { roles } from '../constants/roles';
import { tradeSettlementStatusTitle } from '../types/settlement/TradeSettlementStatus';
import { settlementAgreementStatusSortOrdering } from '../types/bid-as-dealer/SettlementAgreementStatus';
import { companyRoles } from "../constants";
import { nameUtils } from './name.utils';
import { isNil } from 'lodash';
import { transactionStatusesSortOrder } from '../types/amr-pipeline/enums/TransactionStatus';

export function compareRating(a, b) {
    if (ratingSortOrder[a] < ratingSortOrder[b]) {
        return -1;
    }
    if (ratingSortOrder[a] > ratingSortOrder[b]) {
        return 1;
    }
    return 0;
}

export function compareRoleShortTitles(a, b) {
    const rolesA = a || [];
    const rolesB = b || [];
    const sortedRolesA = [rolesA.map(r => roles.getShortTitle(r))]
        .sort()
        .join(',');
    const sorderRolesB = [rolesB.map(r => roles.getShortTitle(r))]
        .sort()
        .join(',');
    return compareStrings(sortedRolesA, sorderRolesB);
}

export function compareRoles(a, b) {
    const rolesA = a || [];
    const rolesB = b || [];
    const sortedRolesA = [rolesA.map(r => roles.getTitle(r))]
        .sort()
        .join(',');
    const sorderRolesB = [rolesB.map(r => roles.getTitle(r))]
        .sort()
        .join(',');
    return compareStrings(sortedRolesA, sorderRolesB);
}

export function compareDates(a, b) {
    return new Date(a) - new Date(b)
}

export function compareDatesNullable(a, b) {
    const aDate = a ? new Date(a): new Date(0);
    const bDate = b ? new Date(b): new Date(0);
    return aDate - bDate;
}

export function compareDatesAsc(a, b) {
    const distantFuture = new Date(0);
    const dateA = a ? new Date(a) : distantFuture;
    const dateB = b ? new Date(b) : distantFuture;

    return dateA - dateB;
}

export function compareDatesDesc(a, b) {
    const distantPast = new Date(0);
    const dateA = a ? new Date(a) : distantPast;
    const dateB = b ? new Date(b) : distantPast;

    return dateA - dateB;
}

export function compareDateHours(a, b) {
    return new Date(a).setSeconds(0, 0) - new Date(b).setSeconds(0, 0)
}

export function compareStrings(a, b) {
    return ((a || "").localeCompare(b || ""));
}

export function compareBy(iteratee) {
    return function (a, b) {
        return (iteratee(a) || "").localeCompare(iteratee(b) || "");
    }
}

export function compareIOI(a, b) {
    if (a.classReferenceName === b.classReferenceName) {
        if (a.investor.legalName === b.investor.legalName) {
            return compareNumbersWithNullAcs(a.dm, b.dm);
        }

        return compareStrings(a.investor.legalName, b.investor.legalName);
    }

    return compareStrings(a.classReferenceName, b.classReferenceName);
}

export function compareNumbersWithNullAcs(a, b) {
    return numericUtils.numberOrDefault(a, Number.MIN_VALUE) - numericUtils.numberOrDefault(b, Number.MIN_VALUE);
}

export function compareNumbersWithNullDesc(a, b) {
    return numericUtils.numberOrDefault(b, Number.MIN_VALUE) - numericUtils.numberOrDefault(a, Number.MIN_VALUE);
}

export function compareCountries(a, b) {
    const countryA = a ? a.threeCharsCode : '';
    const countryB = b ? b.threeCharsCode : '';

    return countryA.localeCompare(countryB)
}

export function compareDateAndMonthStamp(a, b) {
    const format = 'MM/YY';
    const aDate = a || '01/70';
    const bDate = b || '01/70';
    return moment(aDate, format).toDate() - moment(bDate, format).toDate();
}

export function compareBoolean(a, b) {
    return (Boolean(a) === Boolean(b)) ? 0 : Boolean(a) ? -1 : 1;
}

export function compareBooleanAndNull(a, b) {
    if (a === b) {
        return 0;
    }

    if (a || isNil(b)) {
        return 1;
    }

    return -1;
}

export function compareNestedStrings(a, b, childrenKey) {
    const subA = a ? a[childrenKey] || '' : '';
    const subB = b ? b[childrenKey] || '' : '';
    return compareStrings(subA, subB);
}

export function compareSettlementStatus(a, b) {
    return compareStrings(
        tradeSettlementStatusTitle[a],
        tradeSettlementStatusTitle[b]
    );
}

export function compareBidAsDealerAgreementStatus(a, b) {
    return settlementAgreementStatusSortOrdering[a] - settlementAgreementStatusSortOrdering[b]
}


export function compareSettlementAccounts(settlementAccountCountA, settlementAccountCountB, agreementA) {
    if (agreementA.signatory?.role === companyRoles.BrokerDealer) {
        return -1
    } else {
        return settlementAccountCountA - settlementAccountCountB
    }
}

const classNameRank = {
    'M': 0,
    'X': 1,
    'Y': 2,
    'A': 3,
    'B': 4,
    'C': 5,
    'D': 6,
    'E': 7,
    'F': 8,
    'G': 9,
    'Equity': 10
}

function getClassNameRank(className) {
    const rank = className?.substring(0, 1).toUpperCase() ?? classNameRank.Equity;
    return classNameRank[rank] ?? classNameRank.Equity;
}
export function compareClassName(a, b) {
    const aRank = getClassNameRank(a);
    const bRank = getClassNameRank(b);
    return aRank - bRank || compareStrings(a, b)
}

export function compareEmpty(a, b) {
    const rankA = a == null ? 1 : -1;
    const rankB = b == null ? 1 : -1;

    return rankA - rankB;
}

export function compareFullNames(a, b) {
    const aFirstName = a ? a.firstName : '';
    const aLastName = a ? a.lastName : '';
    const bFirstName = b ? b.firstName : '';
    const bLastName = b ? b.lastName : '';

    const aFullName = nameUtils.getFullName(aFirstName, aLastName);
    const bFullName = nameUtils.getFullName(bFirstName, bLastName);

    return (aFullName || '').localeCompare(bFullName || '');
}

export function compareTransactionStatuses(a, b) {
    return transactionStatusesSortOrder[a] - transactionStatusesSortOrder[b];
};
