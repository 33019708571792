import { Bid } from '../types/bidding/Bid';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { biddingUtils } from './bidding.utils';

export const jumpBallUtils = {
    canBid,
    canSendFeedback
}

function canBid(
    stagedBiddingStatus?: OpenBiddingStatus | null,
    latestBid?: Bid,
    canBidOnStage2?: boolean) {
    if (stagedBiddingStatus == null) return true;
    if (stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded) return false;
    if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended && latestBid != null) {
        return Boolean(canBidOnStage2) && latestBid.stagedBiddingStatus == null;
    }

    return false;
}

function canSendFeedback(
    stage2Participants: { [companyIdentifier: string]: boolean },
    stagedBiddingStatus?: OpenBiddingStatus | null,
    levelBids?: Bid[]) {
    if (stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded) return false; // bidding ended
    if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
        if (levelBids?.some(b => b.pass)) return false; // pass
        if (!levelBids?.length) return false; // no bids
        if (levelBids?.every(b => b.stagedBiddingStatus === OpenBiddingStatus.stage1Ended)) return false; // all satge2 bids alreay sent
        if (!levelBids.some(b => stage2Participants[biddingUtils.getBidCompanyIdentifier(b)])) return false; // out of top-x
    }

    return true;
}
