
import { isNil, isEqual, uniqBy } from 'lodash';
import {
    ObjectConfig,
    DifferenceConfig,
    DifferenceType,
    Dynamics,
    Iteratee,
    ObjectArrayConfig,
    ObjectArrayDifference,
    PrimitiveDifference,
    Traverse,
    Difference,
    ObjectArrayItemDifference,
} from './types';
import { defaultIteratee, isPrimitive, noValue } from './utils';

export const Differ = <T extends Object>(config: DifferenceConfig<T> = {}) => {
    const defaultObjectConfig: ObjectConfig<T> = {
        traverse: Traverse.Deep,
    };

    const withIteratee = <K>(iteratee: Iteratee<K>) => (a: K) => (b: K) => iteratee(a) === iteratee(b);

    const getDerivedValue = <K>(current?: K, previous?: K) => {
        if (typeof(current) === 'boolean' || typeof(previous) === 'boolean') {
            return current;
        }

        return current || previous;
    };

    const getDynamics = <K>(current?: K, previous?: K) => {
        if (typeof current !== "number" || typeof previous !== "number") {
            return undefined;
        }

        if (current === previous) {
            return undefined;
        }

        if (previous === undefined) {
            return Dynamics.Increased;
        }

        if (current === undefined) {
            return Dynamics.Decreased;
        }

        return current > previous ? Dynamics.Increased : Dynamics.Decreased;
    }

    const getPrimitiveDifferenceType = <K>(previousValue?: K, currentValue?: K): DifferenceType => {
        if ((noValue(previousValue) && noValue(currentValue)) || isEqual(previousValue, currentValue)) {
            return DifferenceType.Unchanged;
        }

        if (noValue(previousValue) && !noValue(currentValue)) {
            return DifferenceType.Added;
        }

        if (!noValue(previousValue) && noValue(currentValue)) {
            return DifferenceType.Removed;
        }

        return DifferenceType.Updated;
    }

    const getArrayItemDifferenceType = <K>(iteratee: Iteratee<K>, previousList: K[] = [], currentList: K[] = []) => {
        return (item: K) => {
            const byIteratee = withIteratee(iteratee);

            const previous = previousList.find(byIteratee(item));
            const current = currentList.find(byIteratee(item));

            // Present in both current and previous arrays — Unchanged
            if (previous && current) {
                return DifferenceType.Unchanged;
            }

            // Present only in previous array — Removed
            if (previous) {
                return DifferenceType.Removed;
            }

            // Otherwise — Added
            return DifferenceType.Added;
        };
    }

    const findPrimitiveDifference = <K>(
        previousValue?: K,
        currentValue?: K
    ): PrimitiveDifference<K> => ({
        previousValue,
        currentValue,
        derivedValue: getDerivedValue(currentValue, previousValue),
        type: getPrimitiveDifferenceType(previousValue, currentValue),
        dynamics: getDynamics(currentValue, previousValue),
    });

    const findArrayDifference = <K extends Object>(
        config: ObjectArrayConfig<K[]>,
        previousValue: K[],
        currentValue: K[]
    ): ObjectArrayDifference<K[]> => {
        const {
            iteratee = defaultIteratee,
            traverse = Traverse.Deep,
            nestedConfig = undefined,
        } = config;

        const byIteratee = withIteratee(iteratee);

        const uniqItems = uniqBy([...previousValue, ...currentValue], iteratee);

        return uniqItems.map((item: K) => {
            const type = getArrayItemDifferenceType(iteratee, previousValue, currentValue)(item);

            const previousItem = previousValue?.find(byIteratee(item));
            const currentItem = currentValue?.find(byIteratee(item));

            const result = {
                type,
                previousValue: previousItem,
                currentValue: currentItem,
                derivedValue: currentItem || previousItem,
                difference: {},
                id: iteratee(item),
            } as ObjectArrayItemDifference<K>;

            const primitiveItems = isPrimitive(previousItem) && isPrimitive(currentItem);
            const shallowTraverse = traverse === Traverse.Shallow;

            // Skip deep check on Shallow traverse or when one of objects is added/removed
            if (shallowTraverse || primitiveItems) {
                return {
                    ...result,
                    type: getPrimitiveDifferenceType(previousItem, currentItem),
                };
            }

            const differ = Differ<K>(nestedConfig);
            const difference = differ(previousItem, currentItem);

            // Top-level check will return Unchanged type, but we should derive more
            // previse difference type from the differences in nested object items
            if (type === DifferenceType.Unchanged) {
                const changedDifferenceType = Object.values(difference).reduce((acc: DifferenceType, field: PrimitiveDifference<unknown>) => {
                    if (field.type !== DifferenceType.Unchanged) {
                      return DifferenceType.Updated;
                    }

                    return acc;
                }, type);

                return {
                    ...result,
                    type: changedDifferenceType,
                    difference,
                };
            }

            return {
                ...result,
                difference,
            };
        });
    }

    const findObjectDifference = (previousValue?: T, currentValue?: T): Difference<T> => {
        if (isNil(previousValue) && isNil(currentValue)) {
            throw new Error('Both current and previous values cannot be undefined');
        }

        const fields = Object.keys({ ...previousValue, ...currentValue }) as (keyof T)[];

        return fields.reduce((acc, key: keyof T) => {
            const fieldConfig = (config[key] as ObjectConfig<unknown>) || defaultObjectConfig;
            const { traverse } = fieldConfig;

            if (traverse === Traverse.Skip) {
                return acc;
            }

            const current = currentValue && currentValue[key];
            const previous = previousValue && previousValue[key];

            if (current instanceof Array || previous instanceof Array) {
                return {
                    ...acc,
                    [key]: findArrayDifference(fieldConfig, (previous || []) as Object[], (current || []) as Object[]),
                };
            }


            if ((isPrimitive(current) && isPrimitive(previous)) || traverse === Traverse.Shallow) {
                return {
                    ...acc,
                    [key]: findPrimitiveDifference(previous, current),
                };
            }

            const nestedConfig = fieldConfig?.nestedConfig;
            const differ = Differ(nestedConfig);

            return {
                ...acc,
                [key]: differ(previous as Object, current as Object),
            };
        }, {});
    }

    return (previousValue?: T, currentValue?: T): Difference<T> => {
        if (isNil(previousValue) && isNil(currentValue)) {
            return {};
        }

        return findObjectDifference(previousValue, currentValue);
    }
};
