export enum TradeType {
    Desk = 1,
    Client = 2,
    Empty = 3
}

export const tradeTypeTitle = {
    [TradeType.Desk]: 'Desk',
    [TradeType.Client]: 'Client',
    [TradeType.Empty]: '—'
};
