import { keys } from "lodash"
import { BwicStatus } from "../../../types/enums/BwicStatus"
import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";

export class BwicStatusArgumentSerializer extends QueryStringArgumentSerializer<string[]> {
    constructor(onDeserializeCallback?: (value: string[]) => void) {
        super("statuses", keys(BwicStatus), onDeserializeCallback);
    }
    serialize = (values: string[] | undefined) => {
        if(!values?.length) return undefined;

        return values
            ?.map(x => BwicStatus[Number(x)])
            .join(',')
    }

    deserialize = (rawValue: string): void => {
        //convert status titles to status values
        const bwicStatusValues =
            rawValue
                .split(',')
                .map(value => value.trim())
                .filter(value => value && this.isAccepted(value))
                .map(value => BwicStatus[value.toLowerCase() as keyof typeof BwicStatus].toString());

        if(bwicStatusValues?.length){
            this.onDeserializeCallback?.(bwicStatusValues);
        }
    }
}
