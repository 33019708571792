export const jsonUtils = {
    tryParse,
    parse
};

function tryParse(string) {
    if (!string) {
        return null;
    }

    let json;
    try {
        json = JSON.parse(string);
    } catch { }

    if (json && typeof json === "object") {
        return json;
    }

    return null;
}

function parse(json) {
    try {
        return typeof json === 'object' ? json : JSON.parse(json);
    } catch (e) {
        return null;
    }
}
