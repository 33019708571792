import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const managerProfileFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.ManagerProfilePresentations,
    SubscriptionFeature.ManagerProfileScheduleMeeting,
    SubscriptionFeature.ManagerProfileManagerRelatedAlerts,
    SubscriptionFeature.ManagerProfileWebinarVideo,
    SubscriptionFeature.ManagerProfileIssuanceSpreadsGraph,
    SubscriptionFeature.ManagerProfileDealsGraph,
    SubscriptionFeature.ManagerProfilePrimaryMarketData,
    SubscriptionFeature.ManagerProfileSecondaryMarketData,
    SubscriptionFeature.ManagerProfileContacts,
    SubscriptionFeature.ManagerProfileDeals,
    SubscriptionFeature.ManagerProfileAnalytics,
];
