import { arrayUtils } from './array.utils';
import { formatUtils } from './format.utils';
import { numericUtils } from './numeric.utils';
import { stringUtils } from './string.utils';
import { constants } from '../constants/constants';
import { errorMessages } from '../constants/error.messages';
import { Bid } from '../types/bidding/Bid';
import { BwicPosition } from '../types/bwic/BwicPosition';
import { CompanySlim } from '../types/company/CompanySlim';
import { BidCompanyIdentifier } from '../types/bidding/BidCompanyIdentifier';
import { BwicProcessType, Process } from '../types/models/Process';
import { BidError } from '../types/state/BrokerDealerBiddingState';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { TradeStatus } from '../types/trades/TradeStatus';
import { round } from 'lodash';
import { BidderCompanySlim } from '../types/company/BidderCompanySlim';
import { Feedback } from '../types/bidding/Feedback';

export const biddingUtils = {
    getBidsByLevel,
    getBidLevel,
    groupBidsByLevel,
    canTradePosition,
    getCurrentBid,
    getBidCompanyIdentifier,
    parseBidCompanyIdentifier,
    validateBid,
    getBidderShortcode,
    getBidderName,
    getUniqueBidId,
    getUniqueBidIdFromFeedback
}

function getBidderShortcode(company: BidderCompanySlim) {
    const code = company.code.toUpperCase();
    return company.buyerPseudoOrderNumber ? `${code}-${company.buyerPseudoOrderNumber}` : code;
}

function getBidderName(company: BidderCompanySlim) {
    const code = company.code.toUpperCase();
    return company.buyerPseudoOrderNumber ? `${code}-${company.buyerPseudoOrderNumber}` : company.name;
}

function getBidsByLevel(bids: Bid[], level: number): Bid[] {
    if (!bids || !bids.length || !numericUtils.isNumber(level) || +level < 0) {
        return [];
    }

    const bidsByValue = groupBidsByLevel(bids);

    return bidsByValue[level] ?? [];
}

function getBidLevel(bid: Bid, bids: Bid[]): number | undefined {
    if (!bid || !bids || !bids.length) {
        return undefined;
    }

    const bidsByValue = groupBidsByLevel(bids);

    for (var i = 0; i < bidsByValue.length; i++) {
        const group = bidsByValue[i];

        if (group.some(b => b.id === bid.id)) {
            return i;
        }
    }

    return undefined;
}

function groupBidsByLevel(bids: Bid[]): Bid[][] {
    if (!bids || !bids.length) {
        return [];
    }

    const sortedBids = bids
        .filter(b => !b.pass)
        .sort((a, b) => +b.value - +a.value);

    const grouped: Map<number, Bid[]> = arrayUtils.groupBy(sortedBids, (bid: Bid) => +bid.value);

    return [...grouped.values()];
}

function canTradePosition(position?: BwicPosition) {
    return (
        position != null &&
        position.bids != null &&
        position.bids.length > 0 &&
        position.bids.some(b => !b.pass) &&
        (position.trade == null || position.trade.status === TradeStatus.rejected)
    );
}

// Gets current bid for Broker-Dealer
// BD bid list always contain the single actual bid or empty
function getCurrentBid(bids: Bid[]) {
    const bid = bids && bids[0];
    return bid;
}

// company identifier: companyId or companyId-companyCode-buyerPseudoOrderNumber;
// buyerPseudoOrderNumber: number | undefined, company: CompanySlim.ts
function getBidCompanyIdentifier(bid: { buyerPseudoOrderNumber?: number, company: CompanySlim }): BidCompanyIdentifier {
    const identifier = bid.buyerPseudoOrderNumber
        ? `${bid.company.id}-${bid.company.code}-${bid.buyerPseudoOrderNumber}`
        : String(bid.company.id);

    return identifier as BidCompanyIdentifier;
}

export type BidCompanyIdentifierParseResult = {
    brokerDealerCompanyId: number;
    brokerDealerCompanyCode?: string;
    buyerPseudoOrderNumber?: number;
};

function parseBidCompanyIdentifier(identifier: BidCompanyIdentifier): BidCompanyIdentifierParseResult | {} {
    if (stringUtils.isNullOrWhiteSpace(identifier)) {
        return {};
    }

    const [brokerDealerCompanyId, brokerDealerCompanyCode, buyerPseudoOrderNumber] = identifier.split('-');

    return {
        brokerDealerCompanyId: Number(brokerDealerCompanyId),
        brokerDealerCompanyCode,
        buyerPseudoOrderNumber: numericUtils.isNumber(buyerPseudoOrderNumber) ? +buyerPseudoOrderNumber : undefined
    };
}

function validateBid(
    currentBid: Bid | undefined,
    bid: { value?: string, pass?: boolean } | undefined,
    bwicProcess: Process,
    openBiddingStage2Level?: number
): BidError {
    const errors: BidError = {};

    if (bid && !bid?.pass && bid?.value != null) {
        const currentBidLevel = numericUtils.numberOrDefault(currentBid?.value);
        const newBidLevel = numericUtils.numberOrDefault(bid.value);

        if (!numericUtils.isNumber(bid.value)) {
            errors.bid = errorMessages.invalidValue;
        } else if (currentBid && currentBidLevel > 0 && newBidLevel < currentBidLevel) {
            errors.bid = errorMessages.bidShouldBeGreater;
        } else if (currentBidLevel !== newBidLevel && (
            newBidLevel < constants.bidRange.min || newBidLevel > constants.bidRange.max)) {
            errors.bid = errorMessages.bidShouldBeBetween;
        } else if (
            bwicProcess.type === BwicProcessType.Live &&
            bwicProcess.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
            bwicProcess.liveBidding != null &&
            bwicProcess.liveBidding.minBidIncrement &&
            openBiddingStage2Level &&
            (!currentBid || newBidLevel !== currentBidLevel) &&
            round((newBidLevel - openBiddingStage2Level), 4) < bwicProcess.liveBidding.minBidIncrement) {
            errors.bid = errorMessages.bidShouldBeGreaterThan(formatUtils.formatBid(openBiddingStage2Level + bwicProcess.liveBidding.minBidIncrement));
        } else if (
            [
                BwicProcessType.BestFootForward,
                BwicProcessType.JumpBall,
                BwicProcessType.TopX,
                BwicProcessType.Standard
            ].includes(bwicProcess.type) &&
            bwicProcess.minBidIncrement &&
            currentBidLevel &&
            newBidLevel &&
            newBidLevel !== currentBidLevel &&
            round((newBidLevel - currentBidLevel), 4) < bwicProcess.minBidIncrement
        ) {
            errors.bid = errorMessages.lessThenMinBidIncrement(formatUtils.formatBid(bwicProcess.minBidIncrement))
        }
    }

    return errors;
}

function getUniqueBidId(bid: { id: number, company: CompanySlim }) {
    return `${bid.id}-${bid.company.id}`;
}

function getUniqueBidIdFromFeedback(feedback: Feedback) {
    return getUniqueBidId({ id: feedback.bidId, company: feedback.brokerDealer })
}
