import moment from 'moment/moment';
import { collateralTypes } from "../../constants/collateral-types";
import { dateRangeFilterOptions } from "../../constants/date-range.filter";
import { CollateralType } from "../amr-pipeline/enums/CollateralType";
import { BwicSearchResult } from "../bwic-monitor/BwicSearchResult";
import { Currencies, Currency } from "../enums/Currency";
import { Rating, Ratings } from "../enums/Rating";
import { FilterBooleanGroup, FilterDateGroup, FilterRangeGroup, FilterSelectGroup, FilterType } from '../filters/FilterGroup';
import { SubscriptionFeature } from '../billing/SubscriptionFeature';
import { user } from '../../user';
import { BwicStatus } from '../enums/BwicStatus';
import { BwicSearchResultSummary } from '../bwic-monitor/BwicSearchResultSummary';
import { RequestState } from '../../constants/request-state';

export interface AllBwicsState {
    bwics?: BwicSearchResult[];
    myBwicsOnly: boolean;
    isAllToAll: boolean;
    activeBwicReferenceName?: string;
    totalRecordNumber: number;
    filterChanged: boolean;
    filterApplied: boolean;
    searchInProgress: RequestState;
    isNextPageRequesting: boolean;
    isRequestingExportBwics: boolean;
    sortBy: BwicMonitorSortByOptions;
    advancedFilterBlocked: boolean;
    summary?: BwicSearchResultSummary;
}

const getDateFilter = (): FilterDateGroup => ({
    key: 'date',
    type: FilterType.Date,
    visible: true,
    text: 'Date',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: user.hasFeatures(SubscriptionFeature.BwicMonitorHistoricalData) ? {
        selectedOption: { ...dateRangeFilterOptions.All, default: true },
        options: { customDateRange: { from: null, to: null } },
    } : {
        selectedOption: { ...dateRangeFilterOptions.TodayAndUpcoming, default: true },
        options: { customDateRange: { from: moment().toDate(), to: null } },
    },
    acceptableOptions: dateRangeFilterOptions.bwicMonitor(),
    requiredFeature: SubscriptionFeature.BwicMonitorHistoricalData
})

const dateSellBuy: FilterDateGroup = {
    key: 'date',
    type: FilterType.Date,
    visible: true,
    text: 'Date',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: { ...dateRangeFilterOptions.All, default: true },
        options: {
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.bwicMonitorSellBuy(),
    requiredFeature: SubscriptionFeature.BwicMonitorHistoricalData
}

const size: FilterRangeGroup = {
    key: 'size',
    type: FilterType.Range,
    visible: true,
    text: 'Size (MM)',
    disabled: false,
    selected: false,
    isApplied: true,
    decimals: 3,
    filter: { from: '', to: '', minValue: '0.000', maxValue: '999.999' },
};
const statuses: FilterSelectGroup<number> = {
    key: 'statuses',
    type: FilterType.Select,
    visible: true,
    text: 'Status',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: [{
        value: BwicStatus.scheduled,
        text: 'Scheduled',
        selected: false,
        visible: true,
        disabled: false
    }, {
        value: BwicStatus.bidding,
        text: 'Bidding',
        selected: false,
        visible: true,
        disabled: false
    }, {
        value: BwicStatus.finished,
        text: 'Finished',
        selected: false,
        visible: true,
        disabled: false
    }, {
        value: BwicStatus.canceled,
        text: 'Canceled',
        selected: false,
        visible: true,
        disabled: false
    }
    ]
};
const collateralType: FilterSelectGroup<CollateralType> = {
    key: 'collateralType',
    type: FilterType.Select,
    visible: true,
    text: 'Collat.',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: [
        {
            value: CollateralType.broadlySyndicated,
            text: collateralTypes.BroadlySyndicated,
            selected: false,
            disabled: false,
            visible: true,
        },
        {
            value: CollateralType.middleMarket,
            text: collateralTypes.MiddleMarket,
            selected: false,
            disabled: false,
            visible: true,
        },
    ],
};
const currency: FilterSelectGroup<Currency> = {
    key: 'currency',
    type: FilterType.Select,
    visible: true,
    text: 'Ccy',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: Currencies.map(v => ({
        value: v,
        text: v,
        selected: false,
        disabled: false,
        visible: true,
    }))
};
const managers: FilterSelectGroup<number> = {
    key: 'managers',
    withSearch: true,
    type: FilterType.Select,
    visible: true,
    text: 'Manager',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: [],
};
const ratings: FilterSelectGroup<Rating> = {
    key: 'ratings',
    type: FilterType.Select,
    visible: true,
    isApplied: true,
    disabled: false,
    selected: false,
    text: 'Rtg',
    filter: Ratings.map(v => ({
        value: v,
        text: v,
        selected: false,
        disabled: false,
        visible: true,
    }))
};
const esg: FilterBooleanGroup = {
    key: 'esg',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'ESG',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    }
};
const euCompliance: FilterBooleanGroup = {
    key: 'euCompliance',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'EU Compliance',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    },
};
const staticDeal: FilterBooleanGroup = {
    key: 'staticDeal',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'Static Deal',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    },
};
const nonCallEnd: FilterDateGroup = {
    key: 'nonCallEnd',
    type: FilterType.DateWithYearsRange,
    visible: false,
    text: 'NC End',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: undefined,
        options: {
            customYearsRange: { from: '', to: '' },
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.nonCallEnd(),
};
const reinvestmentEnd: FilterDateGroup = {
    key: 'reinvestmentEnd',
    type: FilterType.DateWithYearsRange,
    visible: false,
    text: 'RI End',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: undefined,
        options: {
            customYearsRange: { from: '', to: '' },
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.reinvestmentEnd(),
};
const outOfNC: FilterBooleanGroup = {
    key: 'outOfNC',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'Out of NC',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    },
}
const outOfRI: FilterBooleanGroup = {
    key: 'outOfRI',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'Out of Rl',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    },
}

const maturity: FilterDateGroup = {
    key: 'maturity',
    type: FilterType.Date,
    visible: false,
    text: 'Maturity',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: undefined,
        options: {
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.maturity(),
    disabledDays: { before: moment().add(1, 'day').toDate() },
};

const vintage: FilterDateGroup = {
    key: 'vintage',
    type: FilterType.YearsDate,
    visible: false,
    text: 'Vintage',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: undefined,
        options: {
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.vintage(),
}
const closing: FilterDateGroup = {
    key: 'closing',
    type: FilterType.Date,
    visible: false,
    text: 'Closing',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: undefined,
        options: {
            customDateRange: { from: null, to: null },
        },
    },
    acceptableOptions: dateRangeFilterOptions.closing(),
    disabledDays: { after: moment().subtract(1, 'day').toDate() },
}

const trustees: FilterSelectGroup<string> = {
    withSearch: true,
    type: FilterType.Select,
    visible: false,
    text: 'Trustee',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: [],
    key: 'trustees',
}
const amr: FilterBooleanGroup = {
    key: 'amr',
    type: FilterType.BooleanRadio,
    visible: false,
    text: 'Deal with AMR',
    disabled: false,
    selected: false,
    isApplied: true,
    filter: {
        selectedOption: null,
    },
}

export const bwicMonitorFilters = {
    visibleFilters: ['date', 'size', 'statuses', 'collateralType', 'currency', 'managers', 'ratings'],
    additionalFilters: ['esg', 'maturity', 'vintage', 'closing', 'trustees', 'amr', 'euCompliance', 'staticDeal', 'nonCallEnd', 'reinvestmentEnd', 'outOfNC', 'outOfRI'],
    defaultFilters: () => ({
        date: getDateFilter(), size, statuses, collateralType, currency, managers, ratings, esg,
        euCompliance, staticDeal, nonCallEnd, reinvestmentEnd, maturity, vintage, closing, trustees, amr, outOfNC, outOfRI
    })
};
export const bwicMonitorBuyFilters = {
    visibleFilters: ['date', 'size', 'statuses', 'currency', 'ratings'],
    additionalFilters: [],
    defaultFilters: { date: dateSellBuy, size, statuses, currency, ratings }
};
export const bwicMonitorSellFilters = {
    visibleFilters: ['date', 'size', 'statuses', 'currency', 'ratings'],
    additionalFilters: [],
    defaultFilters: { date: dateSellBuy, size, statuses, currency, ratings }
};

export enum BwicMonitorSortByOptions {
    NewestFirst = 'Newest-First',
    OldestFirst = 'Oldest-First',
}

export const bwicMonitorSortByOptionsText = {
    [BwicMonitorSortByOptions.NewestFirst]: 'Newest first',
    [BwicMonitorSortByOptions.OldestFirst]: 'Oldest first',

}

export const bwicMonitorSortByApiValues = {
    [BwicMonitorSortByOptions.NewestFirst]: { field: undefined, direction: 'DESC' },
    [BwicMonitorSortByOptions.OldestFirst]: { field: undefined, direction: 'ASC' },
}
