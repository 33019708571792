import { PipelineType } from '../amr-pipeline/enums/PipelineType';
import { ArrangerPipelineFilters, DealsPipelineFilters } from '../state/PipelineFilterState';
import { FilterGroup } from './FilterGroup';
import { RedirectFilterOption } from './RedirectFilterOption';

export enum BwicFilterType {
    BwicMonitor = 'BwicMonitor',
    BwicMonitorSell = 'BwicMonitorSell',
    BwicMonitorBuy = 'BwicMonitorBuy',
    Inventory = 'Inventory',
    Portfolio = 'Portfolio',
    BDInventory = 'BDInventory',
}

export type CommonFilter = {
    [x: string]: FilterGroup;
};

export type TFilterType = BwicFilterType | PipelineType;

export type TFilter<FType extends TFilterType> = FType extends BwicFilterType
    ? CommonFilter
    : FType extends PipelineType.ArrangerPipeline
      ? ArrangerPipelineFilters
      : DealsPipelineFilters;

export interface FilterState<FType extends TFilterType> {
    filterChanged: boolean;
    filterApplied: boolean;
    selectedFilterReferenceName?: string;
    lastAppliedFilter?: TFilter<FType>;
    selectedFilter?: TFilter<FType>;
    filterModified: boolean;
    initialFilter?: TFilter<FType>;
    filter: TFilter<FType>;
    redirectFilters: RedirectFilterOption | null;
    visibleFilters: Array<string>;
}
