export abstract class QueryStringArgumentSerializer<T> {
    constructor(
        key: string,
        acceptedValues: string[] | undefined,
        onDeserializeCallback?: (value: T) => void,
        emptySpaceSymbol?: string
    ) {
        this.key = key;
        this.acceptedValues = acceptedValues;
        this.onDeserializeCallback = onDeserializeCallback;
        this.emptySpaceSymbol = emptySpaceSymbol;
    }

    abstract serialize: (value: T | undefined) => string | undefined
    abstract deserialize: (rawValue: string) => void

    key: string
    acceptedValues: string[] | undefined
    onDeserializeCallback?: (value: T) => void
    emptySpaceSymbol: string | undefined

    isAccepted = (value: string): boolean => {
        if (this.acceptedValues) {
            return this.acceptedValues.some(a =>
                a.localeCompare(
                    value || '',
                    undefined,
                    { sensitivity: 'accent' }) === 0);
        }

        return true;
    }
}
