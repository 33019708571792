import { toArray } from 'lodash';
import { FilterBooleanGroup, FilterDateGroup, FilterGroup, FilterSelectGroup, FilterType } from '../../types/filters/FilterGroup';
import { CommonFilter } from '../../types/filters/FilterState';
import { selectedDateOptions } from '../amr-pipeline-filter.utils';
import { filterUtils, getYearsRange } from './filter.utils';

export function compareStrings(value: string | undefined, group: FilterSelectGroup<string>) {
    return group.filter.some(c => c.selected && String(c.value)?.localeCompare(String(value), undefined, { sensitivity: 'accent' }) === 0)
}

export function compareNumbers(value: number, group: FilterSelectGroup<number>) {
    return group.filter.some(c => c.selected && Number(c.value) === Number(value))
}

export function compareBoolean(value: boolean | undefined, group: FilterBooleanGroup) {
    return Boolean(value) === Boolean(group.filter.selectedOption)
}

export function compareBooleanStrict(group: FilterBooleanGroup, value?: boolean | null,) {
    if (value == null) return false
    return compareBoolean(value, group)
}

export function compareByDateRange(date: Date | undefined, filter: FilterDateGroup) {
    const periodDate = selectedDateOptions(filter);
    if (periodDate.from || periodDate.to) {
        return filterUtils.filerByDateRange(date, periodDate.from, periodDate.to);
    }
    return true
}

export function compareByDateYearRange(filter: FilterDateGroup, date?: Date, closingDate?: Date) {
    const periodEndYears = getYearsRange(filter);
    if (periodEndYears.from || periodEndYears.to) {
        return filterUtils.filterByYearIncrement(Number(periodEndYears.from), Number(periodEndYears.to), date, closingDate);
    }
    return compareByDateRange(date, filter)
}

export function getSelectedFilters(filters: CommonFilter) {
    return toArray(filters).reduce<{ [key: string]: boolean }>((result, current: FilterGroup) => {
        if (current.type === FilterType.Select && (current.filter as [])?.some((c: { selected: boolean }) => c.selected))
            result[current.key] = true;
        if (current.type === FilterType.DateWithYearsRange && (current.filter as { selectedOption: boolean })?.selectedOption)
            result[current.key] = true;
        if (current.type === FilterType.BooleanRadio && (current.filter as { selectedOption: boolean })?.selectedOption != null)
            result[current.key] = true;
        if (current.type === FilterType.Date && (current.filter as { selectedOption: boolean })?.selectedOption)
            result[current.key] = true;
        if (current.type === FilterType.YearsDate && (current.filter as { selectedOption: boolean })?.selectedOption)
            result[current.key] = true;

        return result
    }, {});
}
