export enum TradeSettlementStatus {
    Unsettled = 1, //  trade is Rejected/Pending
    PendingAllocation = 2, // awaiting for manual allocation
    PendingProceeds = 3,
    PendingSettlement = 4, // allocated(auto/manually) trades, pending settlement dateTime
    Settled = 5
}

export const tradeSettlementStatusTitle = {
    [TradeSettlementStatus.Unsettled]: 'Unsettled',
    [TradeSettlementStatus.PendingAllocation]: 'Pending Allocation',
    [TradeSettlementStatus.PendingProceeds]: 'Pending Proceeds',
    [TradeSettlementStatus.PendingSettlement]: 'Pending Settlement',
    [TradeSettlementStatus.Settled]: 'Settled',
}
