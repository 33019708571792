import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const dashboardFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.CanUseDashboardFilter,
    SubscriptionFeature.CanUseDashboardAlerts,
    SubscriptionFeature.getBidAsDealerRequests,
    SubscriptionFeature.getVantageData,
    SubscriptionFeature.getDtcSellingBuying,
    SubscriptionFeature.getBwicPercentOfTrace,
    SubscriptionFeature.getMedianTripleA,
    SubscriptionFeature.getDntRates,
    SubscriptionFeature.getSameDayBwics,
    SubscriptionFeature.getColorRates,
    SubscriptionFeature.getMostTradedTickers,
    SubscriptionFeature.getPriceTalkDeviation,
    SubscriptionFeature.getSecurityBwicStatistics,
    SubscriptionFeature.getPriceLevels,
    SubscriptionFeature.getTradingStats,
    SubscriptionFeature.getBiggestMovers,
    SubscriptionFeature.viewIncludedToPortfolioInventory,
    SubscriptionFeature.IssuanceSpreadsGraph,
    SubscriptionFeature.Top10ArrangersTable,
    SubscriptionFeature.EsgAsPercentOfBslNewIssueGraph,
    SubscriptionFeature.Top10TrusteesTable,
    SubscriptionFeature.EuCompliantAsPercentOfBslGraph,
    SubscriptionFeature.StaticDealsTable,
    SubscriptionFeature.ShortAndLongDatedDealsGraph,
    SubscriptionFeature.DebutManagersTable,
    SubscriptionFeature.UsBslNewIssueWasGraph,
    SubscriptionFeature.UsBslNewIssueWarfGraph,
    SubscriptionFeature.UsBslNewIssueDsGraph,
    SubscriptionFeature.CanUseIssueTransactionChartFilter,
    SubscriptionFeature.CanUseTopIssuersChartFilter
];
