// TODO: This module should be a part of chart.utils, which should be rewritten on typescript
import moment, { Moment, unitOfTime } from 'moment';
import { ChartGrouping } from '../types/dashboard/AmrChart';
import { DropDownListItem } from '../components/controls/DropDownList';

export type Range<T> = {
    min: T;
    max: T;
}

export interface GroupedBy<T, K> {
    date: T;
    entries: K[];
}

function getRange(min: Moment, max: Moment, interval: unitOfTime.Base) {
    let current = min.clone();

    const result = [];

    while (current.isBefore(max)) {
        result.push(current.clone())
        current.add(1, interval);
    }

    return result;
}

const gapsFiller = <T,>(data: T[], dateGetter: (row: T) => Moment, blankItem: (date: Moment) => T) =>
    ({ min, max }: Range<Moment>, interval: unitOfTime.Base) => {
        if (!min || !max) {
            return [];
        }

        const datesRange = getRange(min.clone().startOf(interval), max.clone().endOf(interval), interval);

        return datesRange.map(date => {
            const dataEntry = data.find(x => dateGetter(x).isSame(date, interval));

            return dataEntry || blankItem(date);
        });
    };

const groupingToUnitOfTime = (grouping: ChartGrouping) => {
    switch (grouping) {
        case ChartGrouping.M:
        case ChartGrouping.Q:
            return grouping as unitOfTime.Base;
        default:
            // W and Y
            return grouping.toLocaleLowerCase() as unitOfTime.Base;
    }
};

const renderSelectedFilterOptionWithPrefix = (prefix: string) => (item: DropDownListItem) => `${prefix}: ${item.text}`;

const getEdgeDimensionValues = <T>(data: T[], dimensionGetter: (row: T) => Moment | undefined): Range<Moment> => {
    const dates = data.reduce((acc: Moment[], next: T) => {
        const date = dimensionGetter(next);

        return date ? [...acc, date] : acc;
    }, []);

    return {
        min: moment.min(dates),
        max: moment.max(dates),
    };
};

export const amrChartUtils = {
    gapsFiller,
    groupingToUnitOfTime,
    renderSelectedFilterOptionWithPrefix,
    getEdgeDimensionValues,
};
