export enum DealsTabType {
    Overview = 'overview',
    DealDetail = 'dealDetail',
    ClassDetail = 'classDetail',
    AllClasses = 'allClasses',
    Documents = 'documents',
    AmrInfo = 'amrInfo',
}

export const DealsTabTypes = [
    { value: DealsTabType.Overview, text: 'Overview' },
    { value: DealsTabType.DealDetail, text: 'Deal Detail' },
    { value: DealsTabType.ClassDetail, text: 'Class Detail' },
    { value: DealsTabType.AllClasses, text: 'All Classes' },
    { value: DealsTabType.Documents, text: 'Documents' },
    { value: DealsTabType.AmrInfo, text: 'AMR Info' },
];
