export interface IDeserializeCommand {
    canExecute?: () => boolean;
    shouldExecute?: () => boolean;
    execute: () => void;
}

export class DeserializeCommandHandler {
    _commands: IDeserializeCommand[] = [];

    addCommand(command: IDeserializeCommand) {
        this._commands.push(command);
        return this;
    }

    process() {
        // All executable or nothing
        if (this._commands.some(c => (c.shouldExecute?.() ?? true) && !(c.canExecute?.() ?? true))) {
            return false;
        }

        for (let c of this._commands) {
            if (c.shouldExecute?.() ?? true) {
                c.execute();
            }
        }

        return true;
    }

    * processGenerator() {
        // All executable or nothing
        if (this._commands.some(c => (c.shouldExecute?.() ?? true) && !(c.canExecute?.() ?? true))) {
            return false;
        }

        for (let c of this._commands) {
            if (c.shouldExecute?.() ?? true) {
                yield c.execute();
            }
        }

        return true;
    }
}