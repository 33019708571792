
export interface CloSystemInfrastructures {
    id: number;
    name: string;
    type?:CloSystemInfrastructuresTypes
}

export enum CloSystemInfrastructuresTypes {
    CloValuationServices = 'CloValuationServices',
    CloAnalyticsServices = 'CloAnalyticsServices',
    CloPortfolioManagementServices = 'CloPortfolioManagementServices',
    OffshoreLegalServices = 'OffshoreLegalServices',
    OffshoreFundAdministrationServices = 'OffshoreFundAdministrationServices',
    CloAccountingServices = 'CloAccountingServices',
    SecondaryCLOPlatforms = 'SecondaryCLOPlatforms',
    CloDocsServices = 'CloDocsServices',
    USCLOLawFirms = 'USCLOLawFirms',
    EuropeanCLOLawFirms = 'EuropeanCLOLawFirms',
}
