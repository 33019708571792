export enum InventorySecurityOrderByColumn {
    lastUpdateDate = 1,
    isinCusip = 2,
    ticker = 3,
    currency = 4,
    rating = 5,
    offer = 6,
    size = 7,
    offerDmBps = 8,
    offerYield = 9,
    walYears = 10,
    status = 11,
    dealerId = 12,
    mvoc = 13,
    nonCallPeriodEndYears = 14,
    reinvestmentPeriodEndYears = 15,
    creditEnhancement = 16,
    collateralType = 17,
    dealLegalName = 18,
    collateralManager = 19,
    statedMaturity = 20,
    vintage = 21,
    trustee = 22,
    amr = 23,
    staticDeal = 24,
    euCompliance = 25,
    esg = 26,
    closingDate = 27,
    outOfNC = 28,
    outOfRI = 29,
    bidSize = 30,
    bid = 31,
    bidDmBps = 32
}

export enum InventorySecuritySortOrder {
    asc = 1,
    desc = 2
}

