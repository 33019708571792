import { upperFirst } from "lodash";
import { ApiError } from "../types/ApiError";
import { jsonUtils } from "./json.utils";

export const apiUtils = {
    isRequestSuccess,
    normalize,
    parseErrorMessage,
    parseSortingParams,
};

function isRequestSuccess(status: number) {
    return status >= 200 && status <= 299;
}

function normalize<TEntity, TKey extends string | number, TResult>(
    entities: TEntity[],
    getKey: (entity: TEntity, index: number) => TKey,
    getValue: (entity: TEntity, index: number) => TResult
): { [key in TKey]: TResult } {
    return Object.assign({}, ...entities.map((e, index) => ({ [getKey(e, index)]: getValue(e, index) })));
}

function parseErrorMessage(jsonText: string): ApiError | undefined {
    const error: ApiError = jsonText ? jsonUtils.tryParse(jsonText) : null;
    return error?.error ? error : undefined;
}

function parseSortingParams(
    sortingParams: { field: string; ascending: boolean }[]
) {
    return sortingParams.reduce(
        (accumulator, p, index) => ({
            ...accumulator,
            [`orderBy[${index}].Field`]: upperFirst(p.field),
            [`orderBy[${index}].Ascending`]: p.ascending,
        }),
        {}
    );
}
