import { Bid } from "../types/bidding/Bid";
import { OpenBiddingStatus } from "../types/enums/OpenBiddingStatus";
import { BwicProcessType, Process } from "../types/models/Process";
import { EditBidOnBehalfState } from "../types/state/BidOnBehalfState";

export const bidOnBehalfUtils = {
    isBidChanged,
    isBidDecreaseDisabled
}

function isBidChanged(edit: EditBidOnBehalfState, original?: Bid) {
    const isAdded = original == null && edit != null && (!!edit.value || !!edit.pass);
    if (isAdded) {
        return true;
    }

    const isChanged = original != null && (
        (edit.value && +edit.value !== original.value) ||
        (edit.pass != null && edit.pass !== original.pass) ||
        (edit.axed != null && edit.axed !== original.axed) ||
        (edit.final != null && edit.final !== original.final)
    );

    return isChanged;
}

function isBidDecreaseDisabled(process: Process) {
    return (process.type === BwicProcessType.JumpBall || process.type === BwicProcessType.TopX) &&
        process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended
}
