enum IoIFloaterIndex {
    Fixed = 'Fixed',
    SOFR = 'SOFR',
    Libor1m = 'LIBOR 1M',
    Libor3m = 'LIBOR 3M',
    Euribor3m = 'EURIBOR 3M',
    Euribor6m = 'EURIBOR 6M',
}

export const ioiFloaterIndexes = [
    IoIFloaterIndex.Fixed,
    IoIFloaterIndex.SOFR,
    IoIFloaterIndex.Libor1m,
    IoIFloaterIndex.Libor3m,
    IoIFloaterIndex.Euribor3m,
    IoIFloaterIndex.Euribor6m,
];
