import { Trade } from "./Trade";

export enum TradeStatus {
    pending = 1,
    affirmed = 2,
    rejected = 3,
    canceled = 4,
}

export function isActiveTrade(trade?: Trade) {
    return trade != null && (trade.status === TradeStatus.pending || trade.status === TradeStatus.affirmed);
}
