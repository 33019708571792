import { Moment } from "moment";
import { constants } from "../constants";
import { OriginatingTransactionClassStatus } from "../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { OriginatingTransactionClass } from '../types/amr-pipeline/models/OriginatingTransactionClass';
import { InventoryFloatingIndex } from "../types/inventory/InventoryFloatingIndex";
import { dateTimeUtils, formatUtils, moneyUtils, numericUtils } from "./";
import { constants as amrConstants } from "../components/amrPipeline/common/constants";
import { Tranche } from '../types/amr-pipeline/models/Tranche';

const TransactionClassRatings: (keyof OriginatingTransactionClass)[] = [
    'expectedRatingMoodys',
    'expectedRatingSnP',
    'expectedRatingFitch',
    'expectedRatingKbra',
    'expectedRatingDbrs',
];

const TrancheOriginalRatings: (keyof Tranche)[] = [
    'originalRatingMoodys',
    'originalRatingSnP',
    'originalRatingFitch',
    'originalRatingKbra',
    'originalRatingDbrs',
];

const TrancheCurrentRatings: (keyof Tranche)[] = [
    'ratingMoodys',
    'ratingSnP',
    'ratingFitch',
    'ratingKbra',
    'ratingDbrs',
];

function convertAmrToBwicInventoryFloaterIndex(amrFloaterIndex?: string): InventoryFloatingIndex | undefined {
    switch(amrFloaterIndex) {
        case "SOFR":
            return InventoryFloatingIndex.Sofr;
        case "LIBOR 1M":
            return InventoryFloatingIndex.Libor1m;
        case "LIBOR 3M":
            return InventoryFloatingIndex.Libor3m;
        case "EURIBOR 3M":
            return InventoryFloatingIndex.Euribor3m;
        case "EURIBOR 6M":
            return InventoryFloatingIndex.Euribor6m;
        default:
            return undefined;
    }
}

function formatClassStatus(status?: OriginatingTransactionClassStatus) {
    switch (status) {
        case OriginatingTransactionClassStatus.NotOffered:
            return amrConstants.notOffered;
        case OriginatingTransactionClassStatus.Subject:
            return amrConstants.subject
        default: return;
    }
}

function formatInventoryCoupon(floaterIndex?: string, margin?: number, emptyValue = constants.emptyPlaceholder) {
    const inventoryPosition = {
        floaterIndex: convertAmrToBwicInventoryFloaterIndex(floaterIndex),
        spread: margin,
    };

    return formatUtils.formatInventoryCoupon(inventoryPosition, emptyValue);
}

function formatClassesTotalBalance(balance: number){
    return moneyUtils.money(Number(numericUtils.round(Math.abs(Math.trunc(balance) / 1.0e9), 3).toFixed(3))) + 'B';
};

function formatRollerDeadlineDate(rollerDeadlineDate: Moment) {
    return dateTimeUtils.isToday(rollerDeadlineDate)
        ? `Today ${rollerDeadlineDate.tz(constants.estTimezone).format(constants.timeFormat)}`
        : rollerDeadlineDate.tz(constants.estTimezone).format(constants.rollerDeadlineFormat);
}

function formatRatingFor<T extends Object>(transactionClass: T, fields: (keyof T)[]) {
    const [ratingMoodys, ratingSnP, ratingFitch, ratingKbra, ratingDbrs] = fields;

    return `${transactionClass[ratingMoodys] || constants.emptyPlaceholder}/${
        transactionClass[ratingSnP] || constants.emptyPlaceholder
    }/${transactionClass[ratingFitch] || constants.emptyPlaceholder}/${
        transactionClass[ratingKbra] || constants.emptyPlaceholder
    }/${transactionClass[ratingDbrs] || constants.emptyPlaceholder}`;
}

function formatRating(transactionClass: OriginatingTransactionClass) {
    return formatRatingFor(transactionClass, TransactionClassRatings);
}

function formatOriginalRating(tranche: Tranche) {
    return formatRatingFor(tranche, TrancheOriginalRatings);
}

function formatCurrentRating(tranche: Tranche) {
    return formatRatingFor(tranche, TrancheCurrentRatings);
}

export const amrFormatUtils = {
    convertAmrToBwicInventoryFloaterIndex,
    formatInventoryCoupon,
    formatRollerDeadlineDate,
    formatRating,
    formatOriginalRating,
    formatCurrentRating,
    formatClassStatus,
    formatClassesTotalBalance
};
