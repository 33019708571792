import cloImage from '../../../images/clo.png';
import londonStockExchangeGroupImage from '../../../images/london-stock-exchange-group.svg';

export enum NewsProvider {
    None = "None",
    CLOResearchGroup = "CLOResearchGroup",
    LondonStockExchangeGroup = "LondonStockExchangeGroup",
}

export const newsProviderTitles = {
    [NewsProvider.None]: "—",
    [NewsProvider.CLOResearchGroup]: "CLO Research Group",
    [NewsProvider.LondonStockExchangeGroup]: "London Stock Exchange Group",
};

export const newsProviderLogos = {
    [NewsProvider.CLOResearchGroup]: cloImage,
    [NewsProvider.LondonStockExchangeGroup]: londonStockExchangeGroupImage,
};
