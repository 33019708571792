export enum BankOptionalFields {
    Cmta = 'cmta',
    Euroclear = 'euroclear',
    LtId = 'ltId',
    InterestedPartyId = 'interestedPartyId',
    InterestedPartyCustodian = 'interestedPartyCustodian',
    GovAbaNumber = 'govAbaNumber',
    GovBankAccountNumber = 'govBankAccountNumber',
    MortgageAbaNumber = 'mortgageAbaNumber',
    MortgageBankAccountNumber = 'mortgageBankAccountNumber',
}

export const bankOptionalFieldsLabel = {
    [BankOptionalFields.Cmta]: {
        option: 'CMTA', label: 'CMTA'
    },
    [BankOptionalFields.Euroclear]: {
        option: 'Euroclear', label: 'Euroclear'
    },
    [BankOptionalFields.LtId]: {
        option: 'LTID', label: 'LTID'
    },
    [BankOptionalFields.InterestedPartyId]: {
        option: 'Interested Party ID', label: 'Intst Party ID'
    },
    [BankOptionalFields.InterestedPartyCustodian]: {
        option: 'Interested Party Custodian', label: 'Intst Party Custodian'
    },
    [BankOptionalFields.GovAbaNumber]: {
        option: 'Gov ABA Number', label: 'Gov ABA #'
    },
    [BankOptionalFields.GovBankAccountNumber]: {
        option: 'Gov Bank Account Number', label: 'Gov Bank Acc #'
    },
    [BankOptionalFields.MortgageAbaNumber]: {
        option: 'Mortgage ABA Number', label: 'Mortgage ABA #'
    },
    [BankOptionalFields.MortgageBankAccountNumber]: {
        option: 'Mortgage Bank Account Number', label: 'Mortgage Bank Acc #',
    },
}

export const bankOptionalFieldMaxLength = {
    [BankOptionalFields.Cmta]: 4,
    [BankOptionalFields.Euroclear]: 6,
    [BankOptionalFields.LtId]: 40,
    [BankOptionalFields.InterestedPartyId]: 5,
    [BankOptionalFields.InterestedPartyCustodian]: 12,
    [BankOptionalFields.GovAbaNumber]: 9,
    [BankOptionalFields.GovBankAccountNumber]: 17,
    [BankOptionalFields.MortgageAbaNumber]: 9,
    [BankOptionalFields.MortgageBankAccountNumber]: 17,
}
