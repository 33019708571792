export enum CloManagersTabType {
    Overview = 'overview',
    Deals = 'deals',
    CloTeam = 'cloTeam',
    AdditionalInformation = 'additionalInformation',
    Analytics = 'analytics',
}

export const CloManagerTabTypes = [
    { value: CloManagersTabType.Overview, text: 'Overview' },
    { value: CloManagersTabType.Deals, text: 'Deals' },
    { value: CloManagersTabType.CloTeam, text: 'CLO Team' },
    { value: CloManagersTabType.AdditionalInformation, text: 'Additional Information' },
    { value: CloManagersTabType.Analytics, text: 'Analytics' },
];
