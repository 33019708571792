import { NewsTabCategory } from "../../news/enums/NewsTabCategory";

export enum NewsAccessType {
    news = 'News',
    conference = 'Conference',
    webinars = 'Webinars',
    newFeatures = 'NewFeatures',
    improvements = 'Improvements',
    allGraphs = 'AllGraphs',
    newsAll = 'NewsAll',
    newsPrimary = 'NewsPrimary',
    newsSecondary = 'NewsSecondary',
    newsResearch = 'NewsResearch',
    readMore = 'ReadMore',
    externalSource = 'ExternalSource',
    dealDetails = 'DealDetails',
    managerProfile = 'ManagerProfile',
    arranger = 'Arranger',
    newsDocument = 'NewsDocument',
    newsAllDocuments = 'NewsAllDocuments',
}

export const newsAccessTypeByTab = {
    [NewsTabCategory.All]: NewsAccessType.newsAll,
    [NewsTabCategory.Primary]: NewsAccessType.newsPrimary,
    [NewsTabCategory.Secondary]: NewsAccessType.newsSecondary,
    [NewsTabCategory.Research]: NewsAccessType.newsResearch,
}
