import * as yup from 'yup';
import { errorMessages } from '../constants';

yup.setLocale({
    mixed: {
        default: errorMessages.invalidValue,
        required: errorMessages.empty,
        oneOf: errorMessages.invalidValue,
    },
    string: {
        matches: errorMessages.invalidValue,
        min: errorMessages.tooSmall,
        max: errorMessages.tooLong,
        email: errorMessages.invalidEmail,
        url: errorMessages.invalidValue,
    },
    number: {
        min: errorMessages.yupNumberMin,
        max: errorMessages.yupNumberMax
    }
});

const yupSchemaValidate = (validationSchema, objectToValidate, initialErrors = {}, currentErrors = {}, propertyName = '') => {
    if (propertyName) {
        return validationSchema
            .validateAt(propertyName, objectToValidate, { abortEarly: false })
            .then(() => ({ ...currentErrors, [propertyName]: '' }))
            .catch(e => ({ ...currentErrors, [propertyName]: e.errors[0] }));
    } else {
        return validationSchema
            .validate(objectToValidate, { abortEarly: false })
            .then(() => ({ ...initialErrors }))
            .catch(e => convertYupErrorToObject(e, initialErrors));
    }
}

const convertYupErrorToObject = (error, initialErrors) => {
    const result = { ...initialErrors };
    error.inner.forEach(i => result[i.path] = i.message)
    return result;
}

export { yup, yupSchemaValidate };
