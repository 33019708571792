export enum InventoryFloatingIndex {
    Sofr = 1,
    Libor1m = 2,
    Libor3m = 3,
    Euribor3m = 4,
    Euribor6m = 5
}

export const inventoryFloatingIndexTitles: { [key: number]: string } = {
    [InventoryFloatingIndex.Sofr]: 'SOFR',
    [InventoryFloatingIndex.Libor1m]: 'LIBOR 1M',
    [InventoryFloatingIndex.Libor3m]: 'LIBOR 3M',
    [InventoryFloatingIndex.Euribor3m]: 'EURIBOR 3M',
    [InventoryFloatingIndex.Euribor6m]: 'EURIBOR 6M',
};
