import moment from "moment";
import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";

const unspecifiedArgumentValue = 'unspecified';

export class DateArgumentSerializer
    extends QueryStringArgumentSerializer<Date | null> {
    constructor(
        key: string,
        onDeserializeCallback?: (value: Date | null) => void) {
        super(key, undefined, onDeserializeCallback);
    }

    dateFormat = 'YYYY-M-D';

    serialize = (value: Date | undefined | null) => {
        if (value === null) {
            return unspecifiedArgumentValue
        }
        return value ? moment(value).format(this.dateFormat) : undefined
    };

    deserialize = (rawValue: string): void => {
        const value = rawValue ? decodeURIComponent(rawValue.trim()) : undefined;

        if (this.onDeserializeCallback) {
            if (value === unspecifiedArgumentValue) {
                this.onDeserializeCallback(null);
            } else {
                const date = value && moment(value, this.dateFormat, true);
                if (date && date.isValid()) {
                    this.onDeserializeCallback(date.startOf('day').toDate());
                }
            }
        }
    }
}
