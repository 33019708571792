export enum SecurityIdentifierType {
    // Rule 144a
    Ticker144a = 1,
    ISIN144a = 2,
    CUSIP144a = 3,
    // Reg S
    TickerRegS = 4,
    ISINRegS = 5,
    CUSIPRegS = 6,
    // Acc’d Investor / Certif.
    TickerAccd = 7,
    ISINAccd = 8,
    CUSIPAccd = 9,
}