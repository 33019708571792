export enum PushHistoryStateItemType {
    NewBid = 'NewBid',
    Feedback = 'Feedback',
    BwicStatusChanged = 'BwicStatusChanged',
    BwicStage1Ended = 'BwicStage1Ended',
    BwicStage2Ended = 'BwicStage2Ended',
    BiddingCompleteReminder='BiddingCompleteReminder'
}

export interface PushHistoryStateItem {
    bwicReferenceName: string;
    positionId?: number;
    receivedDate: Date;
    payload?: any;
}

export interface PushHistoryState {
    history: {
        [key in PushHistoryStateItemType]?: PushHistoryStateItem[];
    }
}
