import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";

export class SingleValueArgumentSerializer
    extends QueryStringArgumentSerializer<string> {
    serialize = (value: string | undefined) => value && encodeURIComponent(value);
    deserialize = (rawValue: string): void => {
        const value = rawValue ? decodeURIComponent(rawValue.trim()) : undefined;
        if (value && this.isAccepted(value) && this.onDeserializeCallback) {
            this.onDeserializeCallback(value);
        }
    }
}
