export enum BanksTabType {
    ContactsAndEvents = 'contacts-and-events',
    Primary = 'primary',
    Secondary = 'secondary',
    // TODO: Second Iteration
    // SettlementAccounts = 'settlement-accounts',
    Analytics = 'analytics',
}

export const BanksTabTypes = [
    { value: BanksTabType.ContactsAndEvents, text: 'Contacts & Events' },
    { value: BanksTabType.Primary, text: 'Primary' },
    { value: BanksTabType.Secondary, text: 'Secondary' },
    // TODO: Second Iteration
    // { value: BanksTabType.SettlementAccounts, text: 'Settlement Accounts' },
    { value: BanksTabType.Analytics, text: 'Analytics' },
];
