import { round as _round, floor as _floor } from 'lodash';

export const numericUtils = {
    isNumber,
    numberOrDefault,
    round,
    floor,
    getPercentage,
    divideSafe,
    toOrdinal
};

function isNumber(value: any): value is number {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function numberOrDefault(value: any, defaultValue = 0) {
    return isNumber(value) ? Number(value) : defaultValue;
}

function round(value: number, precision = 2) {
    return _round(value, precision);
}

function floor(value: number, precision = 2) {
    return _floor(value, precision);
}

function getPercentage(number: number, percent: number) {
    if (!number || !percent) {
        return 0;
    }
    return number * percent / 100;
}

function divideSafe(a?: number | null, b?: number | null) {
    if (a == null || b == null || !isNumber(a) || !isNumber(b) || +b === 0) {
        return NaN;
    }
    return a / b;
}

function toOrdinal(n: number) {
    const  s = ["th", "st", "nd", "rd"];
    const v = n % 100;

    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
