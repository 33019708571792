import moment from "moment";
import { constants } from "../constants";
import { FilterRangeGroup, FilterDateGroup, FilterRadioGroup, FilterBooleanGroup, FilterSelectGroup } from "../types/filters/FilterGroup";
import { numericUtils } from "./numeric.utils";

export function selectedSelectGroupOptions(filter: FilterSelectGroup, fallback?: any) {
    const selectedOptions = filter?.filter.filter(m => m.selected).map(m => m.value);

    if (!selectedOptions.length) {
        return fallback;
    }

    return selectedOptions;
}

export function selectedRangeOptions(filter: FilterRangeGroup) {
    const { from, to } = filter?.filter || {};
    return {
        from: numericUtils.isNumber(from) ? Number(from) : undefined,
        to: numericUtils.isNumber(to) ? Number(to) : undefined,
    };
}

export function selectedDateOptions(filter?: FilterDateGroup) {
    const { from, to } = filter?.filter.options.customDateRange || {};
    return {
        from: from ? moment(from).startOf('day').format(constants.dateFormatISO8601) : undefined,
        to: to ? moment(to).endOf('day').format(constants.dateFormatISO8601) : undefined,
    };
}

export function selectedRadioOption<T>(filter: FilterRadioGroup<T>) {
    const selectedOption = filter?.filter.selectedOption;
    return selectedOption !== null ? [selectedOption] : undefined;
}

export function selectedBooleanOption(filter?: FilterBooleanGroup) {
    const selectedOption = filter?.filter.selectedOption;
    return selectedOption !== null ? selectedOption : undefined;
}

export function validateYear(year: string | number, minYear = 2010, maxYear = moment().year()) {
    if (parseInt(year.toString(), 10) < minYear || parseInt(year.toString(), 10) > maxYear) {
        return `No available data related to this year.`;
    }

    return '';
};
