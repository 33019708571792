export enum OriginatingTransactionClassStatus {
    'Subject' = 'Subject',
    'Open' = 'Open',
    'NotOffered' = 'NotOffered',
    'CallDesk' = 'CallDesk',
    'Retained' = 'Retained',
    'IncludesAdditionalSub' = 'IncludesAdditionalSub',
}

export const transactionClassStatusTitles = {
    [OriginatingTransactionClassStatus.Subject]: OriginatingTransactionClassStatus.Subject,
    [OriginatingTransactionClassStatus.Open]: OriginatingTransactionClassStatus.Open,
    [OriginatingTransactionClassStatus.NotOffered]: 'Not Offered',
    [OriginatingTransactionClassStatus.CallDesk]: 'Call Desk',
    [OriginatingTransactionClassStatus.Retained]: OriginatingTransactionClassStatus.Retained,
    [OriginatingTransactionClassStatus.IncludesAdditionalSub]: "Includes add'l sub",
};