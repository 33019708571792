import { Matcher } from 'react-day-picker';
import { FilterRangeOption } from './FilterRangeOption';
import { DateRange } from './DateRange';
import { FilterRadioOption } from './FilterRadioOption';
import { FilterBooleanOption } from './FilterBooleanOption';
import { FilterDateOption } from './FilterDateOption';
import { FilterOption } from './FilterOption';
import { DateFilterOption } from './DateFilterOption';
import { DateTimeFilterOption } from './DateTimeFilterOption';
import { FilterDateTimeOption } from './FilterDateTimeOption';
import { SubscriptionFeature } from '../billing/SubscriptionFeature';

export enum FilterType {
    Select = 'select',
    Range = 'range',
    Date = 'date',
    DateWithYearsRange = 'dateWithYearsRange',
    DateWithYearsAndTimeRange = 'dateWithYearsAndTimeRange',
    YearsDate = 'yearsDate',
    Radio = 'radio',
    BooleanRadio = 'booleanRadio',
}

export interface DateFilterOptions {
    customDateRange: DateRange;
    customYearsRange?: FilterRangeOption;
}

export interface FilterGroup {
    filter: unknown;
    key: string;
    visible: boolean;
    text: string;
    disabled: boolean;
    selected: boolean;
    withSearch?: boolean;
    isApplied?: boolean;
    type: FilterType;
    requestState?: boolean;
    requiredFeature?: SubscriptionFeature;
}

export interface FilterSelectGroup<T = string | number> extends FilterGroup {
    filter: FilterOption<T>[],
}

export interface FilterRangeGroup extends FilterGroup {
    filter: FilterRangeOption;
    acceptableOptions?: FilterRangeOption[];
    decimals?: number;
}

export interface FilterDateGroup extends FilterGroup {
    filter: FilterDateOption;
    acceptableOptions?: DateFilterOption[];
    disabledDays?: Matcher;
}

export interface FilterDateTimeGroup extends FilterGroup {
    filter: FilterDateTimeOption;
    acceptableOptions?: DateTimeFilterOption[];
}

export interface FilterRadioGroup<T> extends FilterGroup {
    filter: FilterRadioOption<T>;
}

export interface FilterBooleanGroup extends FilterGroup {
    filter: FilterBooleanOption;
}
