import { TimeSpan } from "../primitives/TimeSpan"
import { Nullable } from "../primitives/Nullable";
import { BwicProcessType } from "./Process";

export interface ParsedBwicProcess {
    reserveLevelsApply: Nullable<boolean>
    canTradeEarly: Nullable<boolean>
    canBidEarly: Nullable<boolean>
    partialBidsAccepted: Nullable<boolean>
    bidsGoodFor: TimeSpan
    processType?: BwicProcessType // reuse BwicProcessType enum (NEW Option will be added to then enum for "Other" case: Unknown = 6)
    bff?: ParsedBwicBestFootForwardSettings
    jumpBall?: ParsedBwicTwoStageSettings
    topX?: ParsedBwicTwoStageSettings
    unknown?: ParsedBwicTwoStageSettings
}

export interface ParsedBwicTwoStageSettings {
    hardCutOff: HardCutOff,
    stage1EndDateUtc?: Date;
    stage2EndDateUtc?: Date;
    improverCount?: Stage2Participants;
}

interface ParsedBwicBestFootForwardSettings {
    stage1EndDateUtc?: Date;
}

export enum Stage2Participants {
    Top2 = 2,
    Top3 = 3,
    Top4 = 4,
    Top5 = 5
}

export type HardCutOff = 1 | 2