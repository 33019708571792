import { TransactionType } from '../amr-pipeline/enums/TransactionType';

export enum ChartUnits {
    Volume = 'Volume',
    Count = 'Count',
    PercentByVolume = 'PercentByVolume',
    PercentByCount = 'PercentByCount',
}

export enum ChartGrouping {
    W = 'W',
    M = 'M',
    Q = 'Q',
    Y = 'Y',
}

export enum ChartView {
    Stacked = 'stack',
    Grouped = 'group',
}

export enum Term {
    Short = 'Short',
    Long = 'Long',
}

export const ChartTypes = [
    { value: TransactionType.newIssue, text: 'New Issue' },
    { value: TransactionType.refi, text: 'Refinancing' },
    { value: TransactionType.reset, text: 'Reset' },
];

export const ChartUnitsList = [
    { value: ChartUnits.Volume, text: 'Volume' },
    { value: ChartUnits.Count, text: 'Count' },
    { value: ChartUnits.PercentByVolume, text: '% by Volume' },
    { value: ChartUnits.PercentByCount, text: '% by Count' },
];
