export enum AmrStatus {
    Draft = 'Draft',
    Submitted = 'Submitted',
    Scheduled = 'Scheduled',
    Canceled = 'Canceled',
    Finished = 'Finished',
}

export const amrStatusesSelectOptions = Object.values(AmrStatus).map(status => ({
    key: status,
    title: status,
}));
