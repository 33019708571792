import { PipelineType } from '../../../../types/amr-pipeline/enums/PipelineType';
import { TFilter, TFilterType } from '../../../../types/filters/FilterState';
import { PipelineFilters } from '../../../../types/state/PipelineFilterState';
import { UserConfigFilter, UserFilterValues } from '../../../../types/user-config/UserConfigFilter';
import { deserializeBwicFilters } from '../bwic/deserializeFilters';
import { deserializeBooleanFilter, deserializeDateFilter, deserializeDateTimeFilter, deserializeRadioFilter, deserializeRangeFilter, deserializeSelectFilter, } from './filterDeserializers';


export const deserializeFilters = (userConfigFilter: UserConfigFilter, stateFilter: TFilter<TFilterType>, filterType: TFilterType) => {
    const { filter } = userConfigFilter;
    switch (filterType) {
        case PipelineType.ArrangerPipeline:
            return deserializeArrangerPipelineFilters(filter, stateFilter as PipelineFilters<PipelineType.ArrangerPipeline>);
        case PipelineType.Deals:
            return deserializeDealsFilters(filter, stateFilter as PipelineFilters<PipelineType.Deals>);
        default:
            return deserializeBwicFilters(filter, stateFilter);
    }
}

const deserializeDealsFilters = (filter: UserFilterValues, stateFilter: PipelineFilters<PipelineType.Deals>) => {
    return {
        ratings: deserializeSelectFilter(stateFilter.ratings.filter, filter.ratings),
        statuses: deserializeSelectFilter(stateFilter.statuses.filter, filter.statuses),
        transactionTypes: deserializeSelectFilter(stateFilter.transactionTypes.filter, filter.types),
        managers: deserializeSelectFilter(stateFilter.managers.filter, filter.collateralManagers),
        arrangers: deserializeSelectFilter(stateFilter.arrangers.filter, filter.arrangers),
        pricingDate: deserializeDateFilter(filter.pricingDate),
        closingDate: deserializeDateFilter(filter.closingDate),
        nonCallEnd: deserializeDateFilter(filter.nonCallPeriodEnd, filter.nonCallPeriodEndDays),
        reinvestmentEnd: deserializeDateFilter(filter.reinvestmentPeriodEnd, filter.reinvestmentPeriodEndDays),
        vintage: deserializeDateFilter(filter.vintage),
        rollerDeadline: deserializeDateTimeFilter(filter.rollerDeadline),
        was: deserializeRangeFilter(filter.was),
        warf: deserializeRangeFilter(filter.warf),
        ds: deserializeRangeFilter(filter.ds),
        collateralType: deserializeSelectFilter(stateFilter.collateralType.filter, filter.collateralTypes),
        amrDeal: deserializeBooleanFilter(filter.amrDeal),
        currency: deserializeSelectFilter(stateFilter.currency.filter, filter.currencyCodes),
        euCompliance: deserializeBooleanFilter(filter.euCompliance),
        classNames: deserializeSelectFilter(stateFilter.classNames.filter, filter.classNames),
        trustees: deserializeSelectFilter(stateFilter.trustees.filter, filter.trustees),
        parSubordination: deserializeRangeFilter(filter.parSubordination),
        mvoc: deserializeRangeFilter(filter.mvoc),
        esg: deserializeBooleanFilter(filter.esg),
        sofr: deserializeBooleanFilter(filter.sofr),
        isDebut: deserializeBooleanFilter(filter.isDebut),
        // enhancedCLO: deserializeBooleanFilter(filter.enhancedCLO), Temporary Enhanced CLO hiding
        staticDeal: deserializeBooleanFilter(filter.staticDeal),
        coupons: deserializeRadioFilter(stateFilter.coupons.filter, filter.coupons),
        outOfNC: deserializeBooleanFilter(filter.outOfNC),
        outOfRI: deserializeBooleanFilter(filter.outOfRI),
    };
}

const deserializeArrangerPipelineFilters = (filter: UserFilterValues, stateFilter: PipelineFilters<PipelineType.ArrangerPipeline>) => {
    return {
        managers: deserializeSelectFilter(stateFilter.managers.filter, filter.collateralManagers),
        arrangers: deserializeSelectFilter(stateFilter.arrangers.filter, filter.arrangers),
        collateralType: deserializeSelectFilter(stateFilter.collateralType.filter, filter.collateralTypes),
        euCompliance: deserializeBooleanFilter(filter.euCompliance),
        ncEnd: deserializeRangeFilter(filter.ncEnd),
        riEnd: deserializeRangeFilter(filter.riEnd),
        equity: deserializeRadioFilter(stateFilter.equity.filter, filter.equity),
        anchor: deserializeBooleanFilter(filter.anchor),
    };
};
