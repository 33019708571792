import { BwicStatus } from '../types/enums/BwicStatus';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { SettlementAgreementStatus } from '../types/bid-as-dealer/SettlementAgreementStatus';
import { apiUtils } from './api.utils';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { compareBoolean } from './compare.utils';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { Trade } from '../types/trades/Trade';

export function canBid(
    bwicStatus: BwicStatus,
    isColorDistribution: boolean,
    isTradedAway: boolean,
    agreement?: SettlementAgentAgreement,
    trade?: Trade,
) {
    return (
        (!!agreement && agreement.agreementStatus === SettlementAgreementStatus.confirmed) &&
        (bwicStatus === BwicStatus.scheduled || bwicStatus === BwicStatus.bidding) &&
        !isColorDistribution &&
        !isTradedAway &&
        trade == null
    );
}

export const getClearingBankConfirmedAgreementsByDealer = (agreements: SettlementAgentAgreement[]) => {
    const clearingBankConfirmedAgreements = agreements.filter(a =>
        a.settlementAgent.isClearingBank &&
        a.agreementStatus === SettlementAgreementStatus.confirmed
    );
    return apiUtils.normalize(clearingBankConfirmedAgreements, a => a.settlementAgent.id, a => a.agreementStatus);
};

export const sortDealersByConfirmedAgreement = (dealers: BrokerDealerCompanySlim[], agreements: SettlementAgentAgreement[]) => {
    const agreementsByDealer = getClearingBankConfirmedAgreementsByDealer(agreements);
    return [...dealers].sort((a, b) => {
        return (
            compareBoolean(agreementsByDealer[a.id] && a.status === CompanyStatus.active, agreementsByDealer[b.id] && b.status === CompanyStatus.active) ||
            a.name.localeCompare(b.name)
        );
    })
};
