import { TimeSpan } from '../primitives/TimeSpan';

export interface JumpBallSettings {
    automaticStage1Deadline?: TimeSpan;
    improverCount: JumpBallStage2Participants;
    improvementRound?: TimeSpan;
    stage1EndDateUtc?: Date;
}

export enum JumpBallStage2Participants {
    Top2 = 2,
    Top3 = 3,
    Top4 = 4,
    Top5 = 5
}
