import { arrayUtils } from '../array.utils';
import { QueryStringArgumentSerializer } from './serializers/QueryStringArgumentSerializer';

export const queryStringSerializer = {
    serialize,
    deserialize
}

function serialize(
    filter: any,
    serializers: QueryStringArgumentSerializer<unknown>[]): string {

    function serializeArgument(serializer: QueryStringArgumentSerializer<unknown>) {
        const { key, serialize } = serializer;
        const serializedValue = serialize(filter[key]);

        return serializedValue != null && `${key}=${serializedValue}`;
    }

    return serializers
        .map(serializer => serializeArgument(serializer))
        .filter(argument => argument)
        .join('&');
}

function deserialize(
    queryString: string,
    serializers: QueryStringArgumentSerializer<unknown>[]): void {

    if (queryString) {
        const args = decodeURIComponent(queryString)
            .replace('?', '')
            .split('&')
            .map(queryStringArgument => queryStringArgument.split('=')) // split on [key, value]
            .filter(([key, value]) => key != null && value != null); // ignore invalid or empty

        arrayUtils
            .distinct(args, ([key]) => key.toLowerCase()) // ignore duplicates
            .forEach(([key, value]) =>
                serializers
                    .filter(s => s.key === key)
                    .forEach(s => s.deserialize(value))
            );
    }
}
