export default class securitiesComparer {
    static compareMultiple(original, modified) {
        if ((original === modified) || (!original && !modified)) {
            return true;
        }

        if (!original || !modified || original.length !== modified.length) {
            return false;
        }

        const originalJson = this.generateSecuritiesJson(original);
        const modifiedJson = this.generateSecuritiesJson(modified);

        return originalJson.localeCompare(modifiedJson, undefined, { sensitivity: 'accent' }) === 0;
    }

    static generateSecuritiesJson(securities) {
        const sorted = securities
            .sort((a, b) => a.isinCusip.localeCompare(b.isinCusip, undefined, { sensitivity: 'accent' }))
            .map(s => ({
                id: s.id,
                isinCusip: s.isinCusip,
                ticker: s.ticker,
                size: s.size,
                currency: s.currency,
                asset: s.asset,
                rating: s.rating,
                pxTalks: (s.pxTalks || [])
                    .map(p => ({
                        companyId: p.company.id,
                        levelType: p.levelType,
                        talk: p.talk,
                        normalizedLevel: p.normalizedLevel
                    })).sort((a, b) => a.companyId - b.companyId),
                color: s.color
                    ? {
                        levelType: s.color.levelType,
                        levelSpecificationType: s.color.levelSpecificationType,
                        traded: s.color.traded,
                        level: s.color.level,
                        normalizedLevel: s.color.normalizedLevel
                    }
                    : null
            }));

        return JSON.stringify(sorted);
    }
}
