import { RequestState } from '../../constants/request-state';
import { Bank } from '../banks/Bank';
import { CloManager } from '../clo-managers/CloManager';
import { ArrangerPipelineEmailPreferences } from '../email-preferences/ArrangerPipelineEmailPreferences';
import { BanksEmailPreferences } from '../email-preferences/BanksEmailPreferences';
import { CloManagersEmailPreferences } from '../email-preferences/CloManagersEmailPreferences';
import { AlertOption, EmailPreferences } from '../email-preferences/EmailPreferences';
import { IssuanceMonitorEmailPreferences } from '../email-preferences/IssuanceMonitorEmailPreferences';

export interface SavedFiltersPreferencesState {
    [filterReferenceName: string]: AlertOption;
}

export interface PortfolioSavedFilterAlertsState {
    [filterReferenceName: string]: {
        bwicAlert: AlertOption | null;
        inventoryAlert: AlertOption | null;
    };
}

export enum PortfolioAlertsRowType {
    AllSecurities,
    SavedFiltersHeader,
    SavedFilter
}

export interface ManageEmailPreferencesState {
    initialPreferences: EmailPreferences;
    editPreferences: EmailPreferences;
    editSavedFiltersPreferences: SavedFiltersPreferencesState;
    portfolioSavedFilterAlerts: PortfolioSavedFilterAlertsState;
    requestStateSaveEditPreferences: RequestState;
    initialIssuanceMonitorPreferences?: IssuanceMonitorEmailPreferences;
    editIssuanceMonitorPreferences?: IssuanceMonitorEmailPreferences;
    initialCloManagersPreferences?: CloManagersEmailPreferences;
    editCloManagersPreferences?: CloManagersEmailPreferences;
    initialBanksPreferences?: BanksEmailPreferences;
    editBanksPreferences?: BanksEmailPreferences;
    initialArrangerPipelinePreferences?: ArrangerPipelineEmailPreferences;
    editArrangerPipelinePreferences?: ArrangerPipelineEmailPreferences;
    mangers?: CloManager[];
    banks?: Bank[];
    requestStateGetPreferences: RequestState;
    requestStateSaveIssuanceMonitorPreferences: RequestState;
    requestStateSaveBwicByRating: RequestState;
}
