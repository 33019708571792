export enum NewsLabel {
    None = "None",
    AMR = "AMR",
    ArrangerPipeline = "ArrangerPipeline",
    BWIC = "BWIC",
    CLOMarket = "CLOMarket",
    Conference = "Conference",
    Debut = "Debut",
    New = "New",
    NewAMR = "NewAMR",
    Pricing = "Pricing",
    PrimaryWeekly = "PrimaryWeekly",
    Webinar = "Webinar",
}

export const newsLabelTitles = {
    [NewsLabel.None]: "—",
    [NewsLabel.AMR]: "AMR",
    [NewsLabel.ArrangerPipeline]: "Arranger Pipeline",
    [NewsLabel.BWIC]: "BWIC",
    [NewsLabel.CLOMarket]: "CLO Market",
    [NewsLabel.Conference]: "Conference",
    [NewsLabel.Debut]: "Debut",
    [NewsLabel.New]: "New",
    [NewsLabel.NewAMR]: "New AMR",
    [NewsLabel.Pricing]: "Pricing",
    [NewsLabel.PrimaryWeekly]: "Primary Weekly",
    [NewsLabel.Webinar]: "Webinar",
};
