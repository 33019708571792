export enum BwicHistoryActionType {
    Scheduled = 1,
    Bidding =  2,
    PriceTalk = 3,
    NewBid = 4,
    QuickFeedback = 5,
    TradeSubmitted = 6,
    TradedAway = 7,
    TradeAffirmed = 8,
    TradeRejected = 9,
    TargetLevel = 10,
    ColorDistribution = 11,
    ColorDistributionSend = 12,
    Finished = 13,
    Canceled = 14,
    OpenBiddingStage1Started = 15,
    OpenBiddingStage1Ended = 16,
    OpenBiddingStage2Ended = 17,
    OpenBiddingStage2Level = 18,
    ModifyAutoFeedbackConfig = 19,
    BidAsDealerRequest = 20,
    DirectBid = 21,
    DirectFeedback = 22,
    TradeCanceled = 23,
    AxedFinalChanged = 24,
    BuyerRejectedTrade = 25,
    BiddingCompleteReminder = 26
}
