import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const issuanceMonitorFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.IssuanceMonitorFullAccess,
    SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs,
    SubscriptionFeature.IssuanceMonitorMarketingHistory,
    SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts,
    SubscriptionFeature.IssuanceMonitorColumnsCustomizer,
    SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts,
    SubscriptionFeature.IssuanceMonitorHistoricalData,
    SubscriptionFeature.IssuanceMonitorArrangerPipeline,
    SubscriptionFeature.IssuanceMonitorWeeklyMarketRecap,
    SubscriptionFeature.IssuanceMonitorExport,
    SubscriptionFeature.IssuanceMonitorTransactionsEmailAlerts,
];
