import { DealDocuments } from "../document/DealDocuments"

export interface UploadDocumentsState {
    isInitialized: boolean
    isUploading: boolean
    deletingDocuments: { [documentId: string]: boolean }
    deals: DealDocuments[]
    uploadingDocuments: UploadingDealDocumentState[]
    uploadAll: { // Upload multiple docs dialog
        visible: boolean
        deal?: DealDocuments
    }
    upload: { // single document upload dialog
        visible: boolean
        dealReferenceName?: string
        documentType?: DealDocumentTypeState
        documentId?: string // current document.id
    } 
    uploadAndAssign: boolean
}

export interface UploadingDealDocumentState {
    uid: string
    progress: number
    name: string // file nanme
    dealReferenceName: string
    documentType: DealDocumentTypeState
    currentDocumentId: string // current document.id
}

export enum DealDocumentTypeState {
    OM = 'OfferingMemorandum',
    Indenture = 'Indenture',
    DistributionReport = 'DistributionReport',
    MonthlyReport = 'MonthlyReport',
    Other = 'Other'
}