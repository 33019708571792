import { Bid } from '../types/bidding/Bid';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { biddingUtils } from './bidding.utils';

export const topXUtils = {
    canBid,
    canSendFeedback
}

function canBid(stagedBiddingStatus?: OpenBiddingStatus | null, canBidOnStage2?: boolean) {
    return stagedBiddingStatus == null || (
        stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
        Boolean(canBidOnStage2)
    );
}

function canSendFeedback(
    stage2Participants: { [companyIdentifier: string]: boolean },
    stagedBiddingStatus?: OpenBiddingStatus | null,
    levelBids?: Bid[],
) {
    if (stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded) return false; // bidding ended
    if (stagedBiddingStatus === OpenBiddingStatus.stage1Ended) {
        if (levelBids?.some(b => b.pass)) return false; // pass
        if (!levelBids?.length) return false; // no bids
        if (!levelBids.some(b => stage2Participants[biddingUtils.getBidCompanyIdentifier(b)])) return false; // out of top-x
    }

    return true;
}


