enum StatsToCalculate {
    NumberOfManagers = "NumberOfManagers",
    NumberOfTrustees = "NumberOfTrustees",
    NumberOfArrangers = "NumberOfArrangers",
    TransactionsBalanceTotalUsd = "TransactionsBalanceTotalUsd",
    TransactionsBalanceTotalEur = "TransactionsBalanceTotalEur",
    WasWarfDsMedians = "WasWarfDsMedians",
    MedianPurchasePrice = "MedianPurchasePrice",
}

const statsToCalculateList = Object.values(StatsToCalculate);
export const statsToCalculateWithoutMedians = statsToCalculateList.filter(
    (s) =>
        s !== StatsToCalculate.WasWarfDsMedians &&
        s !== StatsToCalculate.MedianPurchasePrice
);
