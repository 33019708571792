import { pickBy } from 'lodash';
import { Currency } from '../../../../types/enums/Currency';
import { Rating } from '../../../../types/enums/Rating';
import { BwicSavedFilter } from '../../../../types/filters/BwicSavedFilter';
import { FilterOption } from '../../../../types/filters/FilterOption';
import { UserFilterValues } from '../../../../types/user-config/UserConfigFilter';
import { serializeBooleanFilter, serializeDateFilter, serializeDaysFilter, serializeRangeFilter, serializeSelectFilter } from '../amr-pipeline/filterSerializers';

export const getSerializedFilter = (filterState: BwicSavedFilter): UserFilterValues => {
    const filter = {
        date: filterState.date ? serializeDateFilter(filterState.date.filter) : undefined,
        size: filterState.size ? serializeRangeFilter(filterState.size.filter) : undefined,
        statuses: filterState.statuses ? serializeSelectFilter(filterState.statuses.filter as FilterOption<string>[]) : undefined,
        collateralTypes: filterState.collateralType ? serializeSelectFilter(filterState.collateralType.filter) : undefined,
        currencyCodes: filterState.currency ? serializeSelectFilter(filterState.currency.filter as FilterOption<Currency>[]) : undefined,
        collateralManagers: filterState.managers ? serializeSelectFilter(filterState.managers.filter as FilterOption<string>[]) : undefined,
        ratings: filterState.ratings ? serializeSelectFilter<Rating>(filterState.ratings.filter as FilterOption<Rating>[]) : undefined,
        esg: filterState.esg ? serializeBooleanFilter(filterState.esg.filter) : undefined,
        euCompliance: filterState.euCompliance ? serializeBooleanFilter(filterState.euCompliance.filter) : undefined,
        staticDeal: filterState.staticDeal ? serializeBooleanFilter(filterState.staticDeal.filter) : undefined,
        nonCallPeriodEnd: filterState.nonCallEnd ? serializeDateFilter(filterState.nonCallEnd.filter) : undefined,
        nonCallPeriodEndDays: filterState.nonCallEnd ? serializeDaysFilter(filterState.nonCallEnd.filter) : undefined,
        reinvestmentPeriodEnd: filterState.reinvestmentEnd ? serializeDateFilter(filterState.reinvestmentEnd.filter) : undefined,
        reinvestmentPeriodEndDays: filterState.reinvestmentEnd ? serializeDaysFilter(filterState.reinvestmentEnd.filter) : undefined,
        maturity: filterState.maturity ? serializeDateFilter(filterState.maturity.filter) : undefined,
        vintage: filterState.vintage ? serializeDateFilter(filterState.vintage.filter) : undefined,
        closingDate: filterState.closing ? serializeDateFilter(filterState.closing.filter) : undefined,
        trustees: filterState.trustees ? serializeSelectFilter<string>(filterState.trustees.filter as FilterOption<Rating>[]) : undefined,
        amrDeal: filterState.amr ? serializeBooleanFilter(filterState.amr.filter) : undefined,
        outOfNC: filterState.outOfNC ? serializeBooleanFilter(filterState.outOfNC.filter) : undefined,
        outOfRI: filterState.outOfRI ? serializeBooleanFilter(filterState.outOfRI.filter) : undefined,
        dealName: filterState.dealName ? serializeSelectFilter(filterState.dealName.filter as FilterOption<string>[]) : undefined,
        dealerIds: filterState.dealerIds ? serializeSelectFilter(filterState.dealerIds.filter) : undefined
    };

    return pickBy(filter, x => x !== undefined);
};
