export class ObjectUtils {
    static equal(object1, object2) {
        const keysFirstObj = Object.keys(object1);
        const keysSecondObject = Object.keys(object2);
        if (keysFirstObj.length !== keysSecondObject.length) {
            return false;
        }
        return !keysFirstObj.filter(key => {
            if (typeof object1[key] == "object" || Array.isArray(object1[key])) {
                return !ObjectUtils.equal(object1[key], object2[key]);
            } else {
                return object1[key] !== object2[key];
            }
        }).length;
    }
}
