export enum SettlementAgreementStatus {
    pending = 1,
    confirmed = 2,
    rejected = 3
}

export enum UnrequestedAgreementStatus {
    Unrequested = 'Unrequested'
}

export const settlementAgreementStatusTitle = {
    [SettlementAgreementStatus.pending]: 'Requested',
    [SettlementAgreementStatus.confirmed]: 'Approved',
    [SettlementAgreementStatus.rejected]: 'Suspended',
}

export const settlementAgreementStatusSortOrdering = {
    [SettlementAgreementStatus.confirmed]: 0,
    [SettlementAgreementStatus.pending]: 1,
    [SettlementAgreementStatus.rejected]: 2,
    [UnrequestedAgreementStatus.Unrequested]: 3
}
