export enum ContactSalesRequestType {
    EvaluateBWIC = 'EvaluateBWIC',
    GetRightPlan = 'GetRightPlan',
    BuyOrUpgradeLicense = 'BuyOrUpgradeLicense',
    ProductQuestion = 'ProductQuestion',
    NeedCompliancePlan = 'NeedCompliancePlan'
}

export const contactSalesRequestTypes = [
    { value: ContactSalesRequestType.EvaluateBWIC, text: 'I want to evaluate BWIC for my organization' },
    { value: ContactSalesRequestType.GetRightPlan, text: 'I want to understand which BWIC plan is right for me' },
    { value: ContactSalesRequestType.BuyOrUpgradeLicense, text: 'I want to buy licenses or upgrade' },
    { value: ContactSalesRequestType.ProductQuestion, text: 'I have a product question' },
    { value: ContactSalesRequestType.NeedCompliancePlan, text: 'I need a compliance plan' }
]
