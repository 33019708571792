import { Rating } from "../enums/Rating";

export enum RatingGroup {
    OTHER = 'OTHER',
}

export const ratingGroupValues = {
    [RatingGroup.OTHER]: [Rating.CCC, Rating.D]
};

export const ratingWithGroupValues = [
    Rating.AAA,
    Rating.AA,
    Rating.A,
    Rating.BBB,
    Rating.BB,
    Rating.B,
    Rating.NR,
    RatingGroup.OTHER
];

export type RatingWithGroup = typeof ratingWithGroupValues[number]
