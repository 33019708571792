import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const dealDocumentsFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.CanAccessIndentureDealDocuments,
    SubscriptionFeature.CanAccessOMDealDocuments,
    SubscriptionFeature.CanAccessDistributionReportDealDocuments,
    SubscriptionFeature.CanAccessMonthlyReportDealDocuments,
    SubscriptionFeature.CanAccessOtherDealDocuments,
    SubscriptionFeature.CanAccessDealDocuments

]
