import moment, { Moment } from "moment";
import { user } from "../user/user";
import { SubscriptionType } from "../types/billing/SubscriptionType";

const cutTime = (dateTime: Moment) => dateTime.startOf('day');

export const getSubscriptionTitle = (subscription: string) => {
    const { isTrial } = calculateSubscriptionTimeline(); 
    switch(true) {
        case isTrial:
            return SubscriptionType.trial;
        default:
            return subscription;
    }
}

export const isTrialExpired = (trialPeriodEnd: Date) => {
    const todayUTC = cutTime(moment.utc());
    return cutTime(moment.utc(trialPeriodEnd)).isBefore(todayUTC, 'day');
}

export function calculateSubscriptionTimeline() {
    const currentUser = user.current();

    if (!currentUser) return {};

    const today = cutTime(moment());
    const isBasic = currentUser.subscription === SubscriptionType.basic;
    const isTrial = !!currentUser.trialPeriodEnd && currentUser.subscription === SubscriptionType.pro;
    const isTrialEnded = isBasic && currentUser?.subscriptionPrev === SubscriptionType.trial
    const isSubscriptionEnded = isBasic && !isTrialEnded;

    let remainingDays: number | undefined;

    if (isTrial || (!isSubscriptionEnded && currentUser?.subscriptionExpiration)) {
        const expiration = isTrial
            ? moment(currentUser.trialPeriodEnd)
            : moment(currentUser?.subscriptionExpiration);

        remainingDays = cutTime(expiration).diff(today, 'day') + 1; // count current day
    }

    return {
        isTrial,
        isTrialEnded,
        isSubscriptionEnded,
        remainingDays
    };
}