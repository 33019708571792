export enum MarketingEventType {
    Release = 'release',
    Webinar = 'webinars',
    Conference = 'conference',
}

export enum ReleaseItemType {
    NewFeature = 1,
    Improvement = 2,
    BugFix = 3
}

export interface ReleaseItem {
    id: number;
    title: string;
    description: string;
    linkName: string;
    link: string;
    imageReferenceName?: string | null;
    type: MarketingEventType;
    label?: string;
    lastModificationDate: Date;
    releaseItemImageId: number;
    releaseItemType: ReleaseItemType;
}

export interface Release {
    id: number;
    title: string;
    releaseDate: Date;
    active: boolean;
    lastModificationDate: Date;
    releaseItems?: ReleaseItem[];
}

export interface Releases {
    listStartSymbol: string;
    listEndSymbol: string;
    listItemStartSymbol: string;
    listItemEndSymbol: string;
    releases: Release[];
}
