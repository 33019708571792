import { isNaN, isNil } from 'lodash';
import { Primitive } from './types';

export const defaultIteratee = <T extends Object>(value: T) => value.toString();

export const isPrimitive = (value: unknown): value is Primitive => {
    return ['string', 'number', 'boolean', 'undefined'].includes(typeof value)
        || value === null
        || value instanceof Date;
};

export const noValue = (value: unknown) => {
    if (typeof value === 'string' && value === '') {
        return true;
    }

    return isNil(value) || isNaN(value);
};
