import { numericUtils } from './numeric.utils';
import { stringUtils } from './string.utils';

export const moneyUtils = {
    roundToMillions,
    amountToMM,
    amountToRoundedMM,
    money,
    parse
};

function roundToMillions(value: number, precision: number) {
    const amount = Number(value);
    return numericUtils.round(Math.abs(Math.trunc(amount) / 1.0e+6), precision).toFixed(precision);
}

function amountToMM(amountRaw: number, showMM = false) {
    if (numericUtils.isNumber(amountRaw)) {
        const millions = roundToMillions(amountRaw, 3);

        return showMM ? `${millions}MM` : millions;
    }

    return '';
}

function amountToRoundedMM(amountRaw: number, showMM = false) {
    const millions = Number(roundToMillions(amountRaw, 0));
    const billionSeparatedValue = money(millions);

    return showMM ? `${billionSeparatedValue}MM` : billionSeparatedValue;
}

function money(value: number, addDecimalPlaces = false, decimalPlaces = 2) {
    if (numericUtils.isNumber(value)) {
        const number = Number(value);

        if (addDecimalPlaces) {
            return numericUtils
                .round(number, decimalPlaces)
                .toLocaleString(
                    'en-US', {
                    minimumFractionDigits: decimalPlaces,
                    maximumFractionDigits: decimalPlaces
                })
        }

        return numericUtils.round(number).toLocaleString('en-US');
    }

    return '';
}

function parse(value?: string | null) {
    return stringUtils.isNullOrWhiteSpace(value)
        ? NaN
        : numericUtils.numberOrDefault(String(value).replace(/,/g, ''), NaN);
}
