import { Rating } from "../enums/Rating";
import { Currency } from "../enums/Currency";

export interface Security {
    id: number;
    ticker: string;
    isin?: string;
    cusip?: string;
    isinCusip: string;
    size: number;
    currency: Currency;
    rating: Rating;
}

export function isinCusip(security: { isin?: string; cusip?: string; currency: Currency }){
    return security.currency === Currency.USD 
        ? security.cusip || security.isin
        : security.isin || security.cusip;
}
