export enum UserConfigType {
    imColumnsDealTab = 'ImColumnsDealTab',
    imColumnsClassTab = 'ImColumnsClassTab',
    imFilter = 'ImFilter',
    imAlert = 'ImAlert',
    apFilter = 'ApFilter',
    arrangerPipelineAlert = 'ArrangerPipelineAlert',
    transactionAlert = 'TransactionAlert',
    weeklyStatsEmail = 'WeeklyStatsEmail',
    newPublishedTransactionAlert = 'NewPublishedTransactionAlert',
    summaryDailyAlerts = 'SummaryDailyAlerts',
    cloManagerAnalyticsAlert = 'CloManagerAnalyticsAlert',
    cloManagerIssuanceMonitorAlert = 'CloManagerIssuanceMonitorAlert',
    cloManagerArrangerPipelineAlert = 'CloManagerArrangerPipelineAlert',
    cloManagerBwicAlert = 'CloManagerBwicAlert',
    cloManagerDealerInventoryAlert = 'CloManagerDealerInventoryAlert',
    bankAnalyticsAlert = 'BankAnalyticsAlert',
    bankIssuanceMonitorAlert = 'BankIssuanceMonitorAlert',
    bankArrangerPipelineAlert = 'BankArrangerPipelineAlert',
    bankDealerInventoryAlert = 'BankDealerInventoryAlert',
}
