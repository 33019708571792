import { OriginatingTransaction } from "../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionClass } from "../types/amr-pipeline/models/OriginatingTransactionClass";
import { OriginatingTransactionPortfolioItem } from "../types/amr-pipeline/models/OriginatingTransactionPortfolioItem";

function calcTargetPar(transaction: OriginatingTransaction): number {
    if (!transaction.portfolio) {
        return 0;
    }

    return transaction.portfolio.reduce((accumulator, p: OriginatingTransactionPortfolioItem) =>
        accumulator + p.targetPar
    , 0);
}

function calcBidPrice(transaction: OriginatingTransaction): number {
    if (!transaction.portfolio || !transaction.portfolio.length) {
        return 0;
    }

    const targetParSum = transaction.portfolio.reduce((accumulator, p) =>
        p.indicativeBid ? accumulator + p.targetPar : accumulator
    , 0);

    if (!targetParSum) {
        return 0;
    }

    const indicativeBidSumWeightedByTargetPar = transaction.portfolio.reduce(
        (accumulator, p) => accumulator + p.indicativeBid * p.targetPar
    , 0);

    return indicativeBidSumWeightedByTargetPar / targetParSum;
}

function calcAskPrice(transaction: OriginatingTransaction): number {
    if (!transaction.portfolio || !transaction.portfolio.length) {
        return 0;
    }

    const targetParSum = transaction.portfolio.reduce((accumulator, p) =>
        p.indicativeAsk ? accumulator + p.targetPar : accumulator
    , 0);

    if (!targetParSum) {
        return 0;
    }

    const indicativeAskSumWeightedByTargetPar = transaction.portfolio.reduce(
        (accumulator, p) => accumulator + p.indicativeAsk * p.targetPar
    , 0);

    return indicativeAskSumWeightedByTargetPar / targetParSum;
}

function calcRatedNotes(transaction: OriginatingTransaction): number {
    return transaction.classes.reduce((accumulator, t: OriginatingTransactionClass) =>
        !t.equity && t.balance ? accumulator + t.balance : accumulator
    , 0);
}

function calcSubNotes(transaction: OriginatingTransaction): number {
    return transaction.classes.reduce((accumulator, t: OriginatingTransactionClass) =>
        t.equity && t.balance ? accumulator + t.balance : accumulator
    , 0);
}

function calcPurchaseAssets(transaction: OriginatingTransaction): number {
    const askPrice = calcAskPrice(transaction);
    return askPrice
        ? (askPrice * calcTargetPar(transaction))
        : 0
}

export const dealStructureUtils = {
    calcTargetPar,
    calcBidPrice,
    calcAskPrice,
    calcPurchaseAssets,
    calcRatedNotes,
    calcSubNotes,
};
