import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";

const positive = ['yes', 'true'];
const negative = ['no', 'false'];

export class FlagArgumentSerializer
    extends QueryStringArgumentSerializer<boolean> {
    constructor(
        key: string,
        onDeserializeCallback?: (value: boolean) => void) {
        super(key, positive.concat(negative), onDeserializeCallback);
    }

    serialize = (value: boolean | undefined) =>
        value == null
            ? undefined
            : value ? 'yes' : 'no'

    deserialize = (rawValue: string): void => {
        const value = rawValue ? decodeURIComponent(rawValue.trim()) : undefined;
        if (value && this.isAccepted(value) && this.onDeserializeCallback) {
            const isPositive = positive.some(p =>
                p.localeCompare(value, undefined, { sensitivity: 'accent' }) === 0);

            this.onDeserializeCallback(isPositive);
        }
    }
}
