import { NewsTabCategory } from './NewsTabCategory';

export enum NewsCategory {
    None = "None",
    Events = "Event",
    Primary = "Primary",
    Research = "Research",
    Secondary = "Secondary",
    MyPortfolio = "MyPortfolio",
}

export const newsCategoryTitles = {
    [NewsCategory.None]: "—",
    [NewsCategory.Events]: "Events",
    [NewsCategory.Primary]: "Primary",
    [NewsCategory.Research]: "Research",
    [NewsCategory.Secondary]: "Secondary",
    [NewsCategory.MyPortfolio]: "My Portfolio",
};

export const NewsTabToCategory: Partial<Record<NewsTabCategory, NewsCategory>> = {
    [NewsTabCategory.Primary]: NewsCategory.Primary,
    [NewsTabCategory.Secondary]: NewsCategory.Secondary,
    [NewsTabCategory.Research]: NewsCategory.Research,
};
