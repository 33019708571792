export enum BwicType {
    Direct = 'Direct',
    Live = 'Live',
    Parsed = 'Parsed',
    Standard = 'Standard',
}

export const BwicTypes: BwicType[] = [
    BwicType.Direct,
    BwicType.Live,
    BwicType.Parsed,
    BwicType.Standard,
];
