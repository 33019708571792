import { roles } from "../../constants/roles";
import { User } from "../../types/account/User";
import { AppProduct } from "../../types/billing/AppProduct";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { bwicMonitorFeatures } from "./by-product/bwic-monitor";
import { dashboardFeatures } from "./by-product/dashboard";
import { dealDocumentsFeatures } from "./by-product/deal-documents";
import { dealerProfileFeatures } from "./by-product/dealer-profile";
import { integratonsFeatures } from "./by-product/integrations";
import { inventoryFeatures } from "./by-product/inventory";
import { issuanceMonitorFeatures } from './by-product/issuance-monitor';
import { kWatchFeatures } from './by-product/k-watch';
import { managerProfileFeatures } from "./by-product/manager-profile";
import { portfolioFeatures } from "./by-product/portfolio";
import { resourcesFeatures } from "./by-product/resources";
import { dataEntryFeatures } from "./by-role/data-entry";

export const featuresByProduct: { [key in AppProduct]?: SubscriptionFeature[] } = {
    [AppProduct.Dashboard]: dashboardFeatures,
    [AppProduct.Inventory]: inventoryFeatures,
    [AppProduct.BwicMonitor]: bwicMonitorFeatures,
    [AppProduct.Portfolio]: portfolioFeatures,
    [AppProduct.Resources]: resourcesFeatures,
    [AppProduct.KWatch]: kWatchFeatures,
    [AppProduct.IssuanceMonitor]: issuanceMonitorFeatures,
    [AppProduct.Integrations]: integratonsFeatures,
    [AppProduct.ManagerProfile]: managerProfileFeatures,
    [AppProduct.DealerProfile]: dealerProfileFeatures,
    [AppProduct.DealDocuments]: dealDocumentsFeatures
};

const featuresByRole = {
    [roles.DataEntry]: dataEntryFeatures
};

function mapByRole(user: User) {
    const features = new Set(user.roles
        ?.map(r => featuresByRole[r] ?? [])
        .flat() ?? []
    );

    return [...features];
}

export function getFeatures(user: User) {
    const productFeatures = user.products
        ?.map(p => featuresByProduct[p] ?? [])
        .flat() ?? [];

    let features = new Set([...productFeatures, ...mapByRole(user)]);
    
    return [...features];
};
