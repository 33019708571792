import moment from "moment";
import { isNil } from 'lodash';
import { FilterDateOption } from "../../../../types/filters/FilterDateOption";
import {
    DateRangeOption,
    DateTimeRangeOption,
    UserConfigDateFilter,
    UserConfigDateTimeFilter,
    UserConfigRangeFilter,
} from "../../../../types/user-config/UserConfigFilter";
import { dateRangeFilterOptions, dateTimeRangeFilterOptions } from "../../../../constants/date-range.filter";
import { FilterOption } from "../../../../types/filters/FilterOption";
import { FilterRadioOption } from "../../../../types/filters/FilterRadioOption";
import { FilterBooleanOption } from "../../../../types/filters/FilterBooleanOption";
import { numericUtils } from "../../../numeric.utils";
import { getRangeFromDateOption, getRangeFromDateTimeOption } from "../../../amr-pipeline.utils";
import { FilterDateTimeOption } from '../../../../types/filters/FilterDateTimeOption';
import { TimeOption } from '../../../../types/filters/TimeOption';

const DateRangeOptionKeys: Partial<Record<DateRangeOption, number>> = Object
    .entries(DateRangeOption)
    .reduce((acc, [key], index) => ({ ...acc, [key]: index }), {});

function deriveSelectedOptionFromDays(from: number, to: number) {
    const foundOption = dateRangeFilterOptions.toArray()
        .find(option => option.to === to && option.from === from);

    return foundOption || dateRangeFilterOptions.YearsRange;
}

function deserializeDays(configFilter: UserConfigRangeFilter<number>) {
    const { from, to } = configFilter;

    if (isNil(from) && isNil(to)) {
        return undefined;
    }

    const fromYears = numericUtils.round(Number(from) / 365, 2);
    const toYears = numericUtils.round(Number(to) / 365, 2);

    return {
        selectedOption: deriveSelectedOptionFromDays(fromYears, toYears),
        options: {
            customDateRange: {
                from: null,
                to: null,
            },
            customYearsRange: {
                from: fromYears,
                to: toYears,
            },
        },
    } as FilterDateOption;
}

function deserializeDates(configFilter?: UserConfigDateFilter) {
    if (!configFilter) {
        return undefined;
    }

    const { name, value: { from, to } = {} } = configFilter;

    if (
        name
        && dateRangeFilterOptions[name]
        && name !== DateRangeOption.Custom
    ) {
        const dateRange = getRangeFromDateOption(dateRangeFilterOptions[name]);

        const customDateRange = {
            from: dateRange?.from ? moment(dateRange.from).toDate() : null,
            to: dateRange?.to ? moment(dateRange.to).toDate() : null,
        };

        return {
            selectedOption: {
                ...dateRangeFilterOptions.toArray().find(x => x.key === DateRangeOptionKeys[name]),
                ...customDateRange,
            },
            options: {
                customDateRange,
            },
        } as FilterDateOption;
    }

    if (isNil(from) && isNil(to)) {
        return undefined;
    }

    return {
        selectedOption: dateRangeFilterOptions[name],
        options: {
            customDateRange: {
                from: moment(from).toDate(),
                to: moment(to).toDate(),
            },
        },
    } as FilterDateOption;
}

export function deserializeDateTimeFilter(configFilter?: UserConfigDateTimeFilter) {
    if (!configFilter) {
        return undefined;
    }

    const { name, value: { from, to } = {} } = configFilter;

    const filterOption = dateTimeRangeFilterOptions[name];

    if (!filterOption) {
        return undefined;
    }

    // Derive current date for Today option
    if (name === DateTimeRangeOption.TodayWithTime) {
        const dateRange = getRangeFromDateTimeOption(filterOption);

        if (!dateRange) {
            return {
                selectedOption: dateTimeRangeFilterOptions[name],
            } as FilterDateTimeOption;
        }

        const customDateRange = {
            from: {
                date: dateRange.from.date.toDate(),
                timeOption: dateRange.from.timeOption,
            },
            to: {
                date: dateRange.to.date.toDate(),
                timeOption: dateRange.to.timeOption,
            },
        };

        return {
            selectedOption: {
                ...dateTimeRangeFilterOptions[name],
                ...customDateRange,
            },
        } as FilterDateTimeOption;
    }

    // Probably config is broken
    if (isNil(from) || isNil(to)) {
        return undefined;
    }

    // For Date and Date Period options - get "from" and "to" values from configuration
    return {
        selectedOption: {
            ...filterOption,
            from: {
                date: moment.parseZone(from.value).local(true).toDate(),
                timeOption: from.option as TimeOption || TimeOption.CustomTime,
            },
            to: {
                date: moment.parseZone(to.value).local(true).toDate(),
                timeOption: to.option as TimeOption || TimeOption.CustomTime,
            },
        },
    } as FilterDateTimeOption;
}

export const deserializeDateFilter = (dateFilter?: UserConfigDateFilter, dayFilter?: UserConfigRangeFilter<number>) => {
    if (!dateFilter && !dayFilter) {
        return undefined;
    }

    if (!dayFilter || (isNil(dayFilter.from) && isNil(dayFilter.to))) {
        return deserializeDates(dateFilter);
    }

    return deserializeDays(dayFilter);
};

export const deserializeSelectFilter = <T = string>(stateFilter: FilterOption<T>[], configFilter?: T[]) => {
    if (!configFilter || !configFilter.length) {
        return undefined;
    }

    return stateFilter.map(filter => ({
        ...filter,
        selected: configFilter.includes(filter.value),
    }));
};

export const deserializeRadioFilter = <T = string>(stateFilter: FilterRadioOption<T>, configFilter?: T[], ) => {
    if (!configFilter || !configFilter.length) {
        return undefined;
    }

    const [selectedOption] = configFilter;

    return {
        selectedOption,
        options: stateFilter.options,
    } as FilterRadioOption<T>;
};

export const deserializeBooleanFilter = (configFilter?: boolean | null) => {
    if (isNil(configFilter)) {
        return undefined;
    }

    return {
        selectedOption: configFilter
    } as FilterBooleanOption;
};

export const deserializeRangeFilter = (configFilter?: UserConfigRangeFilter<number>) => {
    if (!configFilter || (isNil(configFilter.from) && isNil(configFilter.to))) {
        return undefined;
    }

    return configFilter;
}
