export class ListBuilder<T> {
    _result: T[] = []

    add(...items: T[]): ListBuilder<T> {
        this._result.push(...items);
        return this;
    }

    addWhen(condition: () => boolean, ...items: T[]): ListBuilder<T> {
        if (condition()) {
            return this.add(...items);
        }

        return this;
    }

    result(): T[] {
        return this._result;
    }
}
