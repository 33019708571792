import { TimeSpan } from '../primitives/TimeSpan';

export interface TopXSettings {
    automaticStage1Deadline?: TimeSpan;
    improverCount: TopXStage2Participants;
    improvementRound?: TimeSpan;
    stage1EndDateUtc?: Date;
}

export enum TopXStage2Participants {
    Top2 = 2,
    Top3 = 3,
    Top4 = 4,
    Top5 = 5
}
